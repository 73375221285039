import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import avatars from '../../../assets/img/media/avatars/blank.png'

import { useTheme } from '@/components/theme-provider'
import {
  Dropdown,
  DropdownContent,
  DropdownTrigger
} from '@/components/ui/dropdown'
import { useTranslation } from '@/localization/i18n'
import { addLanguage } from '@/redux/user-slice'
import french from '../../../assets/img/media/flag/france.svg'
import german from '../../../assets/img/media/flag/germany.svg'
import palestine from '../../../assets/img/media/flag/palestine.svg'
import spanish from '../../../assets/img/media/flag/spain.svg'
import turkey from '../../../assets/img/media/flag/turkey.svg'
import unitedStates from '../../../assets/img/media/flag/united-states.svg'
import { changeRegisterStatus } from '@/redux/user-slice'

import { useState } from 'react'

const Sidebar = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { setTheme } = useTheme()

  const {
    user: { user }
  } = useSelector(state => state.user)

  function logout() {
    sessionStorage.clear()
   window.location.reload()
  }

  const [selectedItem, setSelectedItem] = useState('Dashboard')

  const [isMouseOver, setIsMouseOver] = useState(false)
  const [key, setKey] = useState('')
  const [isMouseOver1, setIsMouseOver1] = useState(false)
  const [key1, setKey1] = useState('')
  const language = useSelector(state => state.user.language)
  const dispatch = useDispatch()

  function changeLanguage(lang) {
    dispatch(addLanguage(lang))
    localStorage.setItem('language', lang)
  }
  function logout() {
    sessionStorage.clear()
    window.location.reload()
  
  }

  const handleMouseEnter = key => {
    setIsMouseOver(true)
    setKey(key)
  }

  const handleMouseLeave = key => {
    setIsMouseOver(false)
    setKey(key)
  }
  const handleMouseEnter1 = key => {
    setIsMouseOver1(true)
    setKey1(key)
  }

  const handleMouseLeave1 = key => {
    setIsMouseOver1(false)
    setKey1(key)
  }

  const handleMenuClick = itemName => {
    setSelectedItem(itemName)
  }

  const getMenuClass = itemName => {
    return selectedItem === itemName ? 'here show' : ''
  }

  const pages = [
    {
      name: t('Dashboard'),
      path: '/',
      icon: 'home-2'
    },
    {
      name: t('Screens'),
      path: '/screens',
      icon: 'screen'
    },
    {
      name: t('Playlists'),
      path: '/playlists',
      icon: 'questionnaire-tablet'
    },
    {
      name: t('Schedules'),
      path: '/schedules',
      icon: 'calendar'
    },
    {
      name: t('Media Assets'),
      path: '/media-library',
      icon: 'folder'
    }
  ]

  return (
    <>
      <div className="app-sidebar fixed left-0 top-0 !z-10 m-0 h-full">
        <div className="app-sidebar-menu flex-grow-1">
          <div
            className="app-header-logo app-header d-flex flex-center py-[17px]"
            data-bs-theme="dark"
          >
            <Link to="/">
              <img src="/maia.svg" className="h-35px" />
            </Link>
          </div>
          <div
            className="app-sidebar-menu flex-grow-1 scroll-lg-ps my-5 me-2"
            style={{ height: '650px', overflowX: 'hidden' }}
          >
            <div
              id="kt_aside_menu"
              className="menu menu-rounded menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-6"
              data-kt-menu="true"
            >
              {pages.map(({ name, path, icon }, index) => (
                <div class={`menu-item py-2 ${pathname === path && 'here'}`}>
                  <Link
                    to={path}
                    className="menu-link menu-center"
                    aria-label={name}
                  >
                    <span className="menu-icon me-0">
                      <i className={`ki-outline ki-${icon} fs-1`}></i>
                      <span className="tooltiptext">{name}</span>
                    </span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column flex-center pb-lg-8 pb-4"
          id="kt_app_sidebar_footer"
        >
          <Dropdown>
            <DropdownTrigger trigger="hover" placement="right-end">
              <div
                class="symbol symbol-40px cursor-pointer"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-attach="parent"
                data-kt-menu-placement="right-end"
              >
                <img src={avatars} alt="user" />
              </div>
            </DropdownTrigger>
            <DropdownContent className="w-275px">
              <div>
                <div class="menu-item px-3">
                  <div class="menu-content d-flex align-items-center px-3">
                    <div class="symbol symbol-50px me-5">
                      <img alt="Logo" src={avatars} />
                    </div>

                    <div class="d-flex flex-column">
                      <div class="fw-bold d-flex align-items-center fs-5">
                        {user?.username}
                        {/* <span class="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span> */}
                      </div>
                      <a
                        href="#"
                        class="fw-semibold text-hover-primary fs-7 text-muted"
                      >
                        {user?.email}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="separator my-2"></div>

                <div class="menu-item px-5">
                  <Link to="/profile" class="menu-link px-5">
                    {t('My Profile')}
                  </Link>
                </div>

                <div class="menu-item px-5">
                  <a class="menu-link maia-63c002c010bf px-5">
                    {t("What's News")}
                  </a>
                </div>

                <div class="separator my-2"></div>

                <div
                  onMouseEnter={() => handleMouseEnter1('2')}
                  onMouseLeave={() => handleMouseLeave1('3')}
                  class="menu-item px-5"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-placement="left-start"
                  data-kt-menu-offset="-15px, 0"
                ></div>
                <Dropdown>
                  <DropdownTrigger
                    className="menu-item px-5"
                    trigger="hover"
                    placement="right-end"
                  >
                    <a href="#" class="menu-link px-5">
                      <span class="menu-title position-relative">
                        {t('Mode')}
                        <span class="position-absolute translate-middle-y top-50 end-0 ms-5">
                          <i class="ki-outline ki-night-day theme-light-show fs-2"></i>
                          <i class="ki-outline ki-moon theme-dark-show fs-2"></i>
                        </span>
                      </span>
                    </a>
                  </DropdownTrigger>
                  <DropdownContent className="w-175px py-4">
                    <div
                      class="menu-item my-0 px-3"
                      onClick={() => setTheme('light')}
                    >
                      <a
                        href="#"
                        class="menu-link px-3 py-2"
                        data-kt-element="mode"
                        data-kt-value="light"
                      >
                        <span class="menu-icon" data-kt-element="icon">
                          <i class="ki-outline ki-night-day fs-2"></i>
                        </span>
                        <span class="menu-title">{t('Light')}</span>
                      </a>
                    </div>

                    <div
                      class="menu-item my-0 px-3"
                      onClick={() => setTheme('dark')}
                    >
                      <a
                        href="#"
                        class="menu-link px-3 py-2"
                        data-kt-element="mode"
                        data-kt-value="dark"
                      >
                        <span class="menu-icon" data-kt-element="icon">
                          <i class="ki-outline ki-moon fs-2"></i>
                        </span>
                        <span class="menu-title">{t('Dark')}</span>
                      </a>
                    </div>
                  </DropdownContent>
                </Dropdown>

                <Dropdown>
                  <DropdownTrigger
                    className="menu-item px-5"
                    trigger="hover"
                    placement="right-end"
                  >
                    <a href="#" class="menu-link px-5">
                      <span class="menu-title position-relative">
                        {t('Language')}
                        <span class="fs-8 bg-light position-absolute translate-middle-y top-50 end-0 flex rounded px-3 py-2">
                          {i18n.language === 'en'
                            ? 'English'
                            : i18n.language === 'es'
                              ? 'Spanish'
                              : i18n.language === 'de'
                                ? 'German'
                                : i18n.language === 'ar'
                                  ? 'Arabic'
                                  : i18n.language === 'fr'
                                    ? 'French'
                                    : i18n.language === 'tr'
                                      ? 'Turkish'
                                      : null}
                          <img
                            class="w-15px h-15px rounded-1 ms-2"
                            src={
                              i18n.language === 'en'
                                ? unitedStates
                                : i18n.language === 'es'
                                  ? spanish
                                  : i18n.language === 'de'
                                    ? german
                                    : i18n.language === 'ar'
                                      ? palestine
                                      : i18n.language === 'fr'
                                        ? french
                                        : i18n.language === 'tr'
                                          ? turkey
                                          : null
                            }
                            alt=""
                          />
                        </span>
                      </span>
                    </a>
                  </DropdownTrigger>
                  <DropdownContent className="w-175px py-4">
                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class={`menu-link d-flex px-5 ${language === 'English' ? 'active' : null}`}
                        onClick={() => {
                          i18n.changeLanguage('en')
                          changeLanguage('English')
                        }}
                      >
                        <span class="symbol symbol-20px me-4">
                          <img class="rounded-1" src={unitedStates} alt="" />
                        </span>
                        {t('English')}
                      </a>
                    </div>

                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class={`menu-link d-flex px-5 ${language === 'Spanish' ? 'active' : null}`}
                        onClick={() => {
                          i18n.changeLanguage('es')
                          changeLanguage('Spanish')
                        }}
                      >
                        <span class="symbol symbol-20px me-4">
                          <img class="rounded-1" src={spanish} alt="" />
                        </span>
                        {t('Spanish')}
                      </a>
                    </div>

                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class={`menu-link d-flex px-5 ${language === 'German' ? 'active' : null}`}
                        onClick={() => {
                          i18n.changeLanguage('de')
                          changeLanguage('German')
                        }}
                      >
                        <span class="symbol symbol-20px me-4">
                          <img class="rounded-1" src={german} alt="" />
                        </span>
                        {t('German')}
                      </a>
                    </div>

                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class={`menu-link d-flex px-5 ${language === 'Arabic' ? 'active' : null}`}
                        onClick={() => {
                          i18n.changeLanguage('ar')
                          changeLanguage('Arabic')
                        }}
                      >
                        <span class="symbol symbol-20px me-4">
                          <img class="rounded-1" src={palestine} alt="" />
                        </span>
                        {t('Arabic')}
                      </a>
                    </div>

                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class={`menu-link d-flex px-5 ${language === 'French' ? 'active' : null}`}
                        onClick={() => {
                          i18n.changeLanguage('fr')
                          changeLanguage('French')
                        }}
                      >
                        <span class="symbol symbol-20px me-4">
                          <img class="rounded-1" src={french} alt="" />
                        </span>
                        {t('French')}
                      </a>
                    </div>

                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class={`menu-link d-flex px-5 ${language === 'Turkish' ? 'active' : null}`}
                        onClick={() => {
                          i18n.changeLanguage('tr')
                          changeLanguage('Turkish')
                        }}
                      >
                        <span class="symbol symbol-20px me-4">
                          <img class="rounded-1" src={turkey} alt="" />
                        </span>
                        {t('Turkish')}
                      </a>
                    </div>
                  </DropdownContent>
                </Dropdown>

                <div class="menu-item my-1 px-5">
                  <a href="#" class="menu-link px-5">
                    {t('Account Settings')}
                  </a>
                </div>

                <div class="menu-item px-5" onClick={() => logout()}>
                  <a href="#" class="menu-link px-5">
                    {t('Sign Out')}
                  </a>
                </div>
              </div>
            </DropdownContent>
          </Dropdown>
        </div>
      </div>
      {/* <div
        id="kt_app_sidebar_panel"
        className="app-sidebar-panel"
        data-kt-drawer="true"
        data-kt-drawer-name="app-sidebar-panel"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="250px"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_app_sidebar_panel_mobile_toggle"
      >
        <div
          className="app-sidebar-panel-header d-flex flex-stack ps-4 pe-5 py-5"
          id="kt_sidebar_panel_header"
        >
          <div className="d-flex align-items-center">
            <a
              href=".html"
              className="btn btn-icon btn-active-color-primary btn-sm"
            >
              <i className="ki-outline ki-left fs-2"></i>
            </a>
            <span className="fs-lg text-white fw-bold">Directory</span>
          </div>
          <div className="d-flex align-items-center gap-1">
            <a
              href="apps/file-manager/folders.html"
              className="btn btn-icon btn-active-color-primary btn-sm"
            >
              <i className="ki-outline ki-add-folder fs-1"></i>
            </a>
            <a
              href="apps/file-manager/settings.html"
              className="btn btn-icon btn-active-color-primary btn-sm"
            >
              <i className="ki-outline ki-add-files fs-1"></i>
            </a>
          </div>
        </div>
        <div
          className="hover-scroll-y scroll-ps m-2"
          id="kt_sidebar_panel_body"
          data-kt-scroll="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_app_header"
          data-kt-scroll-wrappers="#kt_sidebar_panel_body"
          data-kt-scroll-offset="5px"
        >
          <div
            className="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-500 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-2"
            id="kt_app_sidebar_secondary_menu"
            data-kt-menu="true"
          >
            <div className="menu-item">
              <div className="menu-content">
                <span className="menu-section fs-5 fw-bolder ps-1 py-1">Apps</span>
              </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
              <span className="menu-link">
                <span className="menu-icon">
                  <i className="ki-outline ki-rocket fs-2"></i>
                </span>
                <span className="menu-title">Projects</span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <a className="menu-link" href="apps/projects/list.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">My Projects</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/projects/project.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">View Project</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/projects/targets.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Targets</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/projects/budget.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Budget</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/projects/users.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Users</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/projects/files.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Files</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/projects/activity.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Activity</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/projects/settings.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Settings</span>
                  </a>
                </div>
              </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
              <span className="menu-link">
                <span className="menu-icon">
                  <i className="ki-outline ki-handcart fs-2"></i>
                </span>
                <span className="menu-title">eCommerce</span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Catalog</span>
                    <span className="menu-arrow"></span>
                  </span>
                  <div className="menu-sub menu-sub-accordion">
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/catalog/products.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Products</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/catalog/categories.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Categories</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/catalog/add-product.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Add Product</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/catalog/edit-product.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Edit Product</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/catalog/add-category.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Add Category</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/catalog/edit-category.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Edit Category</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Sales</span>
                    <span className="menu-arrow"></span>
                  </span>
                  <div className="menu-sub menu-sub-accordion">
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/sales/listing.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Orders Listing</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/sales/details.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Order Details</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/sales/add-order.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Add Order</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/sales/edit-order.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Edit Order</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Customers</span>
                    <span className="menu-arrow"></span>
                  </span>
                  <div className="menu-sub menu-sub-accordion">
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/customers/listing.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Customer Listing</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/customers/details.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Customer Details</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Reports</span>
                    <span className="menu-arrow"></span>
                  </span>
                  <div className="menu-sub menu-sub-accordion">
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/reports/view.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Products Viewed</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/reports/sales.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Sales</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/reports/returns.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Returns</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/reports/customer-orders.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Customer Orders</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/ecommerce/reports/shipping.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Shipping</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/ecommerce/settings.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Settings</span>
                  </a>
                </div>
              </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
              <span className="menu-link">
                <span className="menu-icon">
                  <i className="ki-outline ki-phone fs-2"></i>
                </span>
                <span className="menu-title">Contacts</span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <a
                    className="menu-link"
                    href="apps/contacts/getting-started.html"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Getting Started</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/contacts/add-contact.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Add Contact</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/contacts/edit-contact.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Edit Contact</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/contacts/view-contact.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">View Contact</span>
                  </a>
                </div>
              </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
              <span className="menu-link">
                <span className="menu-icon">
                  <i className="ki-outline ki-chart fs-2"></i>
                </span>
                <span className="menu-title">Support Center</span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <a className="menu-link" href="apps/support-center/overview.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Overview</span>
                  </a>
                </div>
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion mb-1"
                >
                  <span className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Tickets</span>
                    <span className="menu-arrow"></span>
                  </span>
                  <div className="menu-sub menu-sub-accordion">
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/support-center/tickets/list.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Tickets List</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/support-center/tickets/view.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">View Ticket</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion mb-1"
                >
                  <span className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Tutorials</span>
                    <span className="menu-arrow"></span>
                  </span>
                  <div className="menu-sub menu-sub-accordion">
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/support-center/tutorials/list.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Tutorials List</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/support-center/tutorials/post.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Tutorial Post</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/support-center/faq.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">FAQ</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/support-center/licenses.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Licenses</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/support-center/contact.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Contact Us</span>
                  </a>
                </div>
              </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
              <span className="menu-link">
                <span className="menu-icon">
                  <i className="ki-outline ki-shield-tick fs-2"></i>
                </span>
                <span className="menu-title">User Management</span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion mb-1"
                >
                  <span className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Users</span>
                    <span className="menu-arrow"></span>
                  </span>
                  <div className="menu-sub menu-sub-accordion">
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/user-management/users/list.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Users List</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/user-management/users/view.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">View User</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Roles</span>
                    <span className="menu-arrow"></span>
                  </span>
                  <div className="menu-sub menu-sub-accordion">
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/user-management/roles/list.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Roles List</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/user-management/roles/view.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">View Role</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="menu-item">
                  <a
                    className="menu-link"
                    href="apps/user-management/permissions.html"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Permissions</span>
                  </a>
                </div>
              </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
              <span className="menu-link">
                <span className="menu-icon">
                  <i className="ki-outline ki-briefcase fs-2"></i>
                </span>
                <span className="menu-title">Customers</span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <a
                    className="menu-link"
                    href="apps/customers/getting-started.html"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Getting Started</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/customers/list.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Customer Listing</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/customers/view.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Customer Details</span>
                  </a>
                </div>
              </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
              <span className="menu-link">
                <span className="menu-icon">
                  <i className="ki-outline ki-map fs-2"></i>
                </span>
                <span className="menu-title">Subscription</span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <a
                    className="menu-link"
                    href="apps/subscriptions/getting-started.html"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Getting Started</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/subscriptions/list.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Subscription List</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/subscriptions/add.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Add Subscription</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/subscriptions/view.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">View Subscription</span>
                  </a>
                </div>
              </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
              <span className="menu-link">
                <span className="menu-icon">
                  <i className="ki-outline ki-credit-cart fs-2"></i>
                </span>
                <span className="menu-title">Invoice Manager</span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">View Invoices</span>
                    <span className="menu-arrow"></span>
                  </span>
                  <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/invoices/view/invoice-1.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Invoice 1</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/invoices/view/invoice-2.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Invoice 2</span>
                      </a>
                    </div>
                    <div className="menu-item">
                      <a
                        className="menu-link"
                        href="apps/invoices/view/invoice-3.html"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Invoice 3</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/invoices/create.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Create Invoice</span>
                  </a>
                </div>
              </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
              <span className="menu-link">
                <span className="menu-icon">
                  <i className="ki-outline ki-file-added fs-2"></i>
                </span>
                <span className="menu-title">File Manager</span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <a className="menu-link" href="apps/file-manager/folders.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Folders</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/file-manager/files.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Files</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/file-manager/blank.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Blank Directory</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/file-manager/settings.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Settings</span>
                  </a>
                </div>
              </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
              <span className="menu-link">
                <span className="menu-icon">
                  <i className="ki-outline ki-sms fs-2"></i>
                </span>
                <span className="menu-title">Inbox</span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <a className="menu-link" href="apps/inbox/listing.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Messages</span>
                    <span className="menu-badge">
                      <span className="badge badge-success">3</span>
                    </span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/inbox/compose.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Compose</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/inbox/reply.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">View & Reply</span>
                  </a>
                </div>
              </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
              <span className="menu-link">
                <span className="menu-icon">
                  <i className="ki-outline ki-message-text-2 fs-2"></i>
                </span>
                <span className="menu-title">Chat</span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <a className="menu-link" href="apps/chat/private.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Private Chat</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/chat/group.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Group Chat</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link" href="apps/chat/drawer.html">
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Drawer Chat</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <a className="menu-link active" href="apps/calendar.html">
                <span className="menu-icon">
                  <i className="ki-outline ki-calendar-8 fs-2"></i>
                </span>
                <span className="menu-title">Calendar</span>
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default Sidebar
