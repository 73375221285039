import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-cards'
import 'swiper/css/effect-coverflow'
import 'swiper/css/effect-cube'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-flip'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import {
  Autoplay,
  EffectCards,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  EffectFlip,
  Navigation
} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

function Player({ source }) {
  const data = useSelector(state => state.user)

  const [playlistContent, setPlaylistContent] = useState(source?.content || [])
  const [currentEffect, setCurrentEffect] = useState('slide')
  const [isMuted, setIsMuted] = useState(true)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [controlsVisible, setControlsVisible] = useState(false)
  const [slideDurations, setSlideDurations] = useState({})
  const swiperRefs = useRef({})
  const playerRef = useRef(null)
  const isFullscreenChange = useRef(false)
  const hideControlsTimeout = useRef(null)

  useEffect(() => {
    setPlaylistContent(source?.content || [])
  }, [data.source])

  useEffect(() => {
    const durations = {}
    for (const key in playlistContent) {
      durations[key] = playlistContent[key].playlist.map(
        item => item?.meta?.duration || 5
      )
    }
    setSlideDurations(durations)
  }, [playlistContent])

  const handleMouseEnter = () => {
    clearTimeout(hideControlsTimeout.current)
  }

  const handleMouseMove = () => {
    setControlsVisible(true)
    clearTimeout(hideControlsTimeout.current)
    hideControlsTimeout.current = setTimeout(() => {
      setControlsVisible(false)
    }, 2500)
  }

  const handleSlideChangeTransitionEnd = useCallback(swiperInstance => {
    swiperInstance.slides.forEach(slide => {
      const video = slide.querySelector('video')
      if (slide.classList.contains('swiper-slide-active') && video) {
        video.play().catch(error => {
          console.error('Error playing video:', error)
        })
      } else if (video) {
        video.pause()
        video.currentTime = 0
      }
    })
  }, [])

  const updateAutoplayDelay = useCallback(
    (swiper, index) => {
      const currentIndex = swiper.realIndex
      const slideDuration = (slideDurations[index]?.[currentIndex] || 5) * 1000

      if (swiper.params.autoplay) {
        swiper.params.autoplay.delay = slideDuration
        swiper.autoplay.stop()
        swiper.autoplay.start()
      } else {
        swiper.params.autoplay = {
          delay: slideDuration,
          disableOnInteraction: false
        }
        swiper.autoplay.start()
      }

      swiper.slides.forEach(slide => {
        const video = slide.querySelector('video')
        if (slide.classList.contains('swiper-slide-active') && video) {
          video.play().catch(error => {
            console.error('Error playing video:', error)
          })
        } else if (video) {
          video.pause()
          video.currentTime = 0
        }
      })
    },
    [slideDurations]
  )

  const handleInit = useCallback(
    (swiper, index) => {
      updateAutoplayDelay(swiper, index)
      handleSlideChangeTransitionEnd(swiper)
    },
    [updateAutoplayDelay, handleSlideChangeTransitionEnd]
  )

  const handleFullScreenChange = useCallback(() => {
    if (isFullscreenChange.current) return
    if (document.fullscreenElement) {
      setIsFullscreen(true)
      for (const key in swiperRefs.current) {
        const swiperInstance = swiperRefs.current[key]
        updateAutoplayDelay(swiperInstance, key)
      }
    } else {
      setIsFullscreen(false)
      for (const key in swiperRefs.current) {
        const swiperInstance = swiperRefs.current[key]
        updateAutoplayDelay(swiperInstance, key)
      }
    }
  }, [updateAutoplayDelay])

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange)
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange)
    document.addEventListener('mozfullscreenchange', handleFullScreenChange)
    document.addEventListener('MSFullscreenChange', handleFullScreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange)
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullScreenChange
      )
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullScreenChange
      )
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange)
    }
  }, [handleFullScreenChange])

  return (
    <div
      ref={playerRef}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      className="h-full"
    >
      {source?.layout?.properties?.boxes
        ? source?.layout?.properties?.boxes.map((resx, index) => {
            const playlist = playlistContent[index]?.playlist

            return (
              <div
                className="aspect-video w-full"
                key={index}
                id={`container-${index}`}
              >
                {playlist && (
                  <Swiper
                    className="h-full w-full"
                    key={`${index}-${playlist.length}`}
                    onSwiper={swiper => {
                      swiperRefs.current[index] = swiper
                      updateAutoplayDelay(swiper, index)
                    }}
                    onInit={swiper => handleInit(swiper, index)}
                    modules={[
                      Navigation,
                      Autoplay,
                      EffectFade,
                      EffectCube,
                      EffectCards,
                      EffectCoverflow,
                      EffectFlip
                    ]}
                    effect={currentEffect}
                    loop={true}
                    navigation={true}
                    pagination={false}
                    autoplay={{
                      disableOnInteraction: false
                    }}
                    onSlideChangeTransitionEnd={swiper =>
                      handleSlideChangeTransitionEnd(swiper)
                    }
                    onSlideChange={swiper => updateAutoplayDelay(swiper, index)}
                  >
                    {playlist.map((res, idx) => {
                      const isVideo = res?.type === 'video'
                      const mediaUrl = isVideo
                        ? `https://vz-d99c6c4e-749.b-cdn.net/${res?.meta?.video_id}/original`
                        : `https://${res?.domain}/${res?.path}/${res?.file}`

                      return (
                        <SwiperSlide key={idx}>
                          <div className="flex h-full w-full items-center justify-center">
                            {isVideo ? (
                              <video
                                className="h-full w-full"
                                src={mediaUrl}
                                style={{
                                  objectFit: `${res?.meta.objectFit || 'cover'}`
                                }}
                                muted={isMuted}
                                playsInline={true}
                                allowFullScreen={true}
                                loop={playlist.length === 1}
                                onPlay={() =>
                                  console.log(
                                    `Playing video: ${res?.meta.video_id}`
                                  )
                                }
                                onPause={() =>
                                  console.log(
                                    `Paused video: ${res?.meta.video_id}`
                                  )
                                }
                                onLoadedData={() =>
                                  console.log(
                                    `Loaded video data: ${res?.meta.video_id}`
                                  )
                                }
                              ></video>
                            ) : res.type==="image"? (
                              <img
                                className="h-full w-full"
                                style={{
                                  objectFit: `${res?.meta.objectFit || 'cover'}`
                                }}
                                src={mediaUrl}
                              />
                            ):( <div className="h-full w-full [&>*]:h-full [&>*]:w-full">
                              <iframe scrolling='no' style={{overflow:"hidden"}} src={res.path}>
                              
                              </iframe>
                             </div>)}
                          </div>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                )}
              </div>
            )
          })
        : null}
    </div>
  )
}

export default Player
