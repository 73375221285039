import {
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selected_id } from '@/redux/user-slice'
import axios from 'axios'

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const SelectPlaylistLayout = ({ setStep }) => {
  const { t } = useTranslation();
  const [layouts, setLayout] = useState([]);
  const [colors, setColors] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedTab, setSelectedTab] = useState("horizontal");
  const user = useSelector(res => res.user);
  const dispatch = useDispatch();

  function getLayoutList() {
    axios.get(process.env.REACT_APP_API_BASE + "/v1/source/layout", {
      headers: { Authorization: `Bearer ${user.user.user.token}` }
    }).then(res => {
      setLayout(res?.data);
      setColors(res?.data?.map(() => getRandomColor()));
    });
  }

  useEffect(() => {
    getLayoutList();
  }, []);

  // Gelen veride name "v-single" ise dikey, değilse yatay ekran olarak filtrele
  const filteredLayouts = layouts.filter(layout => {
    if (selectedTab === 'vertical') {
      return layout.name === 'v-single';
    }
    return layout.name !== 'v-single';
  });

  const handleAddPlaylist = () => {
    if (selectedId) {
      console.log('Creating playlist for layout ID:', selectedId);
      setStep(2); // Veya uygun adım numarasına geçiş
    } else {
      console.log('No layout selected');
    }
  };

  return (
    <>
      <DialogHeader>
        <DialogTitle>{t('Select Layout')}</DialogTitle>
      </DialogHeader>

      <div className="tabs-container">
        <span 
          className={`tab-button ${selectedTab === 'horizontal' ? 'active' : ''}`}
          onClick={() => setSelectedTab('horizontal')}
        >
          {t('Horizontal')}
        </span >
        <span 
          className={`tab-button ${selectedTab === 'vertical' ? 'active' : ''}`}
          onClick={() => setSelectedTab('vertical')}
        >
          {t('Vertical')}
        </span >
      </div>

      <div className="modal-body px-lg-17 grid max-h-[500px] grid-cols-2 gap-4 overflow-y-auto py-10">
        {filteredLayouts?.map((res, index) => {
          return (
            <div className="" key={res._id}>
              <label
                id={res._id}
                style={{ cursor: "pointer", justifyContent: "center", textAlign: "center", alignItems: "center" }}
                onClick={() => {
                  setSelectedId(res._id);
                  dispatch(selected_id(res._id));
                }}
                htmlFor={`layout-${index}`}
                className={` ${res._id === selectedId ? "selected-layaout" : ""} app-blank w-full rounded border ring-0 ring-primary transition-all peer-checked:ring ${res.properties.container === "flex-col" ? "flex-column" : res.properties.container} d-flex`}
              >
                {res?.properties?.boxes ? res.properties.boxes.map((resx, boxIndex) => {
                  return (
                    <div
                      key={boxIndex}
                      className={`${resx.classes}`}
                      style={{ position: 'relative' }}
                    >
                      <span className="screenIndex" style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '20px',
                        fontWeight: 'bold'
                      }}>{boxIndex + 1}</span>
                    </div>
                  )
                }) : null}
              </label>
            </div>
          );
        })}
      </div>

      <DialogFooter>
        <button className="btn btn-light" onClick={() => setStep(1)}>
          {t('Back')}
        </button>
        <button className="btn btn-primary" onClick={handleAddPlaylist}>
          {t('Add Playlist')}
        </button>
      </DialogFooter>
    </>
  );
}

export default SelectPlaylistLayout;
