import React, { useState } from "react";
import axios from "axios";
import avatar from "../../assets/img/media/avatars/blank.png"

function Settings(props) {
  const [formData, setFormData] = useState({
    avatar: null,
    fname: "",
    lname: "",
    company: "",
    phone: "",
    website: "",
    communication: [],
    allowMarketing: true,
    birthdate: "",
    merchant_name: "",
    identity_number: "",
    logo: "",
    description: "",
    address: "",
    country: "",
    city: "",
    postcode: "",
    image: "",
    full_name: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (name === "allowMarketing") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
        }));
      } else {
        setFormData((prevData) => {
          const updatedCommunication = prevData.communication.includes(value)
            ? prevData.communication.filter((item) => item !== value)
            : [...prevData.communication, value];
          return {
            ...prevData,
            communication: updatedCommunication,
          };
        });
      }
    } else if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.files[0],
      }));
    } else {
      setFormData((prevData) => {
        const updatedData = { ...prevData, [name]: value };

        if (name === "fname" || name === "lname") {
          updatedData.full_name = `${updatedData.fname} ${updatedData.lname}`.trim();
        }

        return updatedData;
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedFormData = {
      fname: formData.fname || props.data.userInfo.profile.fname,
      lname: formData.lname || props.data.userInfo.profile.lname,
      company: formData.company || props.data.userInfo.profile.company,
      phone: formData.phone || props.data.userInfo.profile.phone,
      website: formData.website || props.data.userInfo.profile.website,
      communication: formData.communication.length > 0 ? formData.communication : props.data.userInfo.profile.communication,
      allowMarketing: formData.allowMarketing !== undefined ? formData.allowMarketing : props.data.userInfo.profile.allowMarketing,
      birthdate: formData.birthdate || props.data.userInfo.profile.birthdate,
      merchant_name: formData.merchant_name || props.data.userInfo.profile.merchant_name,
      identity_number: formData.identity_number || props.data.userInfo.profile.identity_number,
      description: formData.description || props.data.userInfo.profile.description,
      address: formData.address || props.data.userInfo.profile.address,
      country: formData.country || props.data.userInfo.profile.country,
      city: formData.city || props.data.userInfo.profile.city,
      postcode: formData.postcode || props.data.userInfo.profile.postcode,
      full_name: formData.full_name || `${props.data.userInfo.profile.fname} ${props.data.userInfo.profile.lname}`.trim(),
      image:"",
      logo:""
    };

    if (formData.avatar) {
      updatedFormData.avatar = formData.avatar;
    }
    if (formData.logo) {
      updatedFormData.logo = formData.logo;
    }
    if (formData.image) {
      updatedFormData.image = formData.image;
    }

    axios
      .post(process.env.REACT_APP_API_BASE + "/v1/profile", updatedFormData, {
        headers: {
          Authorization: `Bearer ${props?.data?.user?.token}`,
          "Content-Type": "Application/json"
        }
      })
      .then((res) => {
        console.log("Profile updated successfully", res.data);
        // Başarılı güncelleme mesajı göster
      })
      .catch((err) => {
        console.error("Error updating profile", err);
        // Hata mesajı göster
      });
  };

  const handleReset = () => {
    setFormData({
      avatar: null,
      fname: "",
      lname: "",
      company: "",
      phone: "",
      website: "",
      communication: [],
      allowMarketing: true,
      birthdate: "",
      merchant_name: "",
      identity_number: "",
      logo: "",
      description: "",
      address: "",
      country: "",
      city: "",
      postcode: "",
      image: "",
      full_name: "",
    });
    console.log("Form reset to initial values");
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return '';
  
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <div>
      <div className="card mb-5 mb-xl-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Profile Details</h3>
          </div>
        </div>

        <div id="kt_account_settings_profile_details" className="show">
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                Avatar
              </label>
              <div className="col-lg-8">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                  style={{
                    backgroundImage: 'url(assets/media/avatars/blank.svg)',
                  }}
                >
                  <img
                   src={avatar}
                    className="image-input-wrapper w-125px h-125px"
                   
                  />
                  <label
                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="change"
                    data-bs-toggle="tooltip"
                    title="Change avatar"
                  >
                    <i className="ki-outline ki-pencil fs-7" />
                    <input
                      type="file"
                      name="avatar"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleInputChange}
                    />
                    <input type="hidden" name="avatar_remove" />
                  </label>
                  <span
                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="cancel"
                    data-bs-toggle="tooltip"
                    title="Cancel avatar"
                  >
                    <i className="ki-outline ki-cross fs-2" />
                  </span>
                  <span
                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="remove"
                    data-bs-toggle="tooltip"
                    title="Remove avatar"
                  >
                    <i className="ki-outline ki-cross fs-2" />
                  </span>
                </div>
                <div className="form-text">
                  Allowed file types: png, jpg, jpeg.
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                Full Name
              </label>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      name="fname"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="First name"
                      defaultValue={props?.data?.userInfo?.profile?.fname}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      name="lname"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Last name"
                      defaultValue={props.data.userInfo.profile.lname}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                Birthdate
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="date"
                  name="birthdate"
                  className="form-control form-control-lg form-control-solid"
                  defaultValue={formatDate(props?.data?.userInfo?.profile?.birthdate)}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                Contact Phone
              </label>
              <div className="col-lg-8">
                <div className="col-lg-6 fv-row">
                  <input
                    type="text"
                    name="phone"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Phone number"
                    defaultValue={props.data.userInfo.profile.phone}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                Company Name
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  name="merchant_name"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Company name"
                  defaultValue={props.data.userInfo.profile.merchant_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                Identity Number
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  name="identity_number"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Identity number"
                  defaultValue={props?.data?.userInfo?.profile?.identity_number}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                Description
              </label>
              <div className="col-lg-8 fv-row">
                <textarea
                  name="description"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Description"
                  defaultValue={props.data.userInfo.profile.description}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                Address
              </label>
              <div className="col-lg-8 fv-row">
                <textarea
                  name="address"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Address"
                  defaultValue={props?.data?.userInfo?.profile?.address}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                Country
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  name="country"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Country"
                  defaultValue={props?.data?.userInfo?.profile?.country}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                City
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  name="city"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="City"
                  defaultValue={props?.data?.userInfo?.profile?.city}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                Zip Code
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  name="postcode"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Postcode"
                  defaultValue={props.data.userInfo.profile.postcode}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="button"
                className="btn btn-light btn-active-light-primary me-2"
                onClick={handleReset}
              >
                Discard
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                id="kt_account_profile_details_submit"
                onClick={handleSubmit}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;