import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import {
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { addGroup } from '../../../redux/user-slice'
import useSWR, { useSWRConfig } from 'swr'
const AddScreenGroup = ({ data, groupId, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const allScreens = useSelector(state => state.user.screen)
  const [availableScreens, setAvailableScreens] = useState([])
  const [selectedScreens, setSelectedScreens] = useState([])
  const [searchAvailable, setSearchAvailable] = useState('')
  const [searchSelected, setSearchSelected] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { mutate } = useSWRConfig()
  useEffect(() => {
    const initialSelectedScreens = data.original.screens || []
    const screensToSelect = allScreens.filter(screen =>
      initialSelectedScreens.some(selected => selected._id === screen._id)
    )
    const screensToDeselect = allScreens.filter(screen =>
      !initialSelectedScreens.some(selected => selected._id === screen._id)
    )

    setSelectedScreens(screensToSelect)
    setAvailableScreens(screensToDeselect)
  }, [allScreens, data.original.screens])

  const handleAddScreen = (screen) => {
    setSelectedScreens([...selectedScreens, screen])
    setAvailableScreens(availableScreens.filter(s => s._id !== screen._id))
  }

  const handleRemoveScreen = (screen) => {
    setAvailableScreens([...availableScreens, screen])
    setSelectedScreens(selectedScreens.filter(s => s._id !== screen._id))
  }

  const filteredAvailableScreens = availableScreens.filter(screen =>
    screen?.name?.toLowerCase().includes(searchAvailable.toLowerCase())
  )

  const filteredSelectedScreens = selectedScreens.filter(screen =>
    screen?.name?.toLowerCase().includes(searchSelected.toLowerCase())
  )
async function  getAllGroup(){
 await axios.get(`${process.env.REACT_APP_API_BASE}/v1/group`).then(res=>{
  
  dispatch(addGroup(res.data))
 
 })
}
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
     await axios.post(`${process.env.REACT_APP_API_BASE}/v1/group/${data.original._id}/screens`, {
        screen: { selectedScreens }
      }).then(response=>{

        console.log(response)
        if (response.status === 200) {
         getAllGroup()
          
         
        
     
        } else {
          // Hata durumunu işle
          console.error('API request failed')
        }
      })
   
    
    } catch (error) {
      console.error('Error submitting screens:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogHeader>
        <DialogTitle>{t('Add Screen')}</DialogTitle>
      </DialogHeader>

      <div className="modal-body px-17 py-10">
        <div className="screen-manager">
          <div className="screens-section">
            <h3>Screens</h3>
            <input
            style={{width:"100%"}}
              type="text"
              placeholder="Search Screen"
              value={searchAvailable}
              onChange={(e) => setSearchAvailable(e.target.value)}
            />
            <ul className='ul-grup'>
              {filteredAvailableScreens.map(screen => (
                <li key={screen._id}>
                  <input
                    type="checkbox"
                    onClick={() => handleAddScreen(screen)}
                  />
                  {screen.name}
                </li>
              ))}
            </ul>
          </div>

          <div className="screens-section">
            <h3>Selected Screens</h3>
            <input
             style={{width:"100%"}}
              type="text"
              placeholder="Search Selected Screen"
              value={searchSelected}
              onChange={(e) => setSearchSelected(e.target.value)}
            />
            <ul className='ul-grup'>
              {filteredSelectedScreens.map(screen => (
                <li key={screen._id}>
                  <button type="button" onClick={() => handleRemoveScreen(screen)}>❌</button>
                  {screen.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <DialogFooter>
        <DialogClose className="btn btn-light" type="button">{t('Cancel')}</DialogClose>
        <DialogClose type="submit" className="btn btn-primary" disabled={isSubmitting}>
          {isSubmitting ? t('Submitting...') : t('Add Screen')}
        </DialogClose>
      </DialogFooter>
    </form>
  )
}

export default AddScreenGroup
