import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isMobile: false,
    registered: false,
    language: 'English',
    user: {},
    screen: [],
    group: [],
    screensStatus: [],
    sideMenu: 'Dashboard',
    playlist: {},
    selected_playlist: {},
    edit_playlist_status: false,
    breadcrumbs: [],
    layout_id: '',
    lastEventTimestamps: {},
    
    theme:"light"
  },
  reducers: {
    changeRegisterStatus: (state, action) => {
      state.registered = action.payload
    },
    setUserInfo: (state, action) => {
      state.user = action.payload
    },
    addScreen: (state, action) => {
      state.screen = action.payload
    },
    addGroup: (state, action) => {
     
      state.group = action.payload
    },
    changeSideMenuName: (state, action) => {
      state.sideMenu = action.payload
    },
    createPlaylist: (state, action) => {
      state.playlist = action.payload
    },
    updateScreensStatus(state, action) {
      const { id, status, timestamp } = action.payload;
    
      return {
        ...state,
        screensStatus: {
          ...state.screensStatus,
          [id]: { status, timestamp }
        }
      };
    },
    selectedPlaylist: (state, action) => {
      state.selected_playlist = action.payload
    },
    changePlaylistEditStatus: (state, action) => {
      state.edit_playlist_status = action.payload
    },

    addBreadcrumbs: (state, action) => {
      const { _id } = action.payload
      if (state.breadcrumbs.length > 0) {
        state.breadcrumbs.forEach((item, index) => {
          if (_id === item._id) {
            state.breadcrumbs = state.breadcrumbs.slice(0, index + 1)
          } else {
            state.breadcrumbs = [...state.breadcrumbs, action.payload]
          }
        })
      } else {
        state.breadcrumbs = [...state.breadcrumbs, action.payload]
      }
    },
    selected_id: (state, action) => {
      state.layout_id = action.payload
    },
    addLanguage: (state, action) => {
      state.language = action.payload
    },
    updateLastEventTimestamps(state, action) {
      const { screenId, timestamp } = action.payload;
      state.lastEventTimestamps = {
        ...state.lastEventTimestamps, // Mevcut verileri koru
        [screenId]: timestamp, // Yeni veriyi ekle veya güncelle
      };
    },
    markOffline(state, action) {
      const { screenId } = action.payload;
      console.log("dasfgdasgf",screenId)
      // Ekranı offline olarak işaretleyin
      const { [screenId]: _, ...rest } = state.lastEventTimestamps;
      state.lastEventTimestamps = rest;
    },
}
})

export const {
  changeRegisterStatus,
  setUserInfo,
  addScreen,
  changeSideMenuName,
  createPlaylist,
  selectedPlaylist,
  changePlaylistEditStatus,
  addBreadcrumbs,
  selected_id,
  updateScreensStatus,
  addLanguage,
  updateLastEventTimestamps,
  markOffline,
  addGroup
} = userSlice.actions

export default userSlice.reducer
