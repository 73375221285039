import moment from 'moment'

const todayDate = moment().startOf('day')
const YM = todayDate.format('YYYY-MM')
const YESTERDAY = todayDate.clone().subtract(1, 'day').format('YYYY-MM-DD')
const TODAY = todayDate.format('YYYY-MM-DD')
const TOMORROW = todayDate.clone().add(1, 'day').format('YYYY-MM-DD')

const uid = () => {
  return Date.now().toString() + Math.floor(Math.random() * 1000).toString()
}

const events = [
  {
    id: uid(),
    title: 'All Day Event',
    start: YM + '-01',
    end: YM + '-02',
    description: 'Toto lorem ipsum dolor sit incid idunt ut',
    className: 'border-success bg-success text-inverse-success',
    location: 'Federation Square'
  },
  {
    id: uid(),
    title: 'Reporting',
    start: YM + '-14T13:30:00',
    description: 'Lorem ipsum dolor incid idunt ut labore',
    end: YM + '-14T14:30:00',
    className: 'border-warning bg-warning text-inverse-success',
    location: 'Meeting Room 7.03'
  },
  {
    id: uid(),
    title: 'Company Trip',
    start: YM + '-02',
    description: 'Lorem ipsum dolor sit tempor incid',
    end: YM + '-03',
    className: 'border-info bg-info text-info-success',
    location: 'Seoul, Korea'
  },
  {
    id: uid(),
    title: 'ICT Expo 2021 - Product Release',
    start: YM + '-03',
    description: 'Lorem ipsum dolor sit tempor inci',
    end: YM + '-05',
    className: 'fc-event-light fc-event-solid-primary',
    location: 'Melbourne Exhibition Hall'
  },
  {
    id: uid(),
    title: 'Dinner',
    start: YM + '-12',
    description: 'Lorem ipsum dolor sit amet, conse ctetur',
    end: YM + '-13',
    location: "Squire's Loft"
  },
  {
    id: uid(),
    title: 'Repeating Event',
    start: YM + '-09T16:00:00',
    end: YM + '-09T17:00:00',
    description: 'Lorem ipsum dolor sit ncididunt ut labore',
    className: 'fc-event-danger',
    location: 'General Area'
  },
  {
    id: uid(),
    title: 'Repeating Event',
    description: 'Lorem ipsum dolor sit amet, labore',
    start: YM + '-16T16:00:00',
    end: YM + '-16T17:00:00',
    location: 'General Area'
  },
  {
    id: uid(),
    title: 'Conference',
    start: YESTERDAY,
    end: TOMORROW,
    description: 'Lorem ipsum dolor eius mod tempor labore',
    className: 'fc-event-primary',
    location: 'Conference Hall A'
  },
  {
    id: uid(),
    title: 'Meeting',
    start: TODAY + 'T10:30:00',
    end: TODAY + 'T12:30:00',
    description: 'Lorem ipsum dolor eiu idunt ut labore',
    location: 'Meeting Room 11.06'
  },
  {
    id: uid(),
    title: 'Meeting',
    start: TODAY + 'T14:30:00',
    end: TODAY + 'T15:30:00',
    className: 'fc-event-warning',
    description: 'Lorem ipsum conse ctetur adipi scing',
    location: 'Meeting Room 11.10'
  },
  {
    id: uid(),
    title: 'Happy Hour',
    start: TODAY + 'T17:30:00',
    end: TODAY + 'T21:30:00',
    className: 'fc-event-info',
    description: 'Lorem ipsum dolor sit amet, conse ctetur',
    location: 'The English Pub'
  },
  {
    id: uid(),
    title: 'Dinner',
    start: TOMORROW + 'T18:00:00',
    end: TOMORROW + 'T21:00:00',
    className: 'fc-event-solid-danger fc-event-light',
    description: 'Lorem ipsum dolor sit ctetur adipi scing',
    location: 'New York Steakhouse'
  },
  {
    id: uid(),
    title: 'Birthday Party',
    start: TOMORROW + 'T12:00:00',
    end: TOMORROW + 'T14:00:00',
    className: 'fc-event-primary',
    description: 'Lorem ipsum dolor sit amet, scing',
    location: 'The English Pub'
  },
  {
    id: uid(),
    title: 'Site visit',
    start: YM + '-28',
    end: YM + '-29',
    className: 'fc-event-solid-info fc-event-light',
    description: 'Lorem ipsum dolor sit amet, labore',
    location: '271, Spring Street'
  }
]

export default events
