import { Outlet } from 'react-router-dom'

import {
  Dropdown,
  DropdownContent,
  DropdownTrigger
} from '@/components/ui/dropdown'
import {
  getLanguageByISO,
  languages,
  useTranslation
} from '@/localization/i18n'

export const AuthenticationLayout = () => {
  const { t, i18n } = useTranslation()

  return (
    <div
      className="d-flex flex-column flex-root app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat h-full"
      style={{ backgroundImage: "url('/assets/media/auth/bg.jpg')" }}
      data-bs-theme="light"
    >
      <div className="d-flex flex-column flex-column-fluid flex-lg-row">
        <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
          <div className="d-flex flex-center flex-lg-start flex-column">
            <img className="h-60px mb-7" alt="Logo" src="/maia.svg" />

            <h2 className="fw-normal m-0 text-2xl text-white">
              {t('Maia digital signage made simple')}
            </h2>
          </div>
        </div>

        <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-lg-20 p-12">
          <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
            <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
              <Outlet />
            </div>

            <div className="d-flex flex-stack px-lg-10">
              <div className="me-0">
                <Dropdown>
                  <DropdownTrigger
                    trigger="click"
                    placement="bottom-start"
                    offset="0px, 0px"
                  >
                    <button className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base">
                      <img
                        data-kt-element="current-lang-flag"
                        className="w-20px h-20px me-3 rounded"
                        src={getLanguageByISO(i18n.language)?.flag}
                        alt=""
                      />
                      <span
                        data-kt-element="current-lang-name"
                        className="me-1"
                      >
                        {getLanguageByISO(i18n.language)?.name}
                      </span>
                      <i className="ki-outline ki-up fs-5 text-muted m-0 rotate-180"></i>{' '}
                    </button>
                  </DropdownTrigger>
                  <DropdownContent>
                    {languages.map(res => {
                      return (
                        <div className="menu-item px-3" key={res.name}>
                          <span
                            className="menu-link d-flex px-5"
                            onClick={() => i18n.changeLanguage(res.ISO)}
                          >
                            <span className="symbol symbol-20px me-4">
                              <img
                                className="rounded-1"
                                src={res.flag}
                                alt="flag"
                              />
                            </span>
                            <span>{res.name}</span>
                          </span>
                        </div>
                      )
                    })}
                  </DropdownContent>
                </Dropdown>
              </div>

              <div className="d-flex fw-semibold fs-base text-primary gap-5">
                <a href="/metronic8/demo56/pages/team.html" target="_blank">
                  {t('Terms')}
                </a>

                <a
                  href="/metronic8/demo56/pages/pricing/column.html"
                  target="_blank"
                >
                  {t('Plans')}
                </a>

                <a href="/metronic8/demo56/pages/contact.html" target="_blank">
                  {t('Contact Us')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthenticationLayout
