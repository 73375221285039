import { useEffect } from 'react'
import { useRef } from 'react';

const Dropdown = ({ children, className, ...props }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (dropdownRef.current) {
      const menu = window.KTMenu.getInstance(dropdownRef.current);
      if (!menu) {
        window.KTMenu.createInstances();
      }
    }
  }, []);

  return (
    <div ref={dropdownRef} className={className} {...props}>
      {children}
    </div>
  )
}

const DropdownTrigger = ({ children, className, ...props }) => {
  return (
    <div
      className={className}
      data-kt-menu-trigger={props.trigger}
      data-kt-menu-placement={props.placement}
      data-kt-menu-offset={props.offset}
    >
      {children}
    </div>
  )
}

const DropdownContent = ({ children, className, ...props }) => {
  return (
    <div
      className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold fs-6 w-auto py-4 ${className}`}
      data-kt-menu
      {...props}
    >
      {children}
    </div>
  )
}

export { Dropdown, DropdownTrigger, DropdownContent }
