import React, { useState } from 'react';
import cct from 'credit-card-type';
import visaImage from '../../assets/img/media/svg/card-logos/visa.svg'; // Kart resimlerinin yolu
import mastercardImage from '../../assets/img/media/svg/card-logos/mastercard.svg';
import amexImage from '../../assets/img/media/svg/card-logos/american-express.svg';
import axios from 'axios';
import { toast } from 'react-toastify';

const NewCardModal = ({ isOpen, onClose, props }) => {
    const [cardInfo, setCardInfo] = useState({
        cardName: '',
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvv: '',
        saveCard: true
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCardInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API_BASE+"/v1/payment/cards", {
            name: cardInfo.cardName,
            card_number: cardInfo.cardNumber,
            exp_month: cardInfo.expiryMonth,
            exp_year: cardInfo.expiryYear,
            cvv: cardInfo.cvv,
            is_default: false
        },

            { headers: { Authorization: `Bearer ${props?.user?.token}` } }).then(res=>{
                if(res.status===200){
                    onClose()
                    toast.success("Kart Başarıyla Eklendi")
                }
            })
    
    };

    // Kart türünü belirle
    const getCardType = (number) => {
        const types = cct(number);
        return types.length > 0 ? types[0].type : 'unknown';
    };

    const cardType = getCardType(cardInfo.cardNumber);

    const getCardImage = (type) => {
        switch (type) {
            case 'visa':
                return visaImage;
            case 'mastercard':
                return mastercardImage;
            case 'amex':
                return amexImage;
            default:
                return null;
        }
    };

    const cardImage = getCardImage(cardType);

    if (!isOpen) return null;

    return (
        <div className="testmodal modal fade show" style={{ display: 'block' }} tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content">
                    <div className="modal-header justify-content-center">
                        <h2 style={{ fontSize: "calc(1.275rem + .3vw)", fontWeight: "600", textAlign: "center" }}>Add New Card</h2>
                    </div>

                    <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-7">
                                <label className="form-label required">Name On Card</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="cardName"
                                    value={cardInfo.cardName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <label className="form-label required">Card Number</label>
                            <div className="d-flex mb-7">

                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="cardNumber"
                                    value={cardInfo.cardNumber}
                                    onChange={handleInputChange}
                                    required
                                />
                                {/* Kart markasını gösteren resim */}
                                <div style={{ position: "absolute", right: "23px" }} className="card-image mt-2 text-center">
                                    {cardImage && <img src={cardImage} alt={cardType} style={{ width: '50px' }} />}
                                </div>
                            </div>

                            <div className="row mb-7">
                                <div className="col-md-8">
                                    <label className="form-label required">Expiration Date</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <select
                                                name="expiryMonth"
                                                className="form-select"
                                                value={cardInfo.expiryMonth}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Month</option>
                                                {[...Array(12)].map((_, i) => (
                                                    <option key={i} value={i + 1}>{i + 1}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-6">
                                            <select
                                                name="expiryYear"
                                                className="form-select"
                                                value={cardInfo.expiryYear}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Year</option>
                                                {[...Array(11)].map((_, i) => {
                                                    const year = new Date().getFullYear() + i;
                                                    return <option key={year} value={year}>{year}</option>;
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label required">CVV</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-solid"
                                        name="cvv"
                                        value={cardInfo.cvv}
                                        onChange={handleInputChange}
                                        minLength="3"
                                        maxLength="4"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <label className="fs-6 fw-semibold form-label">Save Card for further billing?</label>
                                    <div class="fs-7 fw-semibold text-muted">If you need more info, please check budget planning</div>
                                </div>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="saveCard"
                                        checked={cardInfo.saveCard}
                                        onChange={(e) => setCardInfo(prev => ({ ...prev, saveCard: e.target.checked }))}
                                    />
                                </div>
                            </div>

                            <div className="text-center pt-15">
                                <button type="button" className="btn btn-light me-3" onClick={onClose}>
                                    Discard
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewCardModal;
