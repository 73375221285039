import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { selectedPlaylist } from '@/redux/user-slice'
import { formDataToJSON } from '@/utils/formDataToJSON'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import AddPlaylist from './add-playlist'
import SelectPlaylistLayout from './select-playlist-layout'

const DataTable = ({ data, columns, loading }) => {
  const [sorting, setSorting] = useState([])
  const [columnFilters, setColumnFilters] = useState([])
  const [step, setStep] = useState(1)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { mutate } = useSWRConfig()
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const table = useReactTable({
    data: data || [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),

    state: { sorting, columnFilters }
  })

  function handleSubmit(e) {
    e.preventDefault()

    const formData = new FormData(e.target)
    const jsonData = formDataToJSON(formData)

    axios
      .post(`${process.env.REACT_APP_API_BASE}/v1/source/`, {
        name: jsonData.name,
        layout: user.layout_id,
        source_type: 'playlist'
      })
      .then(response => {
        if (response.status === 200) {
          toast.success(t('Playlist created'))

          navigate(`/playlists/${response.data._id}`)
          dispatch(selectedPlaylist(response.data))
        } else {
          toast.error(t('Error creating playlist'))
        }
      })
      .catch(error => {
        toast.error(t('Error creating playlist'))
        console.error(error)

        // Handle error
      })
  }

  return (
    <div className="card" data-select2-id="select2-data-129-o87l">
      <div
        className="card-header d-flex flex-column border-0"
        data-protonpass-form=""
        data-select2-id="select2-data-128-aqkb"
      >
        <div className="d-flex justify-content-between pt-4">
          <div className="card-title align-items-start flex w-full flex-col">
            <div className="flex w-full flex-1 justify-between">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bold text-gray-900">
                  {t('Playlists')}
                </span>
                <span class="fw-semibold fs-6 text-gray-500 mt-1">
                  {t('Total Playlist')} {data?.length}
                </span>
              </h3>
            </div>
            <div className="my-1 mt-4 flex w-full justify-between">
              <div className="d-flex align-items-center position-relative">
                <i className="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
                <input
                  className="form-control form-control-solid w-250px ps-12"
                  placeholder={t('Search Playlist')}
                  onChange={e =>
                    table.getColumn('name').setFilterValue(e.target.value)
                  }
                />
              </div>
              <div className="card-toolbar">
                <div className="d-flex justify-content-end">
                  <Dialog>
                    <DialogTrigger className="btn btn-primary er fs-6 px-8 py-4">
                      {t('Create Playlist')}
                    </DialogTrigger>
                    <DialogContent>
                      <form onSubmit={e => handleSubmit(e)}>
                        <Tabs
                          defaultValue="addPlaylist"
                          value={step == 1 ? 'addPlaylist' : 'selectLayout'}
                        >
                          <TabsList className="hidden">
                            <TabsTrigger value="addPlaylist">
                              Add Playlist
                            </TabsTrigger>
                            <TabsTrigger value="selectLayout">
                              Select Layout
                            </TabsTrigger>
                          </TabsList>
                          <TabsContent
                            value="addPlaylist"
                            forceMount
                            className="data-[state=inactive]:hidden"
                          >
                            <AddPlaylist setStep={setStep} />
                          </TabsContent>
                          <TabsContent value="selectLayout">
                            <SelectPlaylistLayout setStep={setStep} />
                          </TabsContent>
                        </Tabs>
                      </form>
                    </DialogContent>
                  </Dialog>
                </div>
                <div
                  className="d-flex justify-content-end align-items-center d-none"
                  data-kt-subscription-table-toolbar="selected"
                >
                  <div className="fw-bold me-5">
                    <span
                      className="me-2"
                      data-kt-subscription-table-select="selected_count"
                    ></span>{' '}
                    Selected
                  </div>

                  <button
                    type="button"
                    className="btn btn-danger"
                    data-kt-subscription-table-select="delete_selected"
                  >
                    Delete Selected
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-0">
          <div
            id="kt_subscriptions_table_wrapper"
            className="dt-container dt-bootstrap5 dt-empty-footer"
          >
            <div id="" className="table-responsive">
              <Table>
                <TableHeader className="uppercase">
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {loading ? (
                    Array(5)
                      .fill(0)
                      .map((_, index) => (
                        <TableRow className="overflow-hidden" key={index}>
                          {Array(2)
                            .fill(0)
                            .map((cell, index) => (
                              <TableCell key={index}>
                                <div
                                  className={`spinner-border spinner-border-sm ${index == 1 && 'invisible'}`}
                                ></div>
                              </TableCell>
                            ))}
                        </TableRow>
                      ))
                  ) : table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && 'selected'}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div id="" className="row pb-4">
            <div
              id=""
              className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar"
            ></div>
            <div
              id=""
              className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
            >
              <div className="dt-paging paging_simple_numbers">
                <ul className="pagination">
                  {table.getCanPreviousPage() ? (
                    <>
                      <li
                        className="dt-paging-button page-item cursor-pointer"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                      >
                        <span className="page-link previous">
                          <i className="previous"></i>
                        </span>
                      </li>
                      <li
                        className="dt-paging-button page-item cursor-pointer"
                        onClick={() => table.previousPage()}
                      >
                        <span className="page-link">
                          {table.options.state.pagination.pageIndex}
                        </span>
                      </li>
                    </>
                  ) : (
                    ''
                  )}
                  <li className="dt-paging-button page-item active">
                    <span className="page-link">
                      {table.options.state.pagination.pageIndex + 1}
                    </span>
                  </li>
                  {table.getCanNextPage() ? (
                    <>
                      <li
                        className="dt-paging-button page-item cursor-pointer"
                        onClick={() => table.nextPage()}
                      >
                        <span className="page-link">
                          {table.options.state.pagination.pageIndex + 2}
                        </span>
                      </li>
                      <li
                        className="dt-paging-button page-item cursor-pointer"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                      >
                        <span className="page-link next">
                          <i className="next"></i>
                        </span>
                      </li>
                    </>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataTable
