import { useTranslation } from 'react-i18next'

import {
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'

const AddGroup = ({ addGroupHandler }) => {
  const { t } = useTranslation()

  return (
    <form onSubmit={ addGroupHandler}>
      <DialogHeader>
        <DialogTitle>{t('Create Group')}</DialogTitle>
      </DialogHeader>

      <div className="modal-body px-17 py-10">
        <div className="fv-row mb-9">
          <label className="fs-6 fw-semibold mb-2">{t('Group Name')}</label>
          <input
            type="text"
            className="form-control form-control-solid"
            placeholder={t('Enter Group Name')}
            name="name"
            required
          />
        </div>
    {/*     <div className="fv-row mb-9">
          <label className="fs-6 fw-semibold mb-2">{t('Screen ID')}</label>
          <input
            type="text"
            className="form-control form-control-solid"
            placeholder={t('Enter Screen ID')}
            name="serial"
            required
          />
        </div>
        <div className="fv-row mb-9">
          <label className="fs-6 fw-semibold mb-2">{t('Screen Location')}</label>
          <input
            type="text"
            className="form-control form-control-solid"
            placeholder={t('Enter Screen Location')}
            name="place"
          />
        </div>
        <div className="fv-row mb-9">
          <label className="fs-6 fw-semibold mb-2">{t('Screen Tags')}</label>
          <input
            type="text"
            className="form-control form-control-solid"
            placeholder={t('Optional')}
            name="tags"
          />
        </div> */}
      </div>
      <DialogFooter>
        <DialogClose className="btn btn-light" type="button">{t('Cancel')}</DialogClose>
        <DialogClose type="submit" className="btn btn-primary">{t('Create Group')}</DialogClose>
      </DialogFooter>
    </form>
  )
}

export default AddGroup
