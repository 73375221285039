import DatePicker from '@/components/ui/date-picker'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'

import {
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import TimePicker from '../../components/ui/time-picker'

const AddEvent = ({ onClose }) => {
  const { t } = useTranslation()

  function handleSubmit(e) {
    e.preventDefault()

    Swal.fire({
      text: t('Are you sure you would like to add event?'),
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: t('Yes, add it!'),
      cancelButtonText: t('No, return'),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-active-light'
      }
    }).then(function (result) {
      if (result.value) {
        const formData = new FormData(e.target)

        axios
          .post(`${process.env.REACT_APP_API_BASE}/v1/source/`, formData)
          .then(response => {
            console.log(response.data)
            if (response.data.status === 200) {
              // Handle response
            }
          })
          .catch(error => {
            console.error(error)
            // Handle error
          })
      } else if (result.dismiss === 'cancel') {
        Swal.fire({
          text: t('Your form has not been cancelled!.'),
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: t('Ok, got it!'),
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        })
      }
    })
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle>{t('Add Schedule')}</DialogTitle>
      </DialogHeader>
      <form className="form" action="#" id="kt_modal_add_event_form">
        <div className="modal-body px-17 py-10">
          <div className="fv-row mb-9">
            <label className="fs-6 fw-semibold mb-2">{t('Event Name')}</label>

            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="calendar_event_name"
            />
          </div>

          <div className="fv-row mb-9">
            <label className="fs-6 fw-semibold mb-2">
              {t('Event Description')}
            </label>

            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="calendar_event_description"
            />
          </div>

          <div className="fv-row mb-9">
            <label className="fs-6 fw-semibold mb-2">
              {t('Event Location')}
            </label>

            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="calendar_event_location"
            />
          </div>

          <div className="fv-row mb-9">
            <label className="form-check form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="kt_calendar_datepicker_allday"
              />
              <span
                className="form-check-label fw-semibold"
                htmlFor="kt_calendar_datepicker_allday"
              >
                {t('All Day')}
              </span>
            </label>
          </div>

          <div className="row row-cols-lg-2 g-10">
            <div className="col">
              <div className="fv-row mb-9">
                <label className="fs-6 fw-semibold mb-2">
                  {t('Event Start Date')}
                </label>
                <div class="d-flex align-items-center">
                  <i class="ki-outline ki-calendar-8 fs-2 position-absolute mx-4"></i>

                  <DatePicker placeholder={t('Pick a start date')} />
                </div>
              </div>
            </div>
            <div className="col" data-kt-calendar="datepicker">
              <div className="fv-row mb-9">
                <label className="fs-6 fw-semibold mb-2">
                  {t('Event Start Time')}
                </label>

                <div class="position-relative d-flex align-items-center">
                  <i class="ki-outline ki-calendar-8 fs-2 position-absolute mx-4"></i>

                  <TimePicker placeholder={t('Pick a start time')} />
                </div>
              </div>
            </div>
          </div>

          <div className="row row-cols-lg-2 g-10">
            <div className="col">
              <div className="fv-row mb-9">
                <label className="fs-6 fw-semibold mb-2">
                  {t('Event End Date')}
                </label>

                <div class="position-relative d-flex align-items-center">
                  <i class="ki-outline ki-calendar-8 fs-2 position-absolute mx-4"></i>

                  <DatePicker placeholder={t('Pick a end date')} />
                </div>
              </div>
            </div>
            <div className="col" data-kt-calendar="datepicker">
              <div className="fv-row mb-9">
                <label className="fs-6 fw-semibold mb-2">
                  {t('Event End Time')}
                </label>

                <div class="position-relative d-flex align-items-center">
                  <i class="ki-outline ki-calendar-8 fs-2 position-absolute mx-4"></i>

                  <TimePicker placeholder={t('Pick a end time')} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <DialogFooter>
          <DialogClose className="btn btn-light" onClick={onClose}>
            {t('Cancel')}
          </DialogClose>
          <button className="btn btn-primary" type="submit">
            {t('Add Schedule')}
          </button>
        </DialogFooter>
      </form>
    </>
  )
}

export default AddEvent
