import React from "react";

function Overview(props) {
  

  return (
    <div>
      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        {/*begin::Card header*/}
        <div className="card-header cursor-pointer">
          {/*begin::Card title*/}
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Profile Details</h3>
          </div>
          {/*end::Card title*/}
          {/*begin::Action*/}
      {/*     <a
            href="account/settings.html"
            className="btn btn-sm btn-primary align-self-center"
          >
            Edit Profile
          </a> */}
          {/*end::Action*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body p-9">
          {/*begin::Row*/}
          <div className="row mb-7">
            {/*begin::Label*/}
            <label className="col-lg-4 fw-semibold text-muted">Full Name</label>
            {/*end::Label*/}
            {/*begin::Col*/}
            <div className="col-lg-8">
              <span className="fw-bold fs-6 text-gray-800">{props?.data?.userInfo?.profile?.full_name || "-"}</span>
            </div>
            {/*end::Col*/}
          </div>
          {/*end::Row*/}
          {/*begin::Input group*/}
          <div className="row mb-7">
            {/*begin::Label*/}
            <label className="col-lg-4 fw-semibold text-muted">Company</label>
            {/*end::Label*/}
            {/*begin::Col*/}
            <div className="col-lg-8 fv-row">
              <span className="fw-semibold text-gray-800 fs-6">{props?.data?.userInfo?.profile.merchant_name || "-"}</span>
            </div>
            {/*end::Col*/}
          </div>
        
          <div className="row mb-7">
            <label className="col-lg-4 fw-semibold text-muted">
              Contact Phone
              <span
                className="ms-1"
                data-bs-toggle="tooltip"
                title="Phone number must be active"
              >
                <i className="ki-outline ki-information fs-7" />
              </span>
            </label>
      
            <div className="col-lg-8 d-flex align-items-center">
              <span className="fw-bold fs-6 text-gray-800 me-2">
                {props?.data?.userInfo?.profile.phone || "-"}
              </span>
             {/*  <span className="badge badge-success">
                {props?.data?.phoneVerified ? "Verified" : "Not Verified"}
              </span> */}
            </div>
         
          </div>
          <div className="row mb-7">
       
       <label className="col-lg-4 fw-semibold text-muted">
         Country
         <span
           className="ms-1"
           data-bs-toggle="tooltip"
           title="Country of origination"
         >
           <i className="ki-outline ki-information fs-7" />
         </span>
       </label>
   
       <div className="col-lg-8">
         <span className="fw-bold fs-6 text-gray-800">{props?.data?.userInfo?.profile?.country || "-"}</span>
       </div>
 
     </div>
     <div className="row mb-7">
       
       <label className="col-lg-4 fw-semibold text-muted">
         City
         <span
           className="ms-1"
           data-bs-toggle="tooltip"
           title="Country of origination"
         >
           <i className="ki-outline ki-information fs-7" />
         </span>
       </label>
   
       <div className="col-lg-8">
         <span className="fw-bold fs-6 text-gray-800">{props?.data?.userInfo?.profile?.city || "-"}</span>
       </div>
 
     </div>
     <div className="row mb-7">
       
       <label className="col-lg-4 fw-semibold text-muted">
        Adress
         <span
           className="ms-1"
           data-bs-toggle="tooltip"
           title="Country of origination"
         >
           <i className="ki-outline ki-information fs-7" />
         </span>
       </label>
   
       <div className="col-lg-8">
         <span className="fw-bold fs-6 text-gray-800">{props?.data?.userInfo?.profile?.adress || "-"}</span>
       </div>
 
     </div>
     <div className="row mb-7">
       
       <label className="col-lg-4 fw-semibold text-muted">
        Zip Code
         <span
           className="ms-1"
           data-bs-toggle="tooltip"
           title="Country of origination"
         >
           <i className="ki-outline ki-information fs-7" />
         </span>
       </label>
   
       <div className="col-lg-8">
         <span className="fw-bold fs-6 text-gray-800">{props?.data?.userInfo?.profile?.postcode || "-"}</span>
       </div>
 
     </div>
     <div className="row mb-7">
       
       <label className="col-lg-4 fw-semibold text-muted">
        Identity Number
         <span
           className="ms-1"
           data-bs-toggle="tooltip"
           title="Country of origination"
         >
           <i className="ki-outline ki-information fs-7" />
         </span>
       </label>
   
       <div className="col-lg-8">
         <span className="fw-bold fs-6 text-gray-800">{props?.data?.userInfo?.profile?.identity_number || "-"}</span>
       </div>
 
     </div>
       
        {/*   <div className="row mb-7">
         
            <label className="col-lg-4 fw-semibold text-muted">Company Site</label>
         
            <div className="col-lg-8">
              <a
                href={props?.data?.companySite ? `https://${props?.data?.companySite}` : "#"}
                className="fw-semibold fs-6 text-gray-800 text-hover-primary"
              >
                {props?.data?.companySite || "-"}
              </a>
            </div>
         
          </div> */}
       
       {/*    <div className="row mb-7">
       
            <label className="col-lg-4 fw-semibold text-muted">
              Country
              <span
                className="ms-1"
                data-bs-toggle="tooltip"
                title="Country of origination"
              >
                <i className="ki-outline ki-information fs-7" />
              </span>
            </label>
        
            <div className="col-lg-8">
              <span className="fw-bold fs-6 text-gray-800">{props?.data?.profile?.country || "-"}</span>
            </div>
      
          </div> */}
      
       {/*    <div className="row mb-7">
            <label className="col-lg-4 fw-semibold text-muted">Communication</label>
            <div className="col-lg-8">
              <span className="fw-bold fs-6 text-gray-800">{props?.data?.communication || "-"}</span>
            </div>
          </div> */}
        
       {/*    <div className="row mb-10">
            <label className="col-lg-4 fw-semibold text-muted">Allow Changes</label>
            <div className="col-lg-8">
              <span className="fw-semibold fs-6 text-gray-800">{props?.data?.allowChanges ? "Yes" : "No"}</span>
            </div>
          </div>
         */}
          <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
            <i className="ki-outline ki-information fs-2tx text-warning me-4" />
            <div className="d-flex flex-stack flex-grow-1">
             
              <div className="fw-semibold">
                <h4 className="text-gray-900 fw-bold">We need your attention!</h4>
                <div className="fs-6 text-gray-700">
                  {props?.data?.noticeText || "Your payment was declined. To start using tools, please "}
                  <a className="fw-bold" href="account/billing.html">Add Payment Method</a>.
                </div>
              </div>
            
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Notice*/}
        </div>
        {/*end::Card body*/}
      </div>
    </div>
  );
}

export default Overview;
