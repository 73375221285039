import axios from 'axios'
import useSWR, { useSWRConfig } from 'swr'

import DataTable from './data-table'
import { useColumns } from './data-table/columns'
import { useColumns1 } from './data-table/group-columns'
import { formDataToJSON } from '@/utils/formDataToJSON'
import AddScreen from './data-table/add-screen'

import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { addScreen } from '@/redux/user-slice'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { addGroup } from '../../redux/user-slice'

const Screens = () => {
  const { mutate } = useSWRConfig()
  const fetcher = (...args) => axios.get(...args)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_API_BASE}/v1/screen`,
    fetcher
  )
  const { data: groupData, error: groupError, isValidating: groupLoading } = useSWR(
    `${process.env.REACT_APP_API_BASE}/v1/group`,
    fetcher,
    { revalidateOnFocus: false }
  );
 
  useEffect(() => {
  
    dispatch(addScreen(data?.data))
    dispatch(addGroup(groupData?.data))
  }, [data, groupData])

  useEffect(() => {
    mutate(`${process.env.REACT_APP_API_BASE}/v1/screen`)
    mutate(`${process.env.REACT_APP_API_BASE}/v1/group`)
  }, [])
 

  const dataArray = (data?.data || []).slice().reverse() || []
  const groupArray = (groupData?.data || []) || []

  function addScreenHandler(e) {
    e.preventDefault()

    try {
      const formData = new FormData(e.target)
      const jsonData = formDataToJSON(formData)

      axios
        .post(`${process.env.REACT_APP_API_BASE}/v1/screen`, jsonData)
        .then(response => {

          if (response.status === 200) {
            toast.success(t('Screen created'))

            mutate(`${process.env.REACT_APP_API_BASE}/v1/screen`)
            dispatch(addScreen(response.data))
          } else {
            toast.error(t('Error creating screen'))
          }
        })
    } catch (error) {
      toast.error(t('Error creating screen'))
      console.error(error)
    }
  }


  const columns = useColumns()
  const groupColumns = useColumns1();

  return (
    <div>
      {dataArray?.length || isLoading ? (
        <DataTable
          screenData={dataArray}
          groupData={groupArray}
          screenColumns={columns}
          groupColumns={groupColumns}
          loading={isLoading}
        />
      ) : (
        <div className="card">
          <div className="card-body p-0">
            <div className="card-px my-10 py-20 text-center">
              <h2 className="fs-2x fw-bold mb-10">{t('New Screen')}</h2>

              <p className="fs-4 fw-semibold text-gray-500 mb-10">
                {t('Click on the below buttons to launch a new screen.')}
              </p>

              <Dialog>
                <DialogTrigger className="btn btn-primary">
                  {t('Add New Screen')}
                </DialogTrigger>
                <DialogContent>
                  <AddScreen addScreenHandler={addScreenHandler} />
                </DialogContent>
              </Dialog>
            </div>

            <div className="flex justify-center px-4 text-center">
              <img
                className="mw-100 mh-300px"
                src="/assets/media/illustrations/sketchy-1/5.png"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Screens
