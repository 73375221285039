import { useParams } from 'react-router'

const FileManager = () => {
  const { type } = useParams()

  return (
    <div>
      <div
        className="card card-flush bgi-position-y-center bgi-no-repeat mb-10 pb-0"
        style={{
          backgroundSize: 'auto calc(100% + 10rem)',
          backgroundPositionX: '100%',
          backgroundImage: `url('/assets/media/illustrations/sketchy-1/4.png')`
        }}
      >
        <div className="card-header pt-10">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-circle me-5">
              <div className="symbol-label border border-dashed border-secondary bg-transparent text-primary">
                <i className="ki-outline ki-abstract-47 fs-2x text-primary"></i>{' '}
              </div>
            </div>

            <div className="d-flex flex-column">
              <h2 className="mb-1">File Manager</h2>
              <div className="fw-bold text-muted">
                2.6 GB <span className="mx-3">|</span> 758 items
              </div>
            </div>
          </div>
        </div>

        <div className="card-body pb-0"></div>
      </div>

      <div className="card card-flush">
        <div className="card-header pt-8">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <i className="ki-outline ki-magnifier fs-1 position-absolute ms-6"></i>{' '}
              <input
                type="text"
                data-kt-filemanager-table-filter="search"
                className="form-control form-control-solid w-250px ps-15"
                placeholder="Search Files & Folders"
              />
            </div>
          </div>

          <div className="card-toolbar">
            <div
              className="d-flex justify-content-end"
              data-kt-filemanager-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-flex btn-light-primary me-3"
                id="kt_file_manager_new_folder"
              >
                <i className="ki-outline ki-add-folder fs-2"></i> New Folder
              </button>

              <button
                type="button"
                className="btn btn-flex btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_upload"
              >
                <i className="ki-outline ki-folder-up fs-2"></i> Upload Files
              </button>
            </div>

            <div
              className="d-flex justify-content-end align-items-center d-none"
              data-kt-filemanager-table-toolbar="selected"
            >
              <div className="fw-bold me-5">
                <span
                  className="me-2"
                  data-kt-filemanager-table-select="selected_count"
                ></span>{' '}
                Selected
              </div>

              <button
                type="button"
                className="btn btn-danger"
                data-kt-filemanager-table-select="delete_selected"
              >
                Delete Selected
              </button>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="d-flex flex-stack">
            <div className="badge badge-lg badge-light-primary">
              <div className="d-flex align-items-center flex-wrap">
                <i className="ki-outline ki-abstract-32 fs-2 me-3 text-primary"></i>{' '}
                <a href="#">Keenthemes</a>
                <i className="ki-outline ki-right fs-2 mx-1 text-primary"></i>{' '}
                <a href="#">themes</a>
                <i className="ki-outline ki-right fs-2 mx-1 text-primary"></i>{' '}
                <a href="#">html</a>
                <i className="ki-outline ki-right fs-2 mx-1 text-primary"></i>{' '}
                demo1
              </div>
            </div>

            <div className="badge badge-lg badge-primary">
              <span id="kt_file_manager_items_counter">82 items</span>
            </div>
          </div>

          <table
            id="kt_file_manager_list"
            data-kt-filemanager-table="folders"
            className="table-row-dashed fs-6 gy-5 table align-middle"
          >
            <thead>
              <tr className="fw-bold fs-7 gs-0 text-start text-gray-500">
                <th className="w-10px pe-2">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-kt-check="true"
                      data-kt-check-target="#kt_file_manager_list .form-check-input"
                      value="1"
                    />
                  </div>
                </th>
                <th className="min-w-250px">Name</th>
                <th className="min-w-10px">Size</th>
                <th className="min-w-125px">Last Modified</th>
                <th className="w-125px"></th>
              </tr>
            </thead>
            <tbody className="fw-semibold text-gray-600">
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="account">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      account
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="apps">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      apps
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="widgets">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      widgets
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="assets">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      assets
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="documentation">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      documentation
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="layouts">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      layouts
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="modals">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      modals
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="authentication">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      authentication
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="dashboards">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      dashboards
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="media">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      media
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="customers">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      customers
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="user-management">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      user-management
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="subscriptions">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      subscriptions
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="forms">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      forms
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="buttons">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      buttons
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="datatables">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      datatables
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="account">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      account
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="apps">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      apps
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="widgets">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      widgets
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="assets">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      assets
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="documentation">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      documentation
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="layouts">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      layouts
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="modals">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      modals
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="authentication">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      authentication
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="dashboards">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      dashboards
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="media">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      media
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="customers">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      customers
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="user-management">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      user-management
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="subscriptions">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      subscriptions
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="forms">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      forms
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="buttons">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      buttons
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="datatables">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      datatables
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="account">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      account
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="apps">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      apps
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="widgets">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      widgets
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="assets">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      assets
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="documentation">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      documentation
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="layouts">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      layouts
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="modals">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      modals
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="authentication">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      authentication
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="dashboards">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      dashboards
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="media">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      media
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="customers">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      customers
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="user-management">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      user-management
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="subscriptions">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      subscriptions
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="forms">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      forms
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="buttons">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      buttons
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="datatables">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      datatables
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="account">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      account
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="apps">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      apps
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="widgets">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      widgets
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="assets">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      assets
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="documentation">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      documentation
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="layouts">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      layouts
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="modals">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      modals
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="authentication">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      authentication
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="dashboards">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      dashboards
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="media">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      media
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="customers">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      customers
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="user-management">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      user-management
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="subscriptions">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      subscriptions
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="forms">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      forms
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="buttons">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      buttons
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="datatables">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      datatables
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="account">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      account
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="apps">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      apps
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="widgets">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      widgets
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="assets">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      assets
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="documentation">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      documentation
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="layouts">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      layouts
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="modals">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      modals
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="authentication">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      authentication
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="dashboards">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      dashboards
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="media">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      media
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="customers">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      customers
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="user-management">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      user-management
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="subscriptions">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      subscriptions
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="forms">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      forms
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="buttons">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      buttons
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="datatables">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      datatables
                    </a>
                  </div>
                </td>
                <td>- </td>
                <td>- </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="index.html">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-files fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      index.html
                    </a>
                  </div>
                </td>
                <td>583 KB </td>
                <td>10 Nov 2024, 9:23 pm </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td data-order="landing.html">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-files fs-2x me-4 text-primary"></i>
                    </span>
                    <a
                      href="/metronic8/demo56/apps/file-manager/files/.html"
                      className="text-hover-primary text-gray-800"
                    >
                      landing.html
                    </a>
                  </div>
                </td>
                <td>87 KB </td>
                <td>10 Mar 2024, 11:30 am </td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                                </span>
                              </div>

                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column d-none text-start"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3"></i>{' '}
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value="https://path/to/file/or/folder/"
                              />
                              <div className="fw-normal fs-8 mt-2 px-3 text-muted">
                                Read only.{' '}
                                <a
                                  href="/metronic8/demo56/apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0"></i>{' '}
                      </button>

                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a
                            href="/metronic8/demo56/apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default FileManager
