import { useTranslation } from 'react-i18next'

import {
    DialogClose,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from '@/components/ui/dialog'
import { useSelector } from 'react-redux'

const ScreenDetail = (props) => {
 console.log(props.id.device.meta)
  
    const { t } = useTranslation()

    return (
        <>
            <DialogHeader>
                <DialogTitle>{t('Screen Detail')}</DialogTitle>
            </DialogHeader>

            <div className="modal-body px-17 py-10">
                <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Brand')}</span>
                        <span>{props?.id?.device?.meta?.brand || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('OS')}</span>
                        <span>{props?.id?.device?.meta?.system_name} {props?.id?.device?.meta?.system_version || ''}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Device ID')}</span>
                        <span>{props?.id?.device?.meta?.device_id || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Device Name')}</span>
                        <span>{props?.id?.device?.meta?.device_name || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Device Type')}</span>
                        <span>{props?.id?.device?.meta?.device_type || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Total Disk Capacity')}</span>
                        <span>{(props?.id?.device?.meta?.total_disk_capacity / (1024 * 1024 * 1024)).toFixed(2)} GB</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Free Disk Storage')}</span>
                        <span>{(props?.id?.device?.meta?.free_disk_storage / (1024 * 1024 * 1024)).toFixed(2)} GB</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Total Memory')}</span>
                        <span>{(props?.id?.device?.meta?.total_memory / (1024 * 1024 * 1024)).toFixed(2)} GB</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Battery Level')}</span>
                        <span>{props?.id?.device?.meta?.battery_level === 1 ? 'Full' : 'Low'}</span>
                    </div>
                   
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('IP Address')}</span>
                        <span>{props?.id?.device?.meta?.ip_address || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Manufacturer')}</span>
                        <span>{props?.id?.device?.meta?.manufacturer || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Model')}</span>
                        <span>{props?.id?.device?.meta?.model || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('API Level')}</span>
                        <span>{props?.id?.device?.meta?.api_level || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Application Name')}</span>
                        <span>{props?.id?.device?.meta?.application_name || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Build Number')}</span>
                        <span>{props?.id?.device?.meta?.build_number || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Bundle ID')}</span>
                        <span>{props?.id?.device?.meta?.bundle_id || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Carrier')}</span>
                        <span>{props?.id?.device?.meta?.carrier || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('MAC Address')}</span>
                        <span>{props?.id?.device?.meta?.mac_address || 'N/A'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Max Memory')}</span>
                        <span>{(props?.id?.device?.meta?.max_memory / (1024 * 1024)).toFixed(2)} MB</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Is Charging')}</span>
                        <span>{props?.id?.device?.meta?.is_charging ? 'Yes' : 'No'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Is Emulator')}</span>
                        <span>{props?.id?.device?.meta?.is_emulator ? 'Yes' : 'No'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Is Landscape')}</span>
                        <span>{props?.id?.device?.meta?.is_landscape ? 'Yes' : 'No'}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <span>{t('Is Tablet')}</span>
                        <span>{props?.id?.device?.meta?.is_tablet ? 'Yes' : 'No'}</span>
                    </div>
                    
                </div>


            </div>
            <DialogFooter>
                <DialogClose className="btn btn-light" type="button">{t('Cancel')}</DialogClose>
              
            </DialogFooter>
        </>
    )
}

export default ScreenDetail
