import Image from '@/components/image'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { useDebounce } from '@/hooks/useDebounce'
import { formDataToJSON } from '@/utils/formDataToJSON'
import {
  closestCenter,
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { VideoPlayer } from '@graphland/react-video-player'
import axios from 'axios'
import { produce } from 'immer'
import {
  BarChart2,
  Calendar,
  Clock,
  GripVertical,
  Hourglass,
  Play,
  Plus,
  SquarePen,
  Tag,
  User
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import MediaSettings from './media-settings'
import PreviewDialog from './preview-dialog'
import PublishScreen from './publish-screen'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { selectedPlaylist } from '@/redux/user-slice'
import { nanoid } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import MediaLifetimeDialog from './media-lifetime-dialog'

import {
  Dropdown,
  DropdownContent,
  DropdownTrigger
} from '@/components/ui/dropdown'
const SortableItem = ({ id, onClick, playlist, setPlaylist, setPreview }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  const { t } = useTranslation();

  function secondsToHMS(duration) {
    let hours = Math.floor(parseInt(duration) / 3600);
    let minutes = Math.floor((parseInt(duration) % 3600) / 60);
    let seconds = parseInt(duration) % 60;

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds;
  }

  return (
    <div
      className="group relative flex cursor-default select-none items-center"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <GripVertical
        className="text-muted-foreground cursor-grab"
        size={20}
        {...listeners}
      />
      <div
        style={{maxWidth:"119px"}} className="ml-2 flex h-20 w-full  flex-shrink-0 cursor-pointer items-center justify-center"
        onClick={onClick}
      >
        {id.type === 'image' && (
          <img
            className="app-blank aspect-video w-full rounded object-cover"
            loading="lazy"
            src={`https://cdn.maiasignage.com/${id.path}/${id.file}?height=480`}
            alt=""
          />
        )}
        {id.type === 'video' && (
          <img
            className="app-blank h-full w-full rounded object-cover"
            loading="lazy"
            src={`${process.env.REACT_APP_BUNNY_PULL_ZONE_URL}/${id.meta.video_id}/thumbnail.jpg`}
          />
        )}
        {id.type === 'instagram' && (
          <div className="app-blank flex h-full w-full justify-center overflow-hidden rounded object-cover p-2">
            <img
              className="h-full"
              loading="lazy"
              src="/assets/media/icons/duotune/social/instagram_logo.svg"
              alt=""
            />
          </div>
        )}
        {id.type === 'webpage' && (
          <img
            className="app-blank h-24 w-24 rounded object-cover"
            loading="lazy"
            src={"/assets/media/svg/web.svg"}
          />
        )}
      </div>
      <Dialog>
        <DialogTrigger onClick={e => e.stopPropagation()} asChild>
          <div className="mx-4 flex-1 truncate">
            <div className="truncate font-bold">{id.name}</div>
            <div className="group relative flex items-center justify-between">
              <div className="mt-2 flex h-8 items-center group-hover:hidden">
                {id.meta?.duration ? (
                  secondsToHMS(id.meta.duration)
                ) : (
                  <div className="text-gray-600 flex items-center">
                    {t('Processing')}
                    <svg
                      className="ml-2 h-4 w-4 animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                )}
              </div>
              <div className="mt-2 hidden group-hover:flex">
                <div className="flex items-center justify-center rounded-lg py-1">
                  <i className="ki-outline ki-pencil fs-2 me-0"></i>
                  <span className="ml-2 font-bold">{t('Edit')}</span>
                </div>
              </div>
            </div>
          </div>
        </DialogTrigger>
        <DialogContent className="max-h-screen overflow-y-auto">
          <MediaSettings
            playlist={id}
            setTimeline={setPlaylist}
            timeline={playlist}
            setPreview={setPreview}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const PlaylistEdit = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const user = useSelector(state => state.user)

  const [playlistContent, setPlaylistContent] = useState(() => {
    const initialContent = user?.selected_playlist?.content || [];
    return initialContent.map(frame => ({
      ...frame,
      playlist: (frame.playlist || []).map(item => ({
        ...item,
        meta: item.meta || {} // Eğer meta yoksa boş bir obje oluştur
      }))
    }));
  });
  const [videoList, setVideoList] = useState([])
  const [imageList, setImageList] = useState([])
  const [isPlaying, setIsPlaying] = useState(false)
  const [preview, setPreview] = useState({})
  const [limit, setLimit] = useState(0)
  const [activeTab, setActiveTab] = useState('images')
  const [deletePending, setDeletePending] = useState(false)
  const [selectPending, setSelectPending] = useState(false)
  const videoRef = useRef()
  const [colors, setColors] = useState([])
  const [showModal, setShowModal] = useState(false)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )
  const [selectedFrame, setSelectedFrame] = useState(0);
  const [selectedItemsPerFrame, setSelectedItemsPerFrame] = useState({
    0: 0,
    1: 0
  });
  const [searchTerm, setSearchTerm] = useState('')
  const [newFolder, setNewFolder] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)
  const [history, setHistory] = useState([])
  const [uploadImage, setUploadImage] = useState()

  const [folders, setFolders] = useState([])
  const [fileList, setFileList] = useState('')

  useEffect(() => {
    const index = selectedItemsPerFrame[selectedFrame];
    if (index !== undefined && playlistContent[selectedFrame]?.playlist[index]) {
      setPreview(playlistContent[selectedFrame].playlist[index]);
    } else {
      setPreview({});
    }
  }, [selectedItemsPerFrame, selectedFrame, playlistContent]);

  const debouncedValue = useDebounce(playlistContent[selectedFrame]?.playlist || [], 1000)

  const fetchVideoList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE}/v1/widget/video/list?limit=100`, {
          headers: { Authorization: `Bearer ${user.user.user.token}` }
      }
      )
      if (res.status === 200) {
        setVideoList(res.data.videos)
      }

    } catch (err) {
      console.log(err)
    }
  }
 console.log(imageList)
  const fetchImageList = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE}/v1/widget/image/list?limit=100`
          , {
            headers: { Authorization: `Bearer ${user.user.user.token}` }
          })

        setImageList(res.data.images)
        resolve(res.data.images)
      } catch (err) {
        console.log(err)
        reject(err)
      }
    })
  }

  const fetchTimeline = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE}/v1/source/${pathname.split('/')[2]}`
      )

      res.data.content[0].playlist.map(item => {
        item._id = nanoid()
      })
      setPlaylistContent(res.data.content)
      setPreview(res.data.content[0].playlist[0])
      dispatch(selectedPlaylist(res.data))
    } catch (err) {
      console.log(err)
    }
  }

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setPlaylistContent(prevContent => {
        const newContent = [...prevContent];
        const playlist = [...newContent[selectedFrame].playlist];
        const oldIndex = playlist.findIndex(i => i._id === active.id._id);
        const newIndex = playlist.findIndex(i => i._id === over.id._id);

        if (oldIndex !== -1 && newIndex !== -1) {
          const [movedItem] = playlist.splice(oldIndex, 1);
          playlist.splice(newIndex, 0, movedItem);
          newContent[selectedFrame] = { ...newContent[selectedFrame], playlist };

          // Seçili öğenin indeksini güncelle
          setSelectedItemsPerFrame(prev => ({
            ...prev,
            [selectedFrame]: newIndex
          }));

          // Preview'i güncelle
          setPreview(playlist[newIndex]);
        }

        return newContent;
      });
    }
  };

  const updateTimeline = e => {
    function update() {
      if (playlistContent[selectedFrame]?.playlist?.length > 0) {
        axios.put(
          process.env.REACT_APP_API_BASE +
          `/v1/source/${pathname.split('/')[2]}`,
          { content: playlistContent }
        ).then(res => {
          if (res.status === 200) {
            dispatch(selectedPlaylist(res.data))
          }
        })
      }
    }

    const timeout = setTimeout(() => {
      update()
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }

  

  useEffect(() => {
    setIsPlaying(false)
  }, [preview])

  useEffect(() => {
    updateTimeline()

    if (!Array.isArray(playlistContent[selectedFrame]?.playlist)) {
      console.log("playlistContent[selectedFrame]?.playlist is not an array");
    }

    if (playlistContent[selectedFrame]?.playlist?.length === 0) {
      setPreview({})
    }
  }, [debouncedValue])

  useEffect(() => {
    updateTimeline();
  }, [playlistContent]);

  const handleCheckboxChange = e => {
    const isChecked = e.target.checked
    const updatedPreview = {
      ...preview,
      meta: {
        ...preview.meta,
        objectFit: isChecked ? 'cover' : 'contain'
      }
    }

    setPreview(updatedPreview)

    setPlaylistContent(prevContent =>
      produce(prevContent, draft => {
        const item = draft[selectedFrame]?.playlist?.find(i => i._id === updatedPreview._id)

        if (item) {
          item.meta = updatedPreview.meta
        }
      })
    )
  }

  function totalDuration(playlist) {
    let totalDuration = 0

    if (playlist) {
      playlist?.map(item => {

        if (item.meta?.duration) {
          totalDuration += item.meta?.duration
        }
      })
    }

    return secondsToHMS(totalDuration)
  }

  function secondsToHMS(duration) {
    let hours = Math.floor(parseInt(duration) / 3600)
    let minutes = Math.floor((parseInt(duration) % 3600) / 60)
    let seconds = parseInt(duration) % 60

    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds

    return hours + ':' + minutes + ':' + seconds
  }

  function addInstagramAppHandler(e) {
    e.preventDefault()

    const formData = new FormData(e.target)
    const jsonData = formDataToJSON(formData)

    if (!selectPending) {
      setSelectPending(true)

      setTimeout(() => {
        const id = nanoid()

        setPlaylistContent(prevContent =>
          produce(prevContent, draft => {
            if (!draft[selectedFrame]) {
              draft[selectedFrame] = { playlist: [] };
            }
            draft[selectedFrame].playlist.push({
              _id: id,
              type: 'instagram',
              name: jsonData.username,
              path: 'https://img.freepik.com/premium-vector/veliky-novgorod-russia-oktober-11-2021-instagram-gradient-background_522680-261.jpg',
              meta: {
                objectFit: 'cover',
                animation: 'slide',
                duration: 15
              }
            });
          })
        );

        setPreview({
          _id: id,
          type: 'instagram',
          name: jsonData.username,
          path: 'https://img.freepik.com/premium-vector/veliky-novgorod-russia-oktober-11-2021-instagram-gradient-background_522680-261.jpg',
          meta: {
            objectFit: 'cover',
            animation: 'slide',
            duration: 15
          }
        })

        setSelectPending(false)
      }, 1000)
    }

    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE}/v1/widget/instagram`)
        .then(response => {
          if (response.status === 200) {
            toast.success('')

            mutate(`${process.env.REACT_APP_API_BASE}/v1/?`)
          } else {
            toast.error(t(''))
          }
        })
        .catch(error => {
          console.error(error)
          toast.error(error.response.data.message)
        })
    } catch (error) {
      toast.error(t(''))
      console.error(error)
    }
  }
  function addWebAppHandler(e) {
    e.preventDefault()

    const formData = new FormData(e.target)
    const jsonData = formDataToJSON(formData)


    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE}/v1/widget/webpage`, {
          url: jsonData.web,
          name: jsonData.webname,
          meta: {
            objectFit: 'cover',
            animation: 'slide',
            duration: 15
          }
        }, {
          headers: { Authorization: `Bearer ${user.user.user.token}` }
        })
        .then(response => {

          if (response.status === 200) {
            console.log(response)
            if (!selectPending) {
              setSelectPending(true)

              setTimeout(() => {

                setPlaylistContent(prevContent =>
                  produce(prevContent, draft => {
                    if (!draft[selectedFrame]) {
                      draft[selectedFrame] = { playlist: [] };
                    }
                    draft[selectedFrame].playlist.push({
                      _id: response?.data?.webpage?._id,
                      type: 'webpage',
                      name: response?.data?.webpage?.name,
                      path: response?.data?.webpage?.url,
                      meta: response?.data?.webpage?.meta
                    });
                  })
                );

                setPreview({
                  _id: id,
                  type: 'webpage',
                  name: "webpage",
                  path: response?.data?.webpage?.url,
                  meta: response?.data?.webpage?.meta
                })
                toast.success('Başarıyla Eklendi')
                setSelectPending(false)
              }, 1000)
            }
          }

          /*   if (response.status === 200) {
              toast.success('')
  
              mutate(`${process.env.REACT_APP_API_BASE}/v1/?`)
            } else {
              toast.error(t(''))
            } */
        })
        .catch(error => {
          console.error(error)
          toast.error(error.response.data.message)
        })
    } catch (error) {
      toast.error(t(''))
      console.error(error)
    }
  }
  // Fetch API kullanarak resmi Blob olarak kaydet
  async function saveImageAsBlob(imageUrl) {
    const response = await fetch(imageUrl)
    const blob = await response.blob()
    return blob
  }

  // Blob'u FormData içine koyup Axios ile gönderme
  async function sendImageToEndpoint(imageBlob) {
    const formData = new FormData()
    formData.append('image', imageBlob, 'image.jpg') // 'file' burada payload key olarak kullanılacak

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE}/v1/widget/image/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      console.log('Image uploaded successfully:', response.data)
      PMW.plugin.editor.close()

      const id = nanoid()

      fetchImageList()
        .then(images => {
          setPlaylistContent(prevContent =>
            produce(prevContent, draft => {
              if (!draft[selectedFrame]) {
                draft[selectedFrame] = { playlist: [] };
              }
              draft[selectedFrame].playlist.push({
                ...images[0],
                _id: images[0]?._id,
                meta: {
                  ...images[0].meta,
                  animation: 'slide',
                  objectFit: 'contain',
                  duration: 15
                }
              });
            })
          );

          setPreview({ ...images[0], _id: id })

          setActiveTab('images')
        })
        .catch(error => {
          console.error('Error fetching image list:', error)
        })
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleAddContent = (content) => {


    setPlaylistContent(prevContent =>
      produce(prevContent, draft => {
        if (!draft[selectedFrame]) {
          draft[selectedFrame] = { playlist: [] };
        }
        const newItem = {
          ...content,
          _id: content?._id,
          meta: {
            ...content.meta,
            animation: 'slide',
            objectFit: 'contain',
            duration: content.meta.duration || 15
          }
        };
        draft[selectedFrame].playlist.push(newItem);

        // Yeni eklenen öğeyi seçili hale getir
        const newIndex = draft[selectedFrame].playlist.length - 1;
        setSelectedItemsPerFrame(prev => ({
          ...prev,
          [selectedFrame]: newIndex
        }));

        // Preview'i güncelle
        setPreview(newItem);
      })
    );

    setSelectPending(false);
  };
  const handleDelete = () => {
    if (preview._id && !deletePending) {
      setDeletePending(true);
      const index = playlistContent[selectedFrame]?.playlist?.findIndex(i => i._id === preview._id);

      if (index !== -1) {
        // newPlaylistContent oluşturuluyor ve setState işlemi hemen yapılıyor
        const newPlaylistContent = produce(playlistContent, draft => {
          draft[selectedFrame].playlist.splice(index, 1);
        });

        setPlaylistContent(newPlaylistContent);

        // selectedItemsPerFrame ve preview güncelleniyor
        const newTimeline = newPlaylistContent[selectedFrame]?.playlist || [];
        if (newTimeline.length === 0) {
          setPreview({});
          setSelectedItemsPerFrame(prev => ({
            ...prev,
            [selectedFrame]: 0
          }));
        } else {
          const newPreviewIndex = index < newTimeline.length ? index : index - 1;

          setPreview(newTimeline[newPreviewIndex]);
          setSelectedItemsPerFrame(prev => ({
            ...prev,
            [selectedFrame]: newPreviewIndex
          }));
        }

        // Sunucuya güncellenmiş içerik gönderiliyor
        axios.put(
          `${process.env.REACT_APP_API_BASE}/v1/source/${pathname.split('/')[2]}`,
          { content: newPlaylistContent }
        )
          .then(() => {
            setDeletePending(false);
          })
          .catch(err => {
            console.error(err);
            setDeletePending(false);
          });
      } else {
        setDeletePending(false);
      }
    }
  };
  function formatDate(dateString, language) {
    const date = new Date(dateString)

    let locale
    switch (language) {
      case 'English':
        locale = 'en-US'
        break
      case 'Turkish':
        locale = 'tr-TR'
        break
      case 'French':
        locale = 'fr-FR'
        break
      default:
        locale = 'en-US'
    }

    return date.toLocaleString(locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  function getFolder(id) {
    axios
      .get(
        process.env.REACT_APP_API_BASE +
        `/v1/filemanager/folder/${id === undefined ? '' : id}`, {
        headers: { Authorization: `Bearer ${user.user.user.token}` }
      }
      )
      .then(res => {
        setFolders(res?.data)
      })
  }

  useEffect(() => {
    getFolder(fileList?._id)
  }, [fileList])

  function selectedFolder(data) {
    setHistory(prevHistory => [...prevHistory, data])
    setFileList(data)
  }

  function createFolder() {

    axios
      .post(process.env.REACT_APP_API_BASE + '/v1/filemanager', {
        name: newFolder,
        parent: fileList?._id === undefined ? '' : fileList?._id
      }, { headers: { Authorization: `Bearer ${user.user.user.token}` } })
      .then(res => {
        if (res.status === 200) {
          const element = document.getElementById(
            'create_folder'
          )
          element.classList.add('d-none')
          setNewFolder("")
          getFolder(fileList?._id)
        }


      })
  }
  function renameFolder(folderId, newName) {
    axios
      .put(process.env.REACT_APP_API_BASE + `/v1/filemanager/${folderId}`, {
        name: newName
      })
      .then(res => {
        toast.success(t('Folder name changed successfully'))
        getFolder(fileList?._id) // Klasör listesini güncellemek için
      })
      .catch(error => {
        console.error(error)
        toast.error(t('An error occurred while changing the folder name'))
      })
  }
  function deleteFolder(id) {
    axios.delete(process.env.REACT_APP_API_BASE + `/v1/filemanager/${id}`).then(res => {
      if (res.status === 200) {
        toast.success("Dosya Başırıyla Silindi.")
        getFolder(fileList?._id)
      }
    }
    )
  }
  async function deleteFile(res1) {


    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE}/v1/widget/${res1._id}`,
        {
          headers: { Authorization: `Bearer ${user.user.user.token}` },
          data: {
            id: res1._id,
            widget: res1.type
          }
        }
      );

      if (response.status === 200) {
        toast.success("Dosya Başarıyla Silindi.");
        getFolder(fileList?._id);
      }
    } catch (error) {
      console.error("Silme işlemi sırasında hata oluştu:", error);
    }
  }
  /* function countTotalItems(fileList) {
    let folderCount
    let fileCount
    if (fileList) {

      folderCount = fileList?.folders ? fileList?.folders?.length : 0
      fileCount = fileList?.files ? fileList?.files?.length : 0
    }
    return folderCount + fileCount
  } */
  function handleBreadcrumbClick(index) {
    setHistory(prevHistory => prevHistory.slice(0, index + 1))
    setFileList(history[index])
  }

  const filteredFolders = folders.folders?.filter(folder =>
    folder.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  const filteredFiles = folders.files?.filter(file =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  function handleUploadAll() {
    if (uploadImage) {
      const formData = new FormData()
      formData.append(
        `${uploadImage.type.includes('video') ? 'video' : 'image'}`,
        uploadImage
      )
      let folderParams = ''
      if (fileList?._id !== undefined) {
        folderParams = `?folder=${fileList._id}`
      }
      axios
        .post(
          process.env.REACT_APP_API_BASE +
          `/v1/widget/${uploadImage.type.includes('video') ? 'video' : 'image'}/upload${folderParams}`,
          formData,
          {
            onUploadProgress: progressEvent => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              setUploadProgress(percentCompleted)
              console.log(`Yükleme ilerlemesi: ${percentCompleted}%`)
            }
          }
        )
        .then(response => {
          console.log(response.status)
          if (response.status === 200) {

          
              toast.success(t('Your file has been uploaded successfully'))
              setShowModal(false)
              getFolder(fileList?._id)
           
          }
        })
        .catch(error => {
          console.error(error)
          toast.error(t('An error occurred while uploading the file'))
        })
    }
  }

  const handleRemoveAll = () => {
    setUploadImage(null)
    setUploadProgress(0)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    handleUploadAll()
  }

  useEffect(() => {
    fetchVideoList()
    fetchImageList()
    fetchTimeline()
  }, [folders])
  return (
    <div className="grid h-full grid-cols-[100px_1fr_200px] gap-4 lg:grid-cols-[150px_1fr_250px] xl:grid-cols-[300px_1fr_400px]">
      <div className="card flex basis-0 flex-col gap-2">
        <div className="border-bottom fw-bold fs-7 gs-0 text-gray-500 flex h-16 items-center justify-center border-dashed text-center">
          {t('CONTENT')}
        </div>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={playlistContent[selectedFrame]?.playlist || []}
            strategy={verticalListSortingStrategy}
          >
            <div className="flex flex-1 basis-0 flex-col gap-4 overflow-y-auto p-2">
              {Array.isArray(playlistContent[selectedFrame]?.playlist) &&
                playlistContent[selectedFrame]?.playlist?.map((item, index) => (
                  <SortableItem
                    playlist={playlistContent[selectedFrame]?.playlist}
                    setPlaylist={newTimeline => {
                      setPlaylistContent(prevContent => {
                        const updatedContent = produce(prevContent, draft => {
                          draft[selectedFrame].playlist = newTimeline;
                        });
                        return updatedContent;
                      });
                    }}
                    setPreview={setPreview}
                    key={item._id + index}
                    id={item}
                    onClick={() => {
                      setPreview(item);
                      setSelectedItemsPerFrame(prev => ({
                        ...prev,
                        [selectedFrame]: index
                      }));
                    }}
                  />
                ))}
            </div>
          </SortableContext>
          <DragOverlay
            className="cursor-grab"
            dropAnimation={{
              sideEffects: defaultDropAnimationSideEffects({
                styles: {
                  active: {
                    opacity: '1'
                  }
                }
              })
            }}
          ></DragOverlay>
        </DndContext>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div className="card flex h-20 flex-row items-center justify-between px-4">
            <div className="flex items-center gap-4">
              <Dialog>
                <DialogTrigger onClick={e => e.stopPropagation()} asChild>
                  <div className="btn btn-sm btn-light btn-active-light-primary flex items-center">
                    <i className="ki-outline ki-time fs-2"></i>
                    <span className="ml-2">{t('Media Lifetime')}</span>
                  </div>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle className="flex items-center justify-center text-xl">
                      {t('Media Lifetime')}
                    </DialogTitle>
                  </DialogHeader>
                  <MediaLifetimeDialog />
                </DialogContent>
              </Dialog>

              {/*  <span>

                {t('Total Duration')}: {totalDuration(playlistContent[selectedFrame]?.playlist) || '00:00:00'}
              </span> */}
            </div>
            <div className="flex items-center gap-4">
              <Dialog>
                <DialogTrigger>
                  <div className="btn btn-sm btn-light btn-active-light-primary flex items-center">
                    <i className="ki-outline ki-eye fs-2"></i>
                    <span className="ml-2">{t('Preview')}</span>
                  </div>
                </DialogTrigger>
                <DialogContent className="prvlay">
                  <PreviewDialog />
                </DialogContent>
              </Dialog>
              <Dialog>
                <DialogTrigger>
                  <div className="btn btn-sm btn-light btn-active-light-primary flex items-center">
                    <i className="ki-outline ki-exit-up fs-2"></i>
                    <span className="ml-2">{t('Publish')}</span>
                  </div>
                </DialogTrigger>
                <DialogContent>
                  <PublishScreen />
                </DialogContent>
              </Dialog>
              <span
                className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                onClick={e => {
                  e.stopPropagation()
                  handleDelete();
                }}
              >
                {!deletePending ? (
                  <i className="ki-outline ki-trash fs-2"></i>
                ) : (
                  <svg
                    className="h-4 w-4 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
              </span>
            </div>
          </div>
          <div className="card flex aspect-video  justify-center overflow-hidden ">
            <div className="d-flex flex-column gap-10">
              <div style={{ justifyContent: "center", alignItems: "center" }}
                className={`item-center d-flex ${user?.selected_playlist.layout?.properties?.container === 'flex-col'
                  ? 'flex-column'
                  : user?.selected_playlist.layout?.properties?.container
                  }`}
              >
                {user?.selected_playlist.layout?.properties?.boxes
                  ? user?.selected_playlist?.layout?.properties?.boxes.map(
                    (resx, index) => {

                      return (
                        <div
                          key={index}
                          style={{
                            cursor: 'pointer',
                            position: 'relative',
                            zIndex: 1,
                            backgroundColor: colors[index]
                          }}
                          id={`container-${index}`}
                          className={`stretchy-wrapper ${resx.classes} ${resx.classes}-extend`}
                          onClick={() => setSelectedFrame(index)}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              zIndex: 2,
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex"
                            }}
                            onClick={() => setSelectedFrame(index)}
                          >
                            {playlistContent[index]?.playlist[selectedItemsPerFrame[index]]?.type === 'image' && (
                              <img
                                style={{
                                  objectFit: `${playlistContent[index]?.playlist[
                                    selectedItemsPerFrame[index] ?? 0
                                  ]?.meta.objectFit}`,
                                  height: '-webkit-fill-available'
                                }}
                                src={
                                  playlistContent !== undefined &&
                                    playlistContent[index]?.playlist[
                                      selectedItemsPerFrame[index] ?? 0
                                    ]?.type === 'image'
                                    ? 'https://' +
                                    playlistContent[index]?.playlist[
                                      selectedItemsPerFrame[index] ?? 0
                                    ]?.domain +
                                    '/' +
                                    playlistContent[index]?.playlist[
                                      selectedItemsPerFrame[index] ?? 0
                                    ]?.path +
                                    '/' +
                                    playlistContent[index]?.playlist[
                                      selectedItemsPerFrame[index] ?? 0
                                    ]?.file
                                    : playlistContent[index]?.playlist[
                                      selectedItemsPerFrame[index] ?? 0
                                    ]?.type === 'video'
                                      ? `https://vz-d99c6c4e-749.b-cdn.net/${playlistContent[index]?.playlist[selectedItemsPerFrame[index] ?? 0].meta.video_id}/preview.webp`
                                      : null
                                }
                                width={'100%'}
                              />
                            )}

                            {playlistContent[index]?.playlist[selectedItemsPerFrame[index]]?.type === 'video' && (
                              <div className="relative flex aspect-video h-full w-full items-center justify-center">
                                <div
                                  className={`pointer-events-none absolute z-10 rounded-full bg-icon p-4 transition-all ${isPlaying ? 'opacity-0' : 'cursor-pointer opacity-100'
                                    }`}
                                >
                                  <Play fill="#0f251f" stroke="#0f251f" />
                                </div>
                                <div className="h-full w-full [&>*]:h-full [&>*]:w-full">
                                  <VideoPlayer
                                    key={preview?.meta?.video_id}
                                    onPlay={() => setIsPlaying(true)}
                                    onPause={() => setIsPlaying(false)}
                                    theme="forest"
                                    poster={`${process.env.REACT_APP_BUNNY_PULL_ZONE_URL}/${playlistContent[index]?.playlist[selectedItemsPerFrame[index]]?.meta?.video_id}/thumbnail.jpg`}
                                    sources={[
                                      {
                                        src: `${process.env.REACT_APP_BUNNY_PULL_ZONE_URL}/${playlistContent[index]?.playlist[selectedItemsPerFrame[index]]?.meta?.video_id}/original`,
                                        type: 'video/mp4'
                                      }
                                    ]}
                                  />
                                </div>
                              </div>
                            )}
                            {playlistContent[index]?.playlist[selectedItemsPerFrame[index]]?.type === 'webpage' && (
                              <div className="relative flex aspect-video h-full w-full items-center justify-center">

                                <div className="h-full w-full [&>*]:h-full [&>*]:w-full">
                                  <iframe scrolling='no' style={{ overflow: "hidden" }} src={playlistContent[index]?.playlist[selectedItemsPerFrame[index]]?.path}>

                                  </iframe>
                                </div>
                              </div>
                            )}

                            {!playlistContent[index]?.playlist[
                              selectedItemsPerFrame[index] ?? 0
                            ]?.type && (
                                <div className="text-xl d-flex flex-col">
                              
                                 
                                  <span style={{ fontSize: 22, textAlign: "center",color:"#99A1B7" }}>{resx.resolution.replace("-", "x")}</span>
                                </div>
                              )}
                          </div>
                        </div>
                      )
                    }
                  )
                  : null}

              </div>
            </div>

          </div>
        </div>

        <div className="card flex-1">
          <div className="card-body flex flex-1 basis-0 flex-col justify-center overflow-hidden">
            <div className="grid grid-cols-3 gap-4 text-lg">
              <div className="flex items-center border rounded-md px-4 py-2">
                <Clock className="mr-2" size={18} />
                <span>{t('Total Duration')}: {totalDuration(playlistContent[selectedFrame]?.playlist) || '00:03:52'}</span>
              </div>
              <div className="flex items-center border rounded-md px-4 py-2">
                <Tag className="mr-2" size={18} />
                <span>
                  {t('Content')}: {playlistContent[selectedFrame]?.playlist?.length || 0} {t('Items')}
                </span>
              </div>
              <div className="flex items-center border rounded-md px-4 py-2">
                <BarChart2 className="mr-2" size={18} />
                <span>
                  {t('Content Types')}: 75% {t('Images')}
                </span>
              </div>
              <div className="flex items-center border rounded-md px-4 py-2">
                <Calendar className="mr-2" size={18} />
                <span>{t('Last Updated')}: 03/07/2024</span>
              </div>
              <div className="flex items-center border rounded-md px-4 py-2">
                <Hourglass className="mr-2" size={18} />
                <span>{t('Media Lifetime')}: 08/01 - 08/31</span>
              </div>
              <div className="flex items-center border rounded-md px-4 py-2">
                <User className="mr-2" size={18} />
                <span>{t('Created by')}: Maia</span>
              </div>
            </div>
          </div>
        </div>




      </div >

      <Tabs value={activeTab} onValueChange={e => setActiveTab(e)} asChild>
        <div className="flex basis-0 flex-col gap-4">
          <div className="card flex min-h-20 items-center justify-center">
            <TabsList>
              <TabsTrigger value="images">{t('Images')}</TabsTrigger>
              <TabsTrigger value="videos">{t('Videos')}</TabsTrigger>
              <TabsTrigger value="media">{t('Media')}</TabsTrigger>
              <TabsTrigger value="apps">{t('Apps')}</TabsTrigger>
            </TabsList>
          </div>
          <TabsContent
            value="images"
            className="card flex-1 basis-0 overflow-y-auto p-2"
          >
            <div className="grid select-none grid-cols-2 gap-4 xl:grid-cols-3">
              {imageList?.length > 0
                && imageList.map((image, index) => (
                  <div
                    className="group relative flex cursor-pointer items-center justify-center"
                    key={index}
                    onClick={() => {
                      if (!selectPending) {
                        setSelectPending(true);
                        setTimeout(() => {
                          handleAddContent(image);
                        }, 1000);
                      }
                    }}
                  >
                    <Image
                      className="app-blank aspect-video w-full rounded object-cover"
                      src={`https://cdn.maiasignage.com/${image.path}/${image.file}?height=360`}
                      alt=""
                      loading="lazy"
                    />
                    <div className="absolute inset-0 flex items-center justify-center rounded text-white opacity-0 transition-all group-hover:bg-black/50 group-hover:!opacity-100">
                      {selectPending ? (
                        <svg
                          className="h-8 w-8 animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        <Plus size={32} />
                      )}
                    </div>
                  </div>
                ))
               /*  : Array(30)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className="app-blank aspect-video w-full rounded"
                    />
                  )) */}
            </div>
          </TabsContent>
          <TabsContent
            value="videos"
            className="card flex-1 basis-0 overflow-y-auto p-2"
          >
            <div className="grid select-none grid-cols-2 gap-4 xl:grid-cols-3">
              {videoList.map((video, index) => (
                <div
                  className="group relative flex aspect-video cursor-pointer items-center justify-center"
                  key={index}
                  onClick={() => {
                    if (!selectPending) {
                      setSelectPending(true);
                      setTimeout(() => {
                        handleAddContent(video);
                      }, 1000);
                    }
                  }}
                >
                  <Image
                    className="app-blank h-full w-full rounded object-cover"
                    loading="lazy"
                    src={`${process.env.REACT_APP_BUNNY_PULL_ZONE_URL}/${video.meta.video_id}/thumbnail.jpg`}
                    alt=""
                  />
                  <div className="absolute inset-0 flex items-center justify-center rounded text-white opacity-0 transition-all group-hover:bg-black/50 group-hover:!opacity-100">
                    {selectPending ? (
                      <svg
                        className="h-8 w-8 animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      <Plus size={32} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </TabsContent>
          <TabsContent
            value="apps"
            className="card flex-1 basis-0 overflow-y-auto p-2"
          >
            <div className="grid grid-cols-2 gap-4 xl:grid-cols-3">
              {/*  <div className="app-blank group relative flex cursor-pointer items-center justify-center overflow-hidden rounded p-6">
                <img
                  className="app-blank h-full w-full object-cover"
                  loading="lazy"
                  src="/assets/media/icons/duotune/social/instagram_logo.svg"
                  alt=""
                />
                <Dialog>
                  <DialogTrigger>
                    <div className="absolute inset-0 flex items-center justify-center rounded text-white opacity-0 transition-all group-hover:bg-black/50 group-hover:!opacity-100">
                      <Plus size={32} />
                    </div>
                  </DialogTrigger>
                  <DialogContent>
                    <form onSubmit={e => addInstagramAppHandler(e)}>
                      <DialogHeader>
                        <DialogTitle>{t('Add Instagram App')}</DialogTitle>
                      </DialogHeader>

                      <div className="modal-body px-17 py-10">
                        <div className="fv-row mb-9">
                          <label className="fs-6 fw-semibold mb-2">
                            {t('Username')}
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder={t('Username')}
                            name="username"
                            required
                          />
                        </div>
                        <div className="fv-row mb-9">
                          <label className="fs-6 fw-semibold mb-2">
                            {t('Limit')}
                          </label>
                          <div className="position-relative w-md-300px">
                            <button
                              type="button"
                              className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0"
                              onClick={() => limit > 1 && setLimit(limit - 1)}
                            >
                              <i className="ki-outline ki-minus-square fs-1"></i>{' '}
                            </button>

                            <input
                              type="text"
                              className="form-control form-control-solid border-0 ps-12"
                              data-kt-dialer-control="input"
                              name={t('Max post count')}
                              readOnly
                              value={limit}
                            />

                            <button
                              type="button"
                              className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                              onClick={() => setLimit(limit + 1)}
                            >
                              <i className="ki-outline ki-plus-square fs-1"></i>{' '}
                            </button>
                          </div>
                        </div>
                      </div>
                      <DialogFooter>
                        <DialogClose className="btn btn-light">
                          {t('Cancel')}
                        </DialogClose>
                        <DialogClose className="btn btn-primary" type="submit">
                          {t('Add App')}
                        </DialogClose>
                      </DialogFooter>
                    </form>
                  </DialogContent>
                </Dialog>
              </div> */}
              <div className="app-blank group relative flex cursor-pointer items-center justify-center overflow-hidden rounded p-6">
                <img
                  className="app-blank h-full w-full object-cover"
                  loading="lazy"
                  src="/assets/media/postermywall.png"
                  alt=""
                />
                <div
                  className="absolute inset-0 flex items-center justify-center rounded text-white opacity-0 transition-all group-hover:bg-black/50 group-hover:!opacity-100"
                  onClick={() => {
                    PMW.plugin.editor.open(
                      'image',
                      async e => {
                        if (e) {
                          console.log('Editor closed, design ID: ' + e);
                          const imageUrl = e; // e değişkeni resim linkini içeriyor
                          const imageBlob = await saveImageAsBlob(imageUrl);
                          await sendImageToEndpoint(imageBlob);
                        } else {
                          console.log('Editor closed without saving.');
                        }
                      },
                      'export',
                      '',
                      ['upgrade']
                    );
                  }}
                >
                  <SquarePen size={32} />
                </div>
              </div>
              {/*    <div className="app-blank group relative flex cursor-pointer items-center justify-center overflow-hidden rounded p-6">
                <img
                  className="app-blank h-full w-full object-cover"
                  loading="lazy"
                  src="/assets/media/svg/web.svg"
                  alt=""
                />
                <Dialog>
                  <DialogTrigger>
                    <div className="absolute inset-0 flex items-center justify-center rounded text-white opacity-0 transition-all group-hover:bg-black/50 group-hover:!opacity-100">
                      <Plus size={32} />
                    </div>
                  </DialogTrigger>
                  <DialogContent>
                    <form onSubmit={e => addWebAppHandler(e)}>
                      <DialogHeader>
                        <DialogTitle>{t('Add Website')}</DialogTitle>
                      </DialogHeader>

                      <div className="modal-body px-17 py-10">
                        <div className="fv-row mb-9">
                          <label className="fs-6 fw-semibold mb-2">
                            {t('Enter Name')}
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder='Web Page Name'
                            name="webname"
                            required
                          />
                        </div>
                        <div className="fv-row mb-9">
                          <label className="fs-6 fw-semibold mb-2">
                            {t('Enter URL')}
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            defaultValue={"https://example.com"}
                            name="web"
                            required
                          />
                        </div>


                      </div>
                      <DialogFooter>
                        <DialogClose className="btn btn-light">
                          {t('Cancel')}
                        </DialogClose>
                        <DialogClose className="btn btn-primary" type="submit">
                          {t('Add App')}
                        </DialogClose>
                      </DialogFooter>
                    </form>
                  </DialogContent>
                </Dialog>
              </div> */}
            </div>
          </TabsContent>
          <TabsContent
            value="media"
            className="card flex-1 basis-0 overflow-y-auto p-2"
          >
            <div className="card-toolbar">
              <div
                style={{ padding: "5px" }}
                className="d-flex justify-content-center"
                data-kt-filemanager-table-toolbar="base"
              >
                <button

                  style={{ width: "100%", height: "46px",justifyContent:"center" }}

                  onClick={() => {
                    const element = document.getElementById(
                      'create_folder'
                    )
                    element.classList.remove('d-none')
                  }}
                  type="button"
                  className="btn btn-flex btn-light-primary me-3"
                  id="kt_file_manager_new_folder"
                >
                  <i className="ki-outline ki-add-folder fs-2"></i>
                  {t('New Folder')}
                </button>

                <Dialog open={showModal}>
                  <DialogTrigger
                    style={{ width: "100%", height: "46px" }}
                    className="btn btn-primary er fs-6 px-8 py-4"
                    onClick={() => setShowModal(true)}
                  >
                    <i className="ki-outline ki-folder-up fs-2"></i>
                    {t('Upload Files')}
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>{t('Upload Files')}</DialogTitle>
                    </DialogHeader>
                    <div className="modal-body pb-15 px-lg-17 pt-10">
                      <div className="form-group">
                        <div
                          className="dropzone-queue mb-2"
                          id="kt_modal_upload_dropzone"
                        >
                          <div className="d-flex">
                            <button
                              className="dropzone-select btn btn-sm btn-primary"
                              onClick={() =>
                                document.getElementById('fileInput').click()
                              }
                            >
                              {t('Attach Files')}
                            </button>
                            <span className="form-text fs-6 text-muted ms-3">
                              {/*   {uploadImage ? '1 file selected' : 'No files selected'} */}
                            </span>
                            {uploadImage && (
                              <>
                                <button
                                  className="dropzone-select btn btn-sm btn-light-primary"
                                  onClick={handleUploadAll}
                                >
                                  {t('Upload All')}
                                </button>
                                <button
                                  className="dropzone-select btn btn-sm btn-light-danger ms-2"
                                  onClick={handleRemoveAll}
                                >
                                  {t('Remove All')}
                                </button>
                              </>
                            )}
                          </div>

                          <input
                            type="file"
                            id="fileInput"
                            className="form-control d-none"
                            onChange={e => setUploadImage(e.target.files[0])}
                          />

                          {uploadImage && (
                            <>
                              <div
                                className="dropzone-items vm-200px bg-gray-100 mt-3"
                                style={{ borderRadius: '10px' }}
                              >
                                <div className="dropzone-item dz-image-preview d-flex flex-row p-5">
                                  <div
                                    className="dropzone-file"
                                    style={{
                                      flex: 1,
                                      margin: '0 10px',
                                      alignSelf: 'center'
                                    }}
                                  >
                                    <div className="dropzone-filename">
                                      <span>
                                        {uploadImage.name} (
                                        {(uploadImage.size / 1024).toFixed(1)} KB)
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="dropzone-progress"
                                    style={{
                                      flex: 1,
                                      margin: '0 10px',
                                      alignSelf: 'center'
                                    }}
                                  >
                                    <div
                                      className="progress bg-gray-300"
                                      style={{ height: '6px' }}
                                    >
                                      <div
                                        className="progress-bar bg-primary"
                                        role="progressbar"
                                        style={{ width: `${uploadProgress}%` }}
                                        aria-valuenow={uploadProgress}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >

                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="dropzone-toolbar"
                                    style={{ alignSelf: 'center' }}
                                  >
                                    <span
                                      className="dropzone-start"
                                      style={{ cursor: 'pointer' }}
                                      onClick={e => handleSubmit1(e)}
                                    >
                                      <i className="ki-outline ki-to-right fs-1"></i>
                                    </span>
                                    <span
                                      className="dropzone-cancel"
                                      data-dz-remove=""
                                    >
                                      <i className="ki-outline ki-cross fs-2"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <span className="form-text fs-6 text-muted mt-5">
                            {t('Max file size is 1MB per file.')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <DialogFooter>
                      <DialogClose
                        className="btn btn-light"
                        onClick={() => {
                          setShowModal(false)
                          setUploadImage(null)
                          setUploadProgress(0)
                        }}
                      >
                        {t('Cancel')}
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>

              <div style={{ marginLeft: "5px", marginRight: "5px", borderBottom: "1px #dbdfe9", borderBottomStyle: "dotted" }} className="card-title">
                <div style={{ paddingTop: "5px", paddingBottom: "5px" }} className="d-flex align-items-center position-relative my-1">
                  <i className="ki-outline ki-magnifier fs-1 position-absolute ms-6"></i>
                  <input

                    type="text"
                    data-kt-filemanager-table-filter="search"
                    className="form-control form-control-solid ps-15"
                    placeholder={t('Search Files & Folders')}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div style={{ padding: "5px", marginLeft: "5px" }} className="badge badge-lg badge-light-primary mb-5">
                <div style={{ marginLeft: "5px" }} className="align-items-center inline-flex cursor-pointer flex-wrap">
                  <span
                    onClick={() => {
                      setFileList(undefined)
                      setHistory([])
                    }}
                  >
                    {t('Media Assets')}
                  </span>
                  <i style={{ fontSize: "20px" }} className="ki-outline ki-right  text-new-color mx-1"></i>

                  {history.map((item, index) => (
                    <span
                      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      key={index}
                      onClick={() => handleBreadcrumbClick(index)}
                    >
                      {item.name}

                      <i style={{ fontSize: "20px" }} className="ki-outline ki-right  mx-1 text-primary"></i>

                    </span>
                  ))}
                </div>
              </div>
              <div style={{ padding: "5px" }} id='create_folder' className="d-none d-flex align-items-center">
                <span id="kt_file_manager_folder_icon">
                  <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                </span>

                <input
                  type="text"
                  name="new_folder_name"
                  placeholder="Enter the folder name"
                  className="form-control mw-250px me-3"
                  value={newFolder}
                  onChange={e => setNewFolder(e.target.value)}
                />

                <button
                  onClick={() => createFolder()}
                  className="btn btn-icon btn-light-primary me-3"
                  id="kt_file_manager_add_folder"
                >
                  <span className="indicator-label">
                    <i className="ki-outline ki-check fs-1"></i>
                  </span>
                  <span className="indicator-progress">
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                </button>

                <button
                  onClick={() => {
                    const element = document.getElementById(
                      'create_folder'
                    )
                    element.classList.add('d-none')
                  }}
                  className="btn btn-icon btn-light-danger"
                  id="kt_file_manager_cancel_folder"
                >
                  <span className="indicator-label">
                    <i className="ki-outline ki-cross fs-1"></i>
                  </span>
                  <span className="indicator-progress">
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                </button>
              </div>
              {filteredFolders?.map((res, index) => (
                <div style={{ padding: "5px" }} key={index} className="d-flex justify-content-between align-items-center py-5">
                  <div
                    className="d-flex align-items-center cursor-pointer"
                    onClick={() => {
                      selectedFolder(res)
                    }}
                  >
                    <i
                      id={`folder-icon-${res._id}`}
                      className="ki-outline ki-folder fs-2x text-new-color1 me-4"
                    ></i>
                    <div className="d-flex flex-column">
                      <span
                        className="text-hover-primary text-media"
                        id={`folder-name-${res._id}`}
                      >
                        {res.name}
                      </span>
                      <span className=" text-media" id={`folder-date-${res._id}`}>
                        {formatDate(res.updatedAt, user?.language)}
                      </span>
                    </div>
                  </div>
                  <div style={{ width: "100%" }} id={`rename-folder-${res._id}`} className="d-none">
                    <div className="fv-row">
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          name="rename_folder_name"
                          placeholder="Enter the new folder name"
                          className="form-control mw-250px me-3"
                          defaultValue={res.name}
                          onChange={e => setNewFolder(e.target.value)}
                        />
                        <button
                          className="btn btn-icon btn-light-primary me-3"
                          onClick={() => {
                            renameFolder(res._id, newFolder)
                            document
                              .getElementById(`rename-folder-${res._id}`)
                              .classList.add('d-none')
                            document
                              .getElementById(`folder-name-${res._id}`)
                              .classList.remove('d-none')
                            document
                              .getElementById(`folder-icon-${res._id}`)
                              .classList.remove('d-none')
                            document
                              .getElementById(`folder-date-${res._id}`)
                              .classList.remove('d-none')
                          }}
                        >
                          <i className="ki-outline ki-check fs-1"></i>
                        </button>
                        <button
                          className="btn btn-icon btn-light-danger"
                          onClick={() => {
                            document
                              .getElementById(`rename-folder-${res._id}`)
                              .classList.add('d-none')
                            document
                              .getElementById(`folder-name-${res._id}`)
                              .classList.remove('d-none')
                            document
                              .getElementById(`folder-icon-${res._id}`)
                              .classList.remove('d-none')
                            document
                              .getElementById(`folder-date-${res._id}`)
                              .classList.remove('d-none')
                          }}
                        >
                          <i className="ki-outline ki-cross fs-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <Dropdown>
                    <DropdownTrigger
                      trigger="click"
                      placement="bottom-end"
                    >
                      <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
                        <i className="ki-solid ki-dots-horizontal fs-2x"></i>{' '}
                      </button>
                    </DropdownTrigger>
                    <DropdownContent className="w-200px fs-7">
                      <div className="menu-item px-3">
                        <a href="#" className="menu-link px-3">
                          {t('Download File')}
                        </a>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          data-kt-filemanager-table="rename"
                          onClick={() => {
                            document
                              .getElementById(`rename-folder-${res._id}`)
                              .classList.remove('d-none')
                            document
                              .getElementById(`folder-name-${res._id}`)
                              .classList.add('d-none')
                            document
                              .getElementById(`folder-icon-${res._id}`)
                              .classList.add('d-none')
                            document
                              .getElementById(`folder-date-${res._id}`)
                              .classList.add('d-none')
                          }}
                        >
                          {t('Rename')}
                        </a>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          data-kt-filemanager-table-filter="move_row"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_move_to_folder"
                        >
                          {t('Move to Folder')}
                        </a>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link text-danger px-3"
                          data-kt-filemanager-table-filter="delete_row"
                          onClick={() => deleteFolder(res._id)}
                        >
                          {t('Delete')}
                        </a>
                      </div>
                    </DropdownContent>
                  </Dropdown>
                </div>


              ))}
              {filteredFiles?.map((res, index) => (
                <div style={{ padding: "5px" }} key={index} className="d-flex justify-content-between align-items-center py-5">
                  <div className="d-flex align-items-start">

                    {res.type === "image" &&
                      <img style={{ width: "119px", cursor: "pointer" }}
                        className="app-blank aspect-video w-full rounded object-cover"
                        src={`https://cdn.maiasignage.com/${res.path}/${res.file}`}
                        alt=""
                        loading="lazy"
                        onClick={() => handleAddContent(res)}
                      />
                    }
                    {res.type === "video" && <img
                      style={{ width: "119px", cursor: "pointer" }}
                      className="app-blank aspect-video h-full w-full rounded object-cover"
                      loading="lazy"
                      src={ `${process.env.REACT_APP_BUNNY_PULL_ZONE_URL}/${res.meta.video_id}/thumbnail.jpg` }
                      alt=""
                      onClick={() => handleAddContent(res)}

                    />}

                    <div style={{ display: "flex", flexDirection: "column", alignSelf: "center", gap: "10px", marginLeft: "10px" }}>
                      <a
                        href="#"
                        className="text-hover-primary text-media d-block"
                        id={`folder-name-${res._id}`}
                      >
                        {res?.name}
                      </a>
                      <span
                        className="text-media fs-7"
                        id={`folder-date-${res._id}`}
                      >
                        {formatDate(res.updatedAt, user?.language)}
                      </span>
                      <div id={`rename-folder-${res._id}`} className="d-none mt-2">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            name="rename_folder_name"
                            placeholder="Enter the new name"
                            className="form-control mw-250px me-3"
                            defaultValue={res.name}
                            onChange={e => setNewFolder(e.target.value)}
                          />
                          <button
                            className="btn btn-icon btn-light-primary me-3"
                            onClick={() => {
                              renameFolder(res._id, newFolder)
                              document
                                .getElementById(`rename-folder-${res._id}`)
                                .classList.add('d-none')
                              document
                                .getElementById(`folder-name-${res._id}`)
                                .classList.remove('d-none')
                              /*   document
                                  .getElementById(`folder-icon-${res._id}`)
                                  .classList.remove('d-none') */
                              document
                                .getElementById(`folder-date-${res._id}`)
                                .classList.remove('d-none')
                            }}
                          >
                            <i className="ki-outline ki-check fs-1"></i>
                          </button>
                          <button
                            className="btn btn-icon btn-light-danger"
                            onClick={() => {
                              document
                                .getElementById(`rename-folder-${res._id}`)
                                .classList.add('d-none')
                              document
                                .getElementById(`folder-name-${res._id}`)
                                .classList.remove('d-none')
                              /*  document
                                 .getElementById(`folder-icon-${res._id}`)
                                 .classList.remove('d-none') */
                              document
                                .getElementById(`folder-date-${res._id}`)
                                .classList.remove('d-none')
                            }}
                          >
                            <i className="ki-outline ki-cross fs-1"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Dropdown>
                      <DropdownTrigger trigger="click" placement="bottom-end">
                        <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
                          <i className="ki-solid ki-dots-horizontal fs-2x"></i>
                        </button>
                      </DropdownTrigger>
                      <DropdownContent className="w-200px fs-7">
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            {t('Download File')}
                          </a>
                        </div>
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                            onClick={() => {
                              document
                                .getElementById(`rename-folder-${res._id}`)
                                .classList.remove('d-none')
                              document
                                .getElementById(`folder-name-${res._id}`)
                                .classList.add('d-none')
                              /*   document
                                  .getElementById(`folder-icon-${res._id}`)
                                  .classList.add('d-none') */
                              document
                                .getElementById(`folder-date-${res._id}`)
                                .classList.add('d-none')
                            }}
                          >
                            {t('Rename')}
                          </a>
                        </div>
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            {t('Move to Folder')}
                          </a>
                        </div>
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                            onClick={() => deleteFile(res)}
                          >
                            {t('Delete')}
                          </a>
                        </div>
                      </DropdownContent>
                    </Dropdown>
                  </div>
                </div>


              ))}
              <div
                className="d-flex justify-content-center align-items-center d-none"
                data-kt-filemanager-table-toolbar="selected"
              >
                <div className="fw-bold me-5">
                  <span
                    className="me-2"
                    data-kt-filemanager-table-select="selected_count"
                  ></span>
                  Selected
                </div>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-kt-filemanager-table-select="delete_selected"
                >
                  Delete Selected
                </button>
              </div>
            </div>
          </TabsContent>
        </div>
      </Tabs>
    </div >
  )
}

export default PlaylistEdit
