import React, { useState, useEffect } from 'react';
import axios from 'axios';


const UpgradePlanModal = ({ isOpen, onClose }) => {
  const [activePlan, setActivePlan] = useState('month');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPackage();
  }, []);

  async function getPackage() {
    try {
      const response = await axios.get(process.env.REACT_APP_API_BASE + "/v1/package");
      setPackages(response.data.rows);
      setSelectedPlan(response.data.rows[0]._id);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching packages:", error);
      setLoading(false);
    }
  }

  if (!isOpen || loading) return null;

  const handlePlanChange = (plan) => {
    setActivePlan(plan);
  };

  const handleRadioChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const getAnnualPrice = (price, discount) => {
    return price * 12 * (1 - discount / 100);
  };

  return (
    <div className='modal testmodal fade show' style={{ display: 'block' }}>
      <div className='modal-dialog modal-xl'>
        <div className="modal-content rounded">
          <div className="modal-body pt-0 pb-15 px-5">
            <div style={{marginTop:"57px"}} className="mb-13 text-center">
              <h1 style={{fontSize:"calc(1.3rem + 0.6vw)", fontWeight:"600"}} className="mb-3">Upgrade a Plan</h1>
              <div className="text-muted fw-semibold fs-5">
                If you need more info, please check <a href="#" className="link-primary fw-bold">Pricing Guidelines</a>.
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="nav-group nav-group-outline mx-auto" data-kt-buttons="true">
                <button 
                  className={`btn btn-color-gray-500 btn-active btn-active-secondary px-6 py-3 me-2 ${activePlan === 'month' ? 'active' : ''}`}
                  onClick={() => handlePlanChange('month')}
                >
                  Monthly
                </button>
                <button 
                  className={`btn btn-color-gray-500 btn-active btn-active-secondary px-6 py-3 ${activePlan === 'annual' ? 'active' : ''}`}
                  onClick={() => handlePlanChange('annual')}
                >
                  Annual
                </button>
              </div>

              <div className="row mt-10">
                <div className="col-lg-6 mb-10 mb-lg-0">
                  <div className="nav flex-column">
                    {packages.map((pkg) => (
                      <label key={pkg._id} className={`nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mb-6 ${selectedPlan === pkg._id ? 'active' : ''}`}>
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                            <input 
                              className="form-check-input" 
                              type="radio" 
                              name="plan" 
                              value={pkg._id}
                              checked={selectedPlan === pkg._id}
                              onChange={handleRadioChange}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                              {pkg.name}
                              {pkg.is_trial && <span className="badge badge-light-success ms-2 py-2 px-3 fs-7">Trial</span>}
                            </div>
                            <div className="fw-semibold opacity-75">{pkg.description}</div>
                          </div>
                        </div>
                        {console.log(pkg.is_trial)}
                        {pkg.price !== 0 ?(
                          <div className="ms-5">
                            <span className="mb-2">$</span>
                            <span className="fs-3x fw-bold">
                              {activePlan === 'month' ? pkg.price : getAnnualPrice(pkg.price, pkg.annual_discount)}
                            </span>
                            <span className="fs-7 opacity-50">/{activePlan === 'month' ? 'Mon' : 'Year'}</span>
                          </div>
                        ) :!pkg.is_trial? (
                          <div className="ms-5">
                            <a href="#" className="btn btn-sm btn-success">Contact Us</a>
                          </div>
                        ):null}
                      </label>
                    ))}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="tab-content rounded h-100 bg-light p-10">
                    <div className="tab-pane fade show active">
                      <div className="pb-5">
                        <h2 className="fw-bold text-gray-900">What's in {packages.find(pkg => pkg._id === selectedPlan)?.name} Plan?</h2>
                        <div className="text-muted fw-semibold">
                          {packages.find(pkg => pkg._id === selectedPlan)?.description}
                        </div>
                      </div>
                      <div className="pt-1">
                        {packages.find(pkg => pkg._id === selectedPlan)?.package_properties.map((feature, index) => (
                          <div key={index} className="feature-item d-flex align-items-center mb-7">
                            <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                              {feature.name}
                            </span>
                            <i className="ki-outline ki-check-circle text-success fs-1"></i>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button type="reset" className="btn btn-light me-3" onClick={onClose}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary" id="kt_modal_upgrade_plan_btn">
                  <span className="indicator-label">Upgrade Plan</span>
                  <span className="indicator-progress">
                    Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanModal;