import { useTranslation } from 'react-i18next';
import {
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { useToast } from '@/components/ui/use-toast';
import { formDataToJSON } from '@/utils/formDataToJSON';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { produce } from 'immer';

const MediaSettings = ({ playlist, setTimeline, timeline, setPreview }) => {
  const { t } = useTranslation();
  const videoRef = useRef();
  const { toast } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [newName, setNewName] = useState(playlist.name);
  const user = useSelector((state) => state.user);
  let tags = [];

  function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    const jsonData = formDataToJSON(formData);

    setTimeline(
      produce(timeline, (draft) => {
        const item = draft.find((i) => i._id === playlist._id);
        if (item) {
          item.meta.objectFit = jsonData.objectFit === 'on' ? 'cover' : 'contain';
          item.meta.animation = jsonData.animation;
          item.meta.duration = Number(jsonData.time.split(':')[2]);
          item.meta.url = jsonData.url;
          item.meta.tagsPlaybackRestriction = jsonData.tagsPlaybackRestriction;
          item.meta.enablePlaybackReport = jsonData.enablePlaybackReport;
          item.meta.tags = jsonData.tags?.split(',');
        }
      })
    );

    setPreview(
      produce(draft => {
        draft.meta.objectFit = jsonData.objectFit == 'on' ? 'cover' : 'contain';
      })
    );
  }

  function secondsToHMS(duration) {
    let hours = Math.floor(parseInt(duration) / 3600);
    let minutes = Math.floor((parseInt(duration) % 3600) / 60);
    let seconds = parseInt(duration) % 60;

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds;
  }

  useEffect(() => {
    var input1 = document.querySelector('#kt_tagify_tags');

    new Tagify(input1, {
      whitelist: ['Important', 'Urgent', 'Room'],
      dropdown: {
        maxItems: 30,
        classname: '',
        enabled: 0,
        closeOnSelect: false
      }
    });
  }, []);

  return (
    <>
      <DialogHeader>
        <DialogTitle className="flex items-center justify-center text-xl">
          <form
            onSubmit={e => {
              e.preventDefault();
              setIsEditing(false);

              setNewName(e.target.title.value);

              setTimeline(
                produce(timeline, draft => {
                  const item = draft.find(i => i._id === playlist._id);
                  if (item) {
                    item.name = e.target.title.value;
                  }
                })
              );
            }}
          >
            {isEditing ? (
              <div className="d-flex align-items-center">
                <input
                  className="form-control mw-250px me-3"
                  defaultValue={newName}
                  name="title"
                />
                <button
                  onClick={() => setNewName(playlist.name)}
                  className="btn btn-icon btn-light-primary me-3 flex-shrink-0"
                  id="kt_file_manager_add_folder"
                >
                  <span className="indicator-label">
                    <i className="ki-outline ki-check fs-1"></i>
                  </span>
                  <span className="indicator-progress">
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                </button>
                <button
                  className="btn btn-icon btn-light-danger flex-shrink-0"
                  id="kt_file_manager_cancel_folder"
                >
                  <span
                    className="indicator-label"
                    onClick={() => setIsEditing(false)}
                  >
                    <i className="ki-outline ki-cross fs-1"></i>
                  </span>
                  <span className="indicator-progress">
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                </button>
              </div>
            ) : (
              <div className="flex items-center">
                <span>{newName}</span>
                <i
                  className="ki-outline ki-pencil fs-2 me-0 ml-2"
                  onClick={() => setIsEditing(true)}
                ></i>
              </div>
            )}
          </form>
        </DialogTitle>
      </DialogHeader>
      <form
        id="kt_modal_new_target_form"
        className="form fv-plugins-bootstrap5 fv-plugins-framework"
        action="#"
        data-select2-id="select2-data-kt_modal_new_target_form"
        onSubmit={handleSubmit}
      >
        <div className="p-12">
          <div className="d-flex şte flex-column fv-row fv-plugins-icon-container">
            <div className="flex items-center justify-center">
              <div className="group relative flex overflow-hidden rounded">
                {playlist.type === 'image' && (
                  <img
                    className={`mb-8 aspect-video max-w-sm rounded-lg object-cover ${
                      playlist.meta?.objectFit === 'cover'
                        ? 'object-cover'
                        : 'object-contain'
                    }`}
                    src={`https://cdn.maiasignage.com/${playlist.path}/${playlist.file}`}
                    alt=""
                  />
                )}
                {playlist.type === 'video' && (
                  <video
                    className={`mb-8 aspect-video max-w-sm rounded-lg object-cover`}
                    src={`${process.env.REACT_APP_BUNNY_PULL_ZONE_URL}/${playlist?.meta.video_id}/original`}
                    ref={videoRef}
                    loop
                    muted
                  />
                )}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column mb-8">
          {/*   <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
              <span>{t('URL')}</span>
            </label> */}

          {/*   <input
              type="text"
              className="form-control form-control-solid"
              placeholder={t('Enter Target URL')}
              name="url"
              defaultValue={playlist.meta.url}
            /> */}
            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
          </div>

          <div className="row row-cols-lg-2 g-10">
            <div className="d-flex flex-column mb-8">
              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                <span>{t('Time')}</span>
              </label>

              <input
                type="text"
                className="form-control form-control-solid"
                placeholder={t('Enter Time')}
                name="time"
                defaultValue={secondsToHMS(playlist.meta?.duration)}
              />
              <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
            </div>

          {/*   <div className="d-flex flex-column fv-row mb-8">
              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                <span>{t('Tags')}</span>
              </label>

              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                id="kt_tagify_tags"
                name="tags"
                defaultValue={playlist.meta.tags?.join(',')}
                placeholder={t('Select Tags')}
              />
            </div> */}
          </div>

          <div className="row row-cols-lg-2">
            <div className="d-flex flex-stack mb-8">
              <div className="me-5">
                <label className="fs-6 fw-semibold">{t('Fit to Screen')}</label>

                <div className="fs-7 fw-semibold text-muted">
                  {t('Fit to screen will make the media fit to the screen.')}
                </div>
              </div>

              <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="objectFit"
                  defaultChecked={playlist.meta?.objectFit === 'cover'}
                />
              </label>
            </div>

            <div className="d-flex flex-stack mb-8">
              <div className="me-5">
                <label className="fs-6 fw-semibold">{t('Animation')}</label>

                <div className="fs-7 fw-semibold text-muted">
                  {t('Enable animation for this media')}
                </div>
              </div>

              <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                <select
                  name="animation"
                  className="form-select form-select-solid fw-bold"
                >
                  <option value="slide">{t('Slide')}</option>
                </select>
              </label>
            </div>

            <div className="d-flex flex-stack">
              <div className="me-5">
                <label className="fs-6 fw-semibold">
                  {t('Tags playback restriction')}
                </label>

                <div className="fs-7 fw-semibold text-muted">
                  {t('Show this media only on screens with matching tags')}
                </div>
              </div>

              <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="tagsPlaybackRestriction"
                  defaultChecked={playlist.meta?.tagsPlaybackRestriction}
                />
              </label>
            </div>
            <div className="d-flex flex-stack">
              <div className="me-5">
                <label className="fs-6 fw-semibold">
                  {t('Enable playback reporting for this media')}
                </label>

                <div className="fs-7 fw-semibold text-muted">
                  {t('Enable Playback Report')}
                </div>
              </div>

              <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="enablePlaybackReport"
                  defaultChecked={playlist.meta?.enablePlaybackReport}
                />
              </label>
            </div>
          </div>
        </div>

        <DialogFooter>
          <DialogClose className="btn btn-light">{t('Cancel')}</DialogClose>
          <DialogClose className="btn btn-primary" type="submit">
            {t('Save')}
          </DialogClose>
        </DialogFooter>
      </form>
    </>
  );
};

export default MediaSettings;
