import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  addScreen,
  changeRegisterStatus,
  setUserInfo
} from '@/redux/user-slice'

import logosAppleDark from '@/assets/img/media/svg/brand-logos/apple-black-dark.svg'
import logosApple from '@/assets/img/media/svg/brand-logos/apple-black.svg'
import logosGoogle from '@/assets/img/media/svg/brand-logos/google-icon.svg'

export const Login = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  function getScreenList(user) {
   
    axios
      .get(`${process.env.REACT_APP_API_BASE}/v1/screen`, {
        headers: { Authorization: `Bearer ${user?.user?.token}` }
      })
      .then(res => {
      console.log(res)
        dispatch(addScreen(res.data))
      })
  }

  function handleSubmit(e) {
    e.preventDefault()

    axios
      .post(`${process.env.REACT_APP_API_BASE}/users/login`, {
        user: { email: e.target.email.value, password: e.target.password.value }
      })
      .then(res => {
        console.log("login",res.data.user.token)
        if (res.status === 200) {
        dispatch(setUserInfo(res.data))
          dispatch(changeRegisterStatus(true))

          getScreenList(res.data)

        navigate('/') 
        } 
      })
      .catch(err => {
        console.log('hello')
        toast.error(t('Wrong email or password'))
      })
  }

  return (
    <form className="form w-100" onSubmit={e => handleSubmit(e)}>
      <div className="mb-11 text-center">
        <h1 className="fw-bolder text-gray-900 mb-3 text-3xl">
          {t('Sign In')}
        </h1>
        <div className="fw-semibold fs-6 text-gray-500">
          {t('Your Social Campaigns')}
        </div>
      </div>
      <div className="row g-3 mb-9">
        <div className="col-md-6">
          <a
            href="#"
            className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center w-100 text-nowrap"
          >
            <img alt="Logo" src={logosGoogle} className="h-15px me-3" />
            {t('Sign in with Google')}
          </a>
        </div>
        <div className="col-md-6">
          <a
            href="#"
            className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center w-100 text-nowrap"
          >
            <img
              alt="Logo"
              src={logosApple}
              className="theme-light-show h-15px me-3"
            />
            <img
              alt="Logo"
              src={logosAppleDark}
              className="theme-dark-show h-15px me-3"
            />
            {t('Sign in with Apple')}
          </a>
        </div>
      </div>
      <div className="separator separator-content my-14">
        <span className="w-125px fw-semibold fs-7 text-gray-500">
          {t('Or with email')}
        </span>
      </div>
      <div className="fv-row mb-8">
        <input
          className="form-control bg-[#e8f0fe] focus:bg-[#e8f0fe]"
          type="email"
          placeholder={t('Email')}
          name="email"
          autoComplete="off"
          required
        />
      </div>
      <div className="fv-row mb-3">
        <input
          className="form-control bg-[#e8f0fe] focus:bg-[#e8f0fe]"
          type="password"
          placeholder={t('Password')}
          name="password"
          autoComplete="off"
          required
        />
      </div>
      <div className="d-flex flex-stack fs-base fw-semibold mb-8 flex-wrap gap-3">
        <div></div>
        <a
          href="authentication/layouts/creative/reset-password.html"
          className="link-primary"
        >
          {t('Forget Password?')}
        </a>
      </div>
      <div className="d-grid mb-10">
        <button type="submit" className="btn btn-primary">
          <span className="indicator-label">{t('Sign In')}</span>
          <span className="indicator-progress">
            {t('Please wait...')}
            <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
          </span>
        </button>
      </div>
     {/*  <div className="fw-semibold fs-6 text-gray-500 text-center">
        {t('Not a Member yet?')}
        <Link className="ms-1 text-primary" to="/register">
          {t('Sign Up')}
        </Link>
      </div> */}
    </form>
  )
}

export default Login
