import React, { useEffect, useState } from "react"
import UpgradePlanModal from "./plan-modal"
import NewCardModal from "./add-card"
import axios from "axios";
function Billing(props) {
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showNewCardModal, setShowNewCardModal] = useState(false);
  const handleAddCardClick = () => {
    setShowNewCardModal(true);
  };
/*   console.log(props)

  async function getPackage() {

    await axios.get(process.env.REACT_APP_API_BASE + "/v1/package").then(res => {
      console.log(res)
    })

  }


  useEffect(() => {
    getPackage()
  }) */
  return (
    <>
      <div>
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
          <div className="card mb-5 mb-xl-10">

            <div className="card-body">

              <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-12 p-6">

                <i className="ki-outline ki-information fs-2tx text-warning me-4" />

                <div className="d-flex flex-stack flex-grow-1">

                  <div className="fw-semibold">
                    <h4 className="text-gray-900 fw-bold">
                      We need your attention!
                    </h4>
                    <div className="fs-6 text-gray-700">
                      Your payment was declined. To start using tools, please
                      <a
                        href="#"
                        className="fw-bold"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_new_card"
                      >
                        Add Payment Method
                      </a>
                      .
                    </div>
                  </div>

                </div>

              </div>

              <div className="row">

                <div className="col-lg-7">

                  <h3 className="mb-2">Active until Dec 09, 2024</h3>
                  <p className="fs-6 text-gray-600 fw-semibold mb-6 mb-lg-15">
                    We will send you a notification upon Subscription expiration
                  </p>

                  <div className="fs-5 mb-2">
                    <span className="text-gray-800 fw-bold me-1">$24.99</span>
                    <span className="text-gray-600 fw-semibold">Per Month</span>
                  </div>

                  <div className="fs-6 text-gray-600 fw-semibold">
                    Extended Pro Package. Up to 100 Agents &amp; 25 Projects
                  </div>

                </div>

                <div className="col-lg-5">

                  <div className="d-flex text-muted fw-bold fs-5 mb-3">
                    <span className="flex-grow-1 text-gray-800">Users</span>
                    <span className="text-gray-800">86 of 100 Used</span>
                  </div>

                  <div className="progress h-8px bg-light-primary mb-2">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      style={{ width: "86%" }}
                      aria-valuenow={86}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>

                  <div className="fs-6 text-gray-600 fw-semibold mb-10">
                    14 Users remaining until your plan requires update
                  </div>

                  <div className="d-flex justify-content-end pb-0 px-0">
                    <a
                      href="#"
                      className="btn btn-light btn-active-light-primary me-2"
                      id="kt_account_billing_cancel_subscription_btn"
                    >
                      Cancel Subscription
                    </a>
                    <button
                      className="btn btn-primary"
                      onClick={() => setShowPlanModal(true)}
                    >
                      Upgrade Plan
                    </button>
                  </div>

                </div>

              </div>

            </div>

          </div>

          <div className="card mb-5 mb-xl-10">

            <div className="card-header card-header-stretch pb-0">

              <div className="card-title">
                <h3 className="m-0">Payment Methods</h3>
              </div>

              <div className="card-toolbar m-0">

                <ul
                  className="nav nav-stretch nav-line-tabs border-transparent"
                  role="tablist"
                >

                  <li className="nav-item" role="presentation">
                    <a
                      id="kt_billing_creditcard_tab"
                      className="nav-link fs-5 fw-bold me-5 active"
                      data-bs-toggle="tab"
                      role="tab"
                      href="#kt_billing_creditcard"
                    >
                      Credit / Debit Card
                    </a>
                  </li>

                  <li className="nav-item" role="presentation">
                    <a
                      id="kt_billing_paypal_tab"
                      className="nav-link fs-5 fw-bold"
                      data-bs-toggle="tab"
                      role="tab"
                      href="#kt_billing_paypal"
                    >
                      Paypal
                    </a>
                  </li>

                </ul>

              </div>

            </div>

            <div
              id="kt_billing_payment_tab_content"
              className="card-body tab-content"
            >

              <div
                id="kt_billing_creditcard"
                className="tab-pane fade show active"
                role="tabpanel"
              >

                <h3 className="mb-5">My Cards</h3>

                <div className="row gx-9 gy-6">

                  <div className="col-xl-6" data-kt-billing-element="card">

                    <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">

                      <div className="d-flex flex-column py-2">

                        <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                          Marcus Morris
                          <span className="badge badge-light-success fs-7 ms-2">
                            Primary
                          </span>
                        </div>

                        <div className="d-flex align-items-center">

                          <img
                            src="assets/media/svg/card-logos/visa.svg"
                            alt=""
                            className="me-4"
                          />

                          <div>
                            <div className="fs-4 fw-bold">Visa **** 1679</div>
                            <div className="fs-6 fw-semibold text-gray-500">
                              Card expires at 09/24
                            </div>
                          </div>

                        </div>

                      </div>

                      <div className="d-flex align-items-center py-2">
                        <button
                          className="btn btn-sm btn-light btn-active-light-primary me-3"
                          data-kt-billing-action="card-delete"
                        >

                          <span className="indicator-label">Delete</span>

                          <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>

                        </button>
                        <button
                          className="btn btn-sm btn-light btn-active-light-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_new_card"
                        >
                          Edit
                        </button>
                      </div>

                    </div>

                  </div>

                  <div className="col-xl-6" data-kt-billing-element="card">

                    <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">

                      <div className="d-flex flex-column py-2">

                        <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                          Jacob Holder
                        </div>

                        <div className="d-flex align-items-center">

                          <img
                            src="assets/media/svg/card-logos/american-express.svg"
                            alt=""
                            className="me-4"
                          />

                          <div>
                            <div className="fs-4 fw-bold">
                              Mastercard **** 2040
                            </div>
                            <div className="fs-6 fw-semibold text-gray-500">
                              Card expires at 10/22
                            </div>
                          </div>

                        </div>

                      </div>

                      <div className="d-flex align-items-center py-2">
                        <button
                          className="btn btn-sm btn-light btn-active-light-primary me-3"
                          data-kt-billing-action="card-delete"
                        >

                          <span className="indicator-label">Delete</span>

                          <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>

                        </button>
                        <button
                          className="btn btn-sm btn-light btn-active-light-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_new_card"
                        >
                          Edit
                        </button>
                      </div>

                    </div>

                  </div>

                  <div className="col-xl-6" data-kt-billing-element="card">

                    <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">

                      <div className="d-flex flex-column py-2">

                        <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                          Jhon Larson
                        </div>

                        <div className="d-flex align-items-center">

                          <img
                            src="assets/media/svg/card-logos/mastercard.svg"
                            alt=""
                            className="me-4"
                          />

                          <div>
                            <div className="fs-4 fw-bold">
                              Mastercard **** 1290
                            </div>
                            <div className="fs-6 fw-semibold text-gray-500">
                              Card expires at 03/23
                            </div>
                          </div>

                        </div>

                      </div>

                      <div className="d-flex align-items-center py-2">
                        <button
                          className="btn btn-sm btn-light btn-active-light-primary me-3"
                          data-kt-billing-action="card-delete"
                        >

                          <span className="indicator-label">Delete</span>

                          <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>

                        </button>
                        <button
                          className="btn btn-sm btn-light btn-active-light-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_new_card"
                        >
                          Edit
                        </button>
                      </div>

                    </div>

                  </div>

                  <div className="col-xl-6">

                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">

                      <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">

                        <div className="mb-3 mb-md-0 fw-semibold">
                          <h4 className="text-gray-900 fw-bold">
                            Important Note!
                          </h4>
                          <div className="fs-6 text-gray-700 pe-7">
                            Please carefully read
                            <a href="#" className="fw-bold me-1">
                              Product Terms
                            </a>
                            adding <br />
                            your new payment card
                          </div>
                        </div>

                        <a

                          className="btn btn-primary px-6 align-self-center text-nowrap"
                          onClick={handleAddCardClick}
                        >
                          Add Card
                        </a>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

              <div
                id="kt_billing_paypal"
                className="tab-pane fade"
                role="tabpanel"
                aria-labelledby="kt_billing_paypal_tab"
              >

                <h3 className="mb-5">My Paypal</h3>

                <div className="text-gray-600 fs-6 fw-semibold mb-5">
                  To use PayPal as your payment method, you will need to make
                  pre-payments each month before your bill is due.
                </div>

                <form className="form">

                  <div className="mb-7 mw-350px">
                    <select
                      name="timezone"
                      data-control="select2"
                      data-placeholder="Select an option"
                      data-hide-search="true"
                      className="form-select form-select-solid form-select-lg fw-semibold fs-6 text-gray-700"
                    >
                      <option>Select an option</option>
                      <option value={25}>US $25.00</option>
                      <option value={50}>US $50.00</option>
                      <option value={100}>US $100.00</option>
                      <option value={125}>US $125.00</option>
                      <option value={150}>US $150.00</option>
                    </select>
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Pay with Paypal
                  </button>
                </form>

              </div>

            </div>

          </div>


        </div>


        <UpgradePlanModal
          isOpen={showPlanModal}
          onClose={() => setShowPlanModal(false)}
        />

        <NewCardModal
          isOpen={showNewCardModal}
          onClose={() => setShowNewCardModal(false)}
          props={props.data}
        />

      </div>

    </>
  )
}

export default Billing