import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

import userSlice from './user-slice'

const persistConfig = {
  key: 'root',
  storage: storageSession
}

const rootReducer = combineReducers({
  user: userSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer
