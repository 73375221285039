import React from 'react'

const FrillEmbeddedWidget = () => {
  const widgetRef = React.useRef(null)

  React.useEffect(() => {
    let widget

    const config = {
      key: '9b4db2b7-4b3b-4e0c-a9dd-e4c42743d7a4',
      container: widgetRef.current,
      callbacks: {
        onReady: frillWidget => {
          widget = frillWidget
        }
      }
    }

    window.Frill_Config = window.Frill_Config || []
    window.Frill_Config.push(config)

    if ('Frill' in window) {
      widget = window.Frill.widget(config)
    }

    return () => {
      widget?.destroy()
      if (window.Frill_Config) {
        window.Frill_Config = window.Frill_Config.filter(c => c !== config)
      }
    }
  }, [widgetRef])

  return <div ref={widgetRef} className="frill-embedded" />
}

export default FrillEmbeddedWidget
