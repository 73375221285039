import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

function VerifyMail() {
  const [verificationCode, setVerificationCode] = useState('')
  const [inputFields, setInputFields] = useState([])

  const user = useSelector(res => res.user.user)
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    if (user && user?.user?.email_verify_code) {
      setInputFields(user?.user?.email_verify_code.split(''))
    }
  }, [user])

  useEffect(() => {
    const combinedCode = inputFields.join('')
    setVerificationCode(combinedCode)
  }, [inputFields])

  const handleChange = (index, e) => {
    const value = e.target.value
    const maxLength = e.target.maxLength
    const updatedFields = [...inputFields]

    if (value && value.length <= maxLength) {
      updatedFields[index] = value
      setInputFields(updatedFields)

      if (value.length === maxLength && index < inputFields.length - 1) {
        document.getElementsByName(`code_${index + 2}`)[0].focus() // Focus on the next input
      }
    } else if (value === '') {
      // If the user deletes a character
      updatedFields[index] = ''
      setInputFields(updatedFields)
      if (index > 0) {
        document.getElementsByName(`code_${index}`)[0].focus() // Focus on the previous input
      }
    }
  }

  function confirmEmail(e) {
    e.preventDefault()
    axios
      .post(process.env.REACT_APP_API_BASE + '/users/email_verify', {
        code: verificationCode
      })
      .then(response => {
        if (response.status === 200) {
          toast.success(t('Email verified successfully.'))

          navigate('/')
        }
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }

  return (
    <>
      <div className="d-flex flex-column flex-root" id="kt_app_root">
        <div className="d-flex flex-column flex-center flex-column-fluid">
          <div className="d-flex flex-column flex-center p-10 text-center">
            <div className="card card-flush w-lg-600px py-5">
              <div className="card-body py-15 py-lg-20">
                <div className="mb-14 flex justify-center">
                  <a href="#" className="">
                    <img alt="Logo" src="/icons/icon.svg" className="h-100px" />
                  </a>
                </div>

                <h1 className="fw-bolder text-gray-900 mb-5 text-3xl">
                  {t('Verify Email')}
                </h1>
                <div className="d-flex flex-stack flex-wrap">
                  {inputFields.map((value, index) => (
                    <input
                      key={index}
                      type="text"
                      name={`code_${index + 1}`}
                      maxLength={1}
                      className="form-control h-60px w-60px fs-2qx bg-transparent my-2 text-center"
                      value={value}
                      onChange={e => handleChange(index, e)}
                    />
                  ))}
                </div>

                <div className="mb-11">
                  <button
                    className="btn btn-primary mt-2"
                    onClick={e => {
                      confirmEmail(e)
                    }}
                  >
                    {t('Confirm')}
                  </button>
                </div>

                <div className="mb-0">
                  <img
                    src="/assets/media/auth/please-verify-your-email.png"
                    className="mw-100 mh-300px theme-light-show"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyMail
