import { Toaster } from '@/components/ui/toaster';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FrillEmbeddedWidget from './components/frill-embedded-widget';
import { Routes } from './routes';
import { useEffect } from 'react';
import './styles/globals.css';
import axios from 'axios';
import { addScreen } from './redux/user-slice';
import SocketConnection from './socket-connection';

export default function App() {
    const dispatch = useDispatch();
    const { registered, user: { user } } = useSelector((state) => state.user);

    if (user) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    }

    useEffect(() => {
        if (!user) {
            return;
        }

        axios.get(`${process.env.REACT_APP_API_BASE}/v1/screen`).then((res) => {
            dispatch(addScreen(res.data));
        });
    }, [user, dispatch]);

    return (
        <>
            <Toaster />
            <SocketConnection user={user} registered={registered}>
                <Routes isAuthorized={registered} />
                <ToastContainer hideProgressBar={true} />
                <FrillEmbeddedWidget />
            </SocketConnection>
        </>
    );
}
