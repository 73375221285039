import { useEffect, useState } from 'react'

export default function Image({ className, src, alt, ...props }) {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const img = new window.Image()
    img.src = src

    const handleLoad = () => setIsLoaded(true)
    img.onload = handleLoad

    // Cleanup function to avoid memory leaks
    return () => {
      img.onload = null
    }
  }, [src])

  return (
    <div
      className={`${className} flex items-center justify-center overflow-hidden`}
    >
      {!isLoaded ? (
        <svg
          className="h-6 w-6 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        <img
          className="h-full w-full object-cover"
          src={src}
          alt={alt}
          onLoad={() => setIsLoaded(true)}
          style={{ display: isLoaded ? 'block' : 'none' }}
          {...props}
        />
      )}
    </div>
  )
}
