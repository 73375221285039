import { useTranslation } from 'react-i18next'

import DatePicker from '@/components/ui/date-picker'
import { DialogClose, DialogFooter } from '@/components/ui/dialog'
import TimePicker from '@/components/ui/time-picker'
import { changePlaylistEditStatus } from '@/redux/user-slice'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const MediaLifetimeDialog = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  let screen = []
  let playlist = []
  const originalUrl = 'https://webplayer.maiasignage.com/'
  const urlWithParams = `${originalUrl}?token=${user.user.user.token}&selected_playlist_id=${user.selected_playlist}`
  const [playlistId, setPlylistId] = useState(user?.selected_playlist)

  useEffect(() => {
    if (!location.pathname.includes('playlist')) {
      dispatch(changePlaylistEditStatus(false))
    }
  }, [location, dispatch])

  return (
    <>
      <form className="form" action="#" id="kt_modal_add_event_form">
        <div className="modal-body px-17 py-10">
          <div className="row row-cols-lg-2 g-10">
            <div className="col">
              <div className="fv-row mb-9">
                <label className="fs-6 fw-semibold mb-2">
                  {t('Event Start Date')}
                </label>
                <div class="d-flex align-items-center">
                  <i class="ki-outline ki-calendar-8 fs-2 position-absolute mx-4"></i>

                  <DatePicker placeholder={t('Pick a start date')} />
                </div>
              </div>
            </div>
            <div className="col" data-kt-calendar="datepicker">
              <div className="fv-row mb-9">
                <label className="fs-6 fw-semibold mb-2">
                  {t('Event Start Time')}
                </label>

                <div class="position-relative d-flex align-items-center">
                  <i class="ki-outline ki-calendar-8 fs-2 position-absolute mx-4"></i>

                  <TimePicker placeholder={t('Pick a start time')} />
                </div>
              </div>
            </div>
          </div>

          <div className="row row-cols-lg-2 g-10">
            <div className="col">
              <div className="fv-row mb-9">
                <label className="fs-6 fw-semibold mb-2">
                  {t('Event End Date')}
                </label>

                <div class="position-relative d-flex align-items-center">
                  <i class="ki-outline ki-calendar-8 fs-2 position-absolute mx-4"></i>

                  <DatePicker placeholder={t('Pick a end date')} />
                </div>
              </div>
            </div>
            <div className="col" data-kt-calendar="datepicker">
              <div className="fv-row mb-9">
                <label className="fs-6 fw-semibold mb-2">
                  {t('Event End Time')}
                </label>

                <div class="position-relative d-flex align-items-center">
                  <i class="ki-outline ki-calendar-8 fs-2 position-absolute mx-4"></i>

                  <TimePicker placeholder={t('Pick a end time')} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <DialogFooter>
          <DialogClose className="btn btn-light">{t('Cancel')}</DialogClose>
          <button className="btn btn-primary" type="submit">
            {t('Save')}
          </button>
        </DialogFooter>
      </form>
    </>
  )
}

export default MediaLifetimeDialog
