import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next, useTranslation } from 'react-i18next'

import frenchFlag from '@/assets/img/media/flag/france.svg'
import germanFlag from '@/assets/img/media/flag/germany.svg'
import palestineFlag from '@/assets/img/media/flag/palestine.svg'
import spanishFlag from '@/assets/img/media/flag/spain.svg'
import turkishFlag from '@/assets/img/media/flag/turkey.svg'
import englishFlag from '@/assets/img/media/flag/united-states.svg'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({ fallbackLng: 'en' })

const languages = [
  { name: 'English', flag: englishFlag, ISO: 'en' },
  { name: 'French', flag: frenchFlag, ISO: 'fr' },
  { name: 'German', flag: germanFlag, ISO: 'de' },
  { name: 'Spanish', flag: spanishFlag, ISO: 'es' },
  { name: 'Turkish', flag: turkishFlag, ISO: 'tr' },
  { name: 'Arabic', flag: palestineFlag, ISO: 'ar' }
]

function getLanguageByISO(ISO) {
  return languages.find(item => item.ISO.toLowerCase() === ISO.toLowerCase())
}

export { languages, getLanguageByISO, i18n, useTranslation }
