import { Outlet } from 'react-router-dom'

import Header from '@/components/layout/header'
import Sidebar from '@/components/layout/sidebar'

const MainLayout = () => {
  return (
    <div className="flex h-full">
      <Header />
      <Sidebar />
      <div className="mt-24 flex-1 lg:ml-24">
        <div className="mx-auto flex h-full flex-1 flex-col">
          <div className="flex-1 p-6 md:p-12">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainLayout
