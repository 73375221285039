import * as React from 'react'

const Table = React.forwardRef(({ className, ...props }, ref) => (
  <table
    ref={ref}
    className={`table-row-dashed fs-6 gy-5 dataTable table align-middle ${className}`}
    {...props}
  />
))
Table.displayName = 'Table'

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
  <thead ref={ref} className={` ${className}`} {...props} />
))
TableHeader.displayName = 'TableHeader'

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={`fw-semibold text-gray-600 ${className}`}
    {...props}
  />
))
TableBody.displayName = 'TableBody'

const TableFooter = React.forwardRef(({ className, ...props }, ref) => (
  <tfoot ref={ref} className={`${className}`} {...props} />
))
TableFooter.displayName = 'TableFooter'

const TableRow = React.forwardRef(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={`fw-bold gs-0 text-muted text-start ${className}`}
    {...props}
  />
))
TableRow.displayName = 'TableRow'

const TableHead = React.forwardRef(({ children, className, ...props }, ref) => (
  <th
    ref={ref}
    className={`fs-7 dt-orderable-asc dt-orderable-desc w-96 ${className}`}
    {...props}
  >
    <span className="dt-column-title">{children}</span>
  </th>
))
TableHead.displayName = 'TableHead'

const TableCell = React.forwardRef(({ className, ...props }, ref) => (
  <td ref={ref} className={`text-gray-600 py-3 ${className}`} {...props} />
))
TableCell.displayName = 'TableCell'

const TableCaption = React.forwardRef(({ className, ...props }, ref) => (
  <caption ref={ref} className={`${className}`} {...props} />
))
TableCaption.displayName = 'TableCaption'

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption
}
