import flatpickr from 'flatpickr'
import { useEffect, useRef } from 'react'

const TimePicker = ({ children, className, ...props }) => {
  const ref = useRef(null)

  useEffect(() => {
    flatpickr(ref.current, {
      enableTime: true,
      noCalendar: true
    })
  }, [])

  return (
    <input
      className={`form-control form-control-solid flatpickr-input ps-12 ${className}`}
      ref={ref}
      {...props}
    />
  )
}

export default TimePicker
