export const pageTitles = {
  '/': ' ',
  '/dashboard': 'Panel',
  '/screens': 'Ekranlar',
  '/playlists': 'Listeler',
  '/playlist-edit': 'Playlist Edit',
  '/calendar': 'Programlar',
  '/media-library': 'Medya Kaynakları',
  '/file-manager': 'File Manager',
  '/profile': 'Profil',
  '/file-manager/images': 'Images',
  '/file-manager/videos': 'Videos',
  '/file-manager/audios': 'Audios',
  '/file-manager/documents': 'Documents',
  '/file-manager/pdfs': 'PDFs',
  '/file-manager/gifs': 'GIFs',
  '/file-manager/stickers': 'Stickers'
}
