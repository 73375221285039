import axios from 'axios'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'

import { DialogClose, DialogFooter, DialogHeader } from '@/components/ui/dialog'

const EventInfo = ({ event, onClose }) => {
  const { t } = useTranslation()

  function handleSubmit(e) {
    e.preventDefault()

    Swal.fire({
      text: t('Are you sure you would like to add event?'),
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: t('Yes, add it!'),
      cancelButtonText: t('No, return'),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-active-light'
      }
    }).then(function (result) {
      if (result.value) {
        const formData = new FormData(e.target)

        axios
          .post(`${process.env.REACT_APP_API_BASE}/v1/source/`, formData)
          .then(response => {
            console.log(response.data)
            if (response.data.status === 200) {
              // Handle response
            }
          })
          .catch(error => {
            console.error(error)
            // Handle error
          })
      } else if (result.dismiss === 'cancel') {
        Swal.fire({
          text: t('Your form has not been cancelled!.'),
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: t('Ok, got it!'),
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        })
      }
    })
  }

  return (
    <>
      <form className="form" action="#" id="kt_modal_add_event_form">
        <DialogHeader>
          <div class="modal-header justify-content-end border-0">
            <div class="btn btn-icon btn-sm btn-color-gray-500 btn-active-icon-primary me-2">
              <i class="ki-outline ki-pencil fs-2"></i>
            </div>
            <div class="btn btn-icon btn-sm btn-color-gray-500 btn-active-icon-danger me-2">
              <i class="ki-outline ki-trash fs-2"></i>
            </div>
          </div>
        </DialogHeader>
        <div class="modal-body px-lg-17 pb-8 pt-8">
          <div class="d-flex">
            <i class="ki-outline ki-calendar-8 fs-1 text-muted me-5"></i>
            <div class="mb-9">
              <div class="d-flex align-items-center mb-2">
                <span class="fs-3 fw-bold me-3">
                  ICT Expo 2021 - Product Release
                </span>
                <span class="badge badge-light-success">{t('All Day')}</span>
              </div>
              <div class="fs-6">Lorem ipsum dolor sit tempor inci</div>
            </div>
          </div>
          <div class="d-flex align-items-center mb-2">
            <span class="bullet bullet-dot h-10px w-10px bg-success me-7 ms-2"></span>
            <div class="fs-6">
              <span class="fw-bold">{t('Starts')} </span>
              <span>May 29th 24</span>
            </div>
          </div>
          <div class="d-flex align-items-center mb-9">
            <span class="bullet bullet-dot h-10px w-10px bg-danger me-7 ms-2"></span>
            <div class="fs-6">
              <span class="fw-bold">{t('Ends')} </span>
              <span>May 31st 24</span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <i class="ki-outline ki-geolocation fs-1 text-muted me-5"></i>
            <div class="fs-6">Melbourne Exhibition Hall</div>
          </div>
        </div>

        <DialogFooter>
          <DialogClose className="btn btn-light" onClick={onClose}>
            {t('Close')}
          </DialogClose>
        </DialogFooter>
      </form>
    </>
  )
}

export default EventInfo
