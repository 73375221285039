import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { changeRegisterStatus, setUserInfo } from '@/redux/user-slice'
import { useRef, useState } from 'react'

export const Register = () => {
  const passwordRef = useRef()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [visibility, setVisibility] = useState(false)
  const [strength, setStrength] = useState(0)

  const handlePasswordChange = () => {
    const password = passwordRef.current.value

    let strength = 0

    if (password.length >= 8) strength++

    if (/[a-z]/.test(password)) strength++ // Lowercase
    if (/[A-Z]/.test(password)) strength++ // Uppercase
    if (/[0-9]/.test(password)) strength++ // Numbers
    if (/[\W_]/.test(password)) strength++ // Special characters

    setStrength(strength)
  }

  function handleSubmit(e) {
    e.preventDefault()

    axios
      .post(`${process.env.REACT_APP_API_BASE}/users`, {
        username: e.target.email.value.split('@')[0],
        email: e.target.email.value,
        password: e.target.password.value
      })
      .then(res => {
        if (res.status === 200) {
          toast.success(t('You have successfully registered.'))
          dispatch(setUserInfo(res.data))
          dispatch(changeRegisterStatus(true))

          navigate('/verify-mail')
        }
      })
      .catch(err => {
        toast.error(t('Registration failed.'))
      })
  }

  return (
    <form
      className="form w-100"
      id="kt_sign_up_form"
      data-kt-redirect-url="/metronic8/demo56/authentication/layouts/creative/sign-in.html"
      onSubmit={handleSubmit}
    >
      <div className="mb-11 text-center">
        <h1 className="fw-bolder mb-3 text-3xl text-gray-900">
          {t('Sign Up')}
        </h1>

        <div className="fw-semibold fs-6 text-gray-500">
          {t('Your Social Campaigns')}
        </div>
      </div>

      <div className="row g-3 mb-9">
        <div className="col-md-6">
          <a
            href="#"
            className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center w-100 text-nowrap"
          >
            <img
              alt="Logo"
              src="/assets/media/svg/brand-logos/google-icon.svg"
              className="h-15px me-3"
            />
            {t('Sign in with Google')}
          </a>
        </div>

        <div className="col-md-6">
          <a
            href="#"
            className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center w-100 text-nowrap"
          >
            <img
              alt="Logo"
              src="/assets/media/svg/brand-logos/apple-black.svg"
              className="theme-light-show h-15px me-3"
            />
            <img
              alt="Logo"
              src="/assets/media/svg/brand-logos/apple-black-dark.svg"
              className="theme-dark-show h-15px me-3"
            />
            {t('Sign in with Apple')}
          </a>
        </div>
      </div>

      <div className="separator separator-content my-14">
        <span className="w-125px fw-semibold fs-7 text-gray-500">
          {t('Or with email')}
        </span>
      </div>

      <div className="fv-row mb-8">
        <input
          type="email"
          placeholder={t('Email')}
          name="email"
          autoComplete="off"
          className="form-control bg-[#e8f0fe] focus:bg-[#e8f0fe]"
          required
        />
      </div>

      <div className="fv-row mb-8" data-kt-password-meter="true">
        <div className="mb-1">
          <div className="position-relative mb-3">
            <input
              className="form-control bg-[#e8f0fe] focus:bg-[#e8f0fe]"
              type={visibility ? 'text' : 'password'}
              placeholder={t('Password')}
              name="password"
              autoComplete="off"
              minLength="6"
              required
              ref={passwordRef}
              onChange={handlePasswordChange}
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 me-n2 end-0"
              data-kt-password-meter-control="visibility"
              onClick={() => setVisibility(!visibility)}
            >
              <i className="ki-outline ki-eye-slash fs-2"></i>{' '}
              <i className="ki-outline ki-eye fs-2 d-none"></i>{' '}
            </span>
          </div>

          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <div
                  className={`flex-grow-1 h-5px me-2 rounded ${index < strength ? 'bg-success' : 'bg-secondary'}`}
                  key={index}
                ></div>
              ))}
          </div>
        </div>

        <div className="text-muted">
          {t(
            'Use 8 or more characters with a mix of letters, numbers & symbols.'
          )}
        </div>
      </div>

      <div className="fv-row mb-8">
        <label className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            name="toc"
            value="1"
            required
          />
          <span className="form-check-label fw-semibold fs-base ms-1 text-gray-700">
            {t('I Accept the Terms')}
          </span>
        </label>
      </div>

      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-primary"
        >
          <span className="indicator-label">{t('Sign Up')}</span>

          <span className="indicator-progress">
            Please wait...{' '}
            <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
          </span>
        </button>
      </div>

      <div className="fw-semibold fs-6 text-center text-gray-500">
        {t('Already have an Account?')}
        <Link to="/login" className="link-primary fw-semibold ms-1">
          {t('Sign In')}
        </Link>
      </div>
    </form>
  )
}

export default Register
