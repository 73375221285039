import { useTranslation } from 'react-i18next'

import {
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import { changePlaylistEditStatus } from '@/redux/user-slice'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import Player from './player'

const PreviewDialog = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  let screen = []
  let playlist = []
  const originalUrl = 'https://webplayer.maiasignage.com/'
  const urlWithParams = `${originalUrl}?token=${user.user.user.token}&selected_playlist_id=${user.selected_playlist}`
  const [playlistId, setPlylistId] = useState(user?.selected_playlist)

  function screenList(data) {
    screen.push(data)
  }

  function pushPlaylistToDevice(e) {
    e.preventDefault()

    if (screen.length === 0) {
      toast.error(t('Please add screens to publish playlist'))
      return
    }

    axios
      .post(`${process.env.REACT_APP_API_BASE}/v1/screen/add/source`, {
        screens: screen,
        source: user?.selected_playlist._id
      })
      .then(res => {
        toast.success(t('Published playlist to device'))
        console.log(res.data)
      })
      .catch(err => {
        toast.error(t('Error publishing playlist to device'))
        console.log(err)
      })
  }

  useEffect(() => {
    if (!location.pathname.includes('playlist')) {
      dispatch(changePlaylistEditStatus(false))
    }
  }, [location, dispatch])

  return (
    <>
     
      <form>
     
        <div style={{padding:"10px"}} className="modal-body  flex items-center justify-center ">
        <DialogClose style={{width:"25px",height:"25px",borderRadios:"8px", justifyContent:"center",display:"flex", position:"absolute",top:"20px",right:"20px",zIndex:"9999"}} className="cls-btn"><i style={{cursor:"pointer"}} className="ki-outline ki-cross fs-2"></i>
        </DialogClose>
          <div style={{maxWidth:"1008px",borderRadius:"10px"}} className="app-blank w-full  overflow-hidden  border ">
            <Player source={playlistId} />
          </div>
        </div>
       
      </form>
    </>
  )
}

export default PreviewDialog
