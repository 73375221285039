import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import {
  Dropdown,
  DropdownContent,
  DropdownTrigger
} from '@/components/ui/dropdown'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

function MediaLibrary() {
  const user = useSelector(state => state.user)
  const [folders, setFolders] = useState([])
  const [fileList, setFileList] = useState('')
  const [newFolder, setNewFolder] = useState('')
  const [uploadImage, setUploadImage] = useState()
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [history, setHistory] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  function countTotalItems(fileList) {
    let folderCount
    let fileCount
    if (fileList) {

      folderCount = fileList?.folders ? fileList?.folders?.length : 0
      fileCount = fileList?.files ? fileList?.files?.length : 0
    }
    return folderCount + fileCount
  }

  function formatDate(dateString, language) {
    const date = new Date(dateString)

    let locale
    switch (language) {
      case 'English':
        locale = 'en-US'
        break
      case 'Turkish':
        locale = 'tr-TR'
        break
      case 'French':
        locale = 'fr-FR'
        break
      default:
        locale = 'en-US'
    }

    return date.toLocaleString(locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  function getFolder(id) {
    axios
      .get(
        process.env.REACT_APP_API_BASE +
        `/v1/filemanager/folder/${id === undefined ? '' : id}`, {
        headers: { Authorization: `Bearer ${user.user.user.token}` }
      }
      )
      .then(res => {
        setFolders(res?.data)
      })
  }

  useEffect(() => {
    getFolder(fileList?._id)
  }, [fileList])

  function selectedFolder(data) {
    setHistory(prevHistory => [...prevHistory, data])
    setFileList(data)
  }

  function createFolder() {

    axios
      .post(process.env.REACT_APP_API_BASE + '/v1/filemanager', {
        name: newFolder,
        parent: fileList?._id === undefined ? '' : fileList?._id
      }, { headers: { Authorization: `Bearer ${user.user.user.token}` } })
      .then(res => {
        if (res.status === 200) {
          const element = document.getElementById(
            'kt_file_manager_new_folder_row'
          )
          element.classList.add('d-none')
          setNewFolder("")
          getFolder(fileList?._id)
        }


      })
  }
  function renameFolder(folderId, newName) {
    axios
      .put(process.env.REACT_APP_API_BASE + `/v1/filemanager/${folderId}`, {
        name: newName
      })
      .then(res => {
        toast.success(t('Folder name changed successfully'))
        getFolder(fileList?._id) // Klasör listesini güncellemek için
      })
      .catch(error => {
        console.error(error)
        toast.error(t('An error occurred while changing the folder name'))
      })
  }
  function deleteFolder(id) {
    axios.delete(process.env.REACT_APP_API_BASE + `/v1/filemanager/${id}`).then(res => {
      if (res.status === 200) {
        toast.success("Dosya Başırıyla Silindi.")
        getFolder(fileList?._id)
      }
    }
    )
  }
  async function deleteFile(res1) {


    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE}/v1/widget/${res1._id}`,
        {
          headers: { Authorization: `Bearer ${user.user.user.token}` },
          data: {
            id: res1._id,
            widget: res1.type
          }
        }
      );

      if (response.status === 200) {
        toast.success("Dosya Başarıyla Silindi.");
        getFolder(fileList?._id);
      }
    } catch (error) {
      console.error("Silme işlemi sırasında hata oluştu:", error);
    }
  }


  /*  const handleSubmit = event => {
    event.preventDefault()

    const formData = new FormData()
    formData.append(
      `${uploadImage.type.includes('video') ? 'video' : 'image'}`,
      uploadImage
    )
    let folderParams = ''
    if (fileList?._id !== undefined) {
      folderParams = `?folder=${fileList._id}`
    }
    axios
      .post(
        process.env.REACT_APP_API_BASE +
          `/v1/widget/${uploadImage.type.includes('video') ? 'video' : 'image'}/upload${folderParams}`,
        formData,
        {
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            setUploadProgress(percentCompleted)
            console.log(`Yükleme ilerlemesi: ${percentCompleted}%`)
          }
        }
      )
      .then(response => {
        if (response.status === 200) {
          toast.success('Dosyanız başarıyla yüklendi')
          setShowModal(false)
          window.location.reload()
        }
      })
      .catch(error => {
        console.error(error)
      })
  } */

  function handleBreadcrumbClick(index) {
    setHistory(prevHistory => prevHistory.slice(0, index + 1))
    setFileList(history[index])
  }

  const filteredFolders = folders.folders?.filter(folder =>
    folder.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  const filteredFiles = folders.files?.filter(file =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  function handleUploadAll() {
    if (uploadImage) {
      const formData = new FormData()
      formData.append(
        `${uploadImage.type.includes('video') ? 'video' : 'image'}`,
        uploadImage
      )
      let folderParams = ''
      if (fileList?._id !== undefined) {
        folderParams = `?folder=${fileList._id}`
      }
      axios
        .post(
          process.env.REACT_APP_API_BASE +
          `/v1/widget/${uploadImage.type.includes('video') ? 'video' : 'image'}/upload${folderParams}`,
          formData,
          {
            onUploadProgress: progressEvent => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              setUploadProgress(percentCompleted)
              console.log(`Yükleme ilerlemesi: ${percentCompleted}%`)
            }
          }
        )
        .then(response => {
          console.log(response.status)
          if (response.status === 200) {

            toast.success(t('Your file has been uploaded successfully'))
            setShowModal(false)
            getFolder(fileList?._id)
          }
        })
        .catch(error => {
          console.error(error)
          toast.error(t('An error occurred while uploading the file'))
        })
    }
  }

  const handleRemoveAll = () => {
    setUploadImage(null)
    setUploadProgress(0)
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleUploadAll()
  }
  return (
    <>
      <div
        className="card card-flush bgi-position-y-center bgi-no-repeat mb-10 pb-0"
        style={{
          backgroundSize: 'auto calc(100% + 10rem)',
          backgroundPositionX: '100%',
          backgroundImage: `url('https://preview.keenthemes.com/metronic8/demo56/assets/media/illustrations/sketchy-1/4.png')`
        }}
      >
        <div className="card-header pt-10">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-circle me-5">
              <div className="symbol-label border-secondary bg-transparent border border-dashed text-primary">
                <i className="ki-outline ki-abstract-47 fs-2x text-primary"></i>{' '}
              </div>
            </div>

            <div className="d-flex flex-column">
              <h2 className="mb-1 text-2xl font-medium">{t('Media Assets')}</h2>
              <div className="fw-bold text-muted">
                2.6 GB <span className="mx-3">|</span> 758 {t('items')}
              </div>
            </div>
          </div>
        </div>

        <div className="card-body pb-0"></div>
      </div>
      <div className="card card-flush">
        <div className="card-header pt-8">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <i className="ki-outline ki-magnifier fs-1 position-absolute ms-6"></i>
              <input
                type="text"
                data-kt-filemanager-table-filter="search"
                className="form-control form-control-solid w-250px ps-15"
                placeholder={t('Search Files & Folders')}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className="card-toolbar">
            <div
              className="d-flex justify-content-end"
              data-kt-filemanager-table-toolbar="base"
            >
              <button
                onClick={() => {
                  const element = document.getElementById(
                    'kt_file_manager_new_folder_row'
                  )
                  element.classList.remove('d-none')
                }}
                type="button"
                className="btn btn-flex btn-light-primary me-3"
                id="kt_file_manager_new_folder"
              >
                <i className="ki-outline ki-add-folder fs-2"></i>
                {t('New Folder')}
              </button>

              <Dialog open={showModal}>
                <DialogTrigger

                  className="btn btn-primary er fs-6 px-8 py-4"
                  onClick={() => setShowModal(true)}
                >
                  <i className="ki-outline ki-folder-up fs-2"></i>
                  {t('Upload Files')}
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>{t('Upload Files')}</DialogTitle>
                  </DialogHeader>
                  <div className="modal-body pb-15 px-lg-17 pt-10">
                    <div className="form-group">
                      <div
                        className="dropzone-queue mb-2"
                        id="kt_modal_upload_dropzone"
                      >
                        <div className="d-flex">
                          <button
                            className="dropzone-select btn btn-sm btn-primary"
                            onClick={() =>
                              document.getElementById('fileInput').click()
                            }
                          >
                            {t('Attach Files')}
                          </button>
                          <span className="form-text fs-6 text-muted ms-3">
                            {/*   {uploadImage ? '1 file selected' : 'No files selected'} */}
                          </span>
                          {uploadImage && (
                            <>
                              <button
                                className="dropzone-select btn btn-sm btn-light-primary"
                                onClick={handleUploadAll}
                              >
                                {t('Upload All')}
                              </button>
                              <button
                                className="dropzone-select btn btn-sm btn-light-danger ms-2"
                                onClick={handleRemoveAll}
                              >
                                {t('Remove All')}
                              </button>
                            </>
                          )}
                        </div>

                        <input
                          type="file"
                          id="fileInput"
                          className="form-control d-none"
                          onChange={e => setUploadImage(e.target.files[0])}
                        />

                        {uploadImage && (
                          <>
                            <div
                              className="dropzone-items vm-200px bg-gray-100 mt-3"
                              style={{ borderRadius: '10px' }}
                            >
                              <div className="dropzone-item dz-image-preview d-flex flex-row p-5">
                                <div
                                  className="dropzone-file"
                                  style={{
                                    flex: 1,
                                    margin: '0 10px',
                                    alignSelf: 'center'
                                  }}
                                >
                                  <div className="dropzone-filename">
                                    <span>
                                      {uploadImage.name} (
                                      {(uploadImage.size / 1024).toFixed(1)} KB)
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="dropzone-progress"
                                  style={{
                                    flex: 1,
                                    margin: '0 10px',
                                    alignSelf: 'center'
                                  }}
                                >
                                  <div
                                    className="progress bg-gray-300"
                                    style={{ height: '6px' }}
                                  >
                                    <div
                                      className="progress-bar bg-primary"
                                      role="progressbar"
                                      style={{ width: `${uploadProgress}%` }}
                                      aria-valuenow={uploadProgress}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >

                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dropzone-toolbar"
                                  style={{ alignSelf: 'center' }}
                                >
                                  <span
                                    className="dropzone-start"
                                    style={{ cursor: 'pointer' }}
                                    onClick={e => handleSubmit(e)}
                                  >
                                    <i className="ki-outline ki-to-right fs-1"></i>
                                  </span>
                                  <span
                                    className="dropzone-cancel"
                                    data-dz-remove=""
                                  >
                                    <i className="ki-outline ki-cross fs-2"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <span className="form-text fs-6 text-muted mt-5">
                          {t('Max file size is 1MB per file.')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <DialogFooter>
                    <DialogClose
                      className="btn btn-light"
                      onClick={() => {
                        setShowModal(false)
                        setUploadImage(null)
                        setUploadProgress(0)
                      }}
                    >
                      {t('Cancel')}
                    </DialogClose>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>

            <div
              className="d-flex justify-content-end align-items-center d-none"
              data-kt-filemanager-table-toolbar="selected"
            >
              <div className="fw-bold me-5">
                <span
                  className="me-2"
                  data-kt-filemanager-table-select="selected_count"
                ></span>
                Selected
              </div>
              <button
                type="button"
                className="btn btn-danger"
                data-kt-filemanager-table-select="delete_selected"
              >
                Delete Selected
              </button>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="d-flex flex-stack">
            <div className="badge badge-lg badge-light-primary">
              <div className="align-items-center inline-flex cursor-pointer flex-wrap">
                <span
                  onClick={() => {
                    setFileList(undefined)
                    setHistory([])
                  }}
                >
                  {t('Media Assets')}
                </span>
                <i className="ki-outline ki-right fs-2x text-new-color mx-1"></i>

                {history.map((item, index) => (
                  <span
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    key={index}
                    onClick={() => handleBreadcrumbClick(index)}
                  >
                    {item.name}

                    <i className="ki-outline ki-right fs-2x mx-1 text-primary"></i>

                  </span>
                ))}
              </div>
            </div>
            <div className="badge badge-lg badge-primary">
              <span id="kt_file_manager_items_counter">
                {countTotalItems(folders)} {t('items')}
              </span>
            </div>
          </div>

          <table
            id="kt_file_manager_list"
            data-kt-filemanager-table="files"
            className="table-row-dashed fs-6 gy-5 table align-middle"
          >
            <thead>
              <tr className="fw-bold fs-7 gs-0 text-gray-500 text-start">
                <th className="min-w-250px !uppercase">{t('Name')}</th>
                <th className="min-w-10px !uppercase">{t('Size')}</th>
                <th className="min-w-125px !uppercase">{t('Last Modified')}</th>
                <th className="w-125px !uppercase"></th>
              </tr>
            </thead>
            <tbody className="fw-semibold text-gray-600">
              <tr
                className="d-none"
                id="kt_file_manager_new_folder_row"
                data-kt-filemanager-template="upload"
              >
                <td id="kt_file_manager_add_folder_form" className="fv-row">
                  <div className="d-flex align-items-center">
                    <span id="kt_file_manager_folder_icon">
                      <i className="ki-outline ki-folder fs-2x me-4 text-primary"></i>
                    </span>

                    <input
                      type="text"
                      name="new_folder_name"
                      placeholder="Enter the folder name"
                      className="form-control mw-250px me-3"
                      value={newFolder}
                      onChange={e => setNewFolder(e.target.value)}
                    />

                    <button
                      onClick={() => createFolder()}
                      className="btn btn-icon btn-light-primary me-3"
                      id="kt_file_manager_add_folder"
                    >
                      <span className="indicator-label">
                        <i className="ki-outline ki-check fs-1"></i>
                      </span>
                      <span className="indicator-progress">
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                      </span>
                    </button>

                    <button
                      onClick={() => {
                        const element = document.getElementById(
                          'kt_file_manager_new_folder_row'
                        )
                        element.classList.add('d-none')
                      }}
                      className="btn btn-icon btn-light-danger"
                      id="kt_file_manager_cancel_folder"
                    >
                      <span className="indicator-label">
                        <i className="ki-outline ki-cross fs-1"></i>
                      </span>
                      <span className="indicator-progress">
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                      </span>
                    </button>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {filteredFolders?.map((res, index) => {
              
                return (
                  <tr key={index}>
                    <td>
                      <div
                        className="align-items-center inline-flex cursor-pointer"
                        onClick={() => {
                          selectedFolder(res)
                        }}
                      >
                        <i
                          id={`folder-icon-${res._id}`}
                          className="ki-outline ki-folder fs-2x text-new-color me-4"
                        ></i>
                        <span
                          className="text-hover-primary text-gray-800"
                          id={`folder-name-${res._id}`}
                        >
                          {res.name}
                        </span>
                      </div>
                      <div id={`rename-folder-${res._id}`} className="d-none">
                        <div className="fv-row">
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              name="rename_folder_name"
                              placeholder="Enter the new folder name"
                              className="form-control mw-250px me-3"
                              defaultValue={res.name}
                              onChange={e => setNewFolder(e.target.value)}
                            />
                            <button
                              className="btn btn-icon btn-light-primary me-3"
                              onClick={() => {
                                renameFolder(res._id, newFolder)
                                document
                                  .getElementById(`rename-folder-${res._id}`)
                                  .classList.add('d-none')
                                document
                                  .getElementById(`folder-name-${res._id}`)
                                  .classList.remove('d-none')
                                document
                                  .getElementById(`folder-icon-${res._id}`)
                                  .classList.remove('d-none')
                              }}
                            >
                              <i className="ki-outline ki-check fs-1"></i>
                            </button>
                            <button
                              className="btn btn-icon btn-light-danger"
                              onClick={() => {
                                document
                                  .getElementById(`rename-folder-${res._id}`)
                                  .classList.add('d-none')
                                document
                                  .getElementById(`folder-name-${res._id}`)
                                  .classList.remove('d-none')
                                document
                                  .getElementById(`folder-icon-${res._id}`)
                                  .classList.remove('d-none')
                              }}
                            >
                              <i className="ki-outline ki-cross fs-1"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{res?.size ?? 'N/A'}</td>
                    <td>{formatDate(res.updatedAt, user?.language)}</td>
                    <td
                      className="text-end"
                      data-kt-filemanager-table="action_dropdown"
                    >
                      <div className="d-flex justify-content-end">
                        <div className="ms-2">
                          <Dropdown>
                            <DropdownTrigger
                              trigger="click"
                              placement="bottom-end"
                            >
                              <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
                                <i className="ki-solid ki-dots-horizontal fs-2x"></i>{' '}
                              </button>
                            </DropdownTrigger>
                            <DropdownContent className="w-200px fs-7">
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">
                                  {t('Download File')}
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a
                                  href="#"
                                  className="menu-link px-3"
                                  data-kt-filemanager-table="rename"
                                  onClick={() => {
                                    document
                                      .getElementById(`rename-folder-${res._id}`)
                                      .classList.remove('d-none')
                                    document
                                      .getElementById(`folder-name-${res._id}`)
                                      .classList.add('d-none')
                                    document
                                      .getElementById(`folder-icon-${res._id}`)
                                      .classList.add('d-none')
                                  }}
                                >
                                  {t('Rename')}
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a
                                  href="#"
                                  className="menu-link px-3"
                                  data-kt-filemanager-table-filter="move_row"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kt_modal_move_to_folder"
                                >
                                  {t('Move to Folder')}
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a
                                  href="#"
                                  className={`menu-link px-3 ${res.name === 'default' ? 'text-muted' : 'text-danger'}`}
                                  data-kt-filemanager-table-filter="delete_row"
                                  onClick={() => {
                                    if (res.name !== 'default') {
                                      deleteFolder(res._id)
                                    }
                                  }}
                                  style={{ pointerEvents: res.name === 'default' ? 'none' : 'auto' }}
                                >
                                  {t('Delete')}
                                </a>
                              </div>
                            </DropdownContent>
                          </Dropdown>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}

              {filteredFiles?.map((res, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <i
                        className={`ki-outline ${res.type === 'image' ? 'ki-picture' : 'ki-youtube'} ki-picture fs-2x text-new-color me-4`}
                      ></i>
                      <a href="#" className="text-hover-primary text-gray-800">
                        {res?.name}
                      </a>
                    </div>
                  </td>
                  <td>{res?.size ?? 'N/A'}</td>
                  <td>{formatDate(res.updatedAt, user?.language)}</td>
                  <td
                    className="text-end"
                    data-kt-filemanager-table="action_dropdown"
                  >
                    <div className="d-flex justify-content-end">
                      <div className="ms-2">
                        <Dropdown>
                          <DropdownTrigger
                            trigger="click"
                            placement="bottom-end"
                          >
                            <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
                              <i className="ki-solid ki-dots-horizontal fs-2x"></i>{' '}
                            </button>
                          </DropdownTrigger>
                          <DropdownContent className="w-200px fs-7">
                            <div className="menu-item px-3">
                              <a href="#" className="menu-link px-3">
                                {t('Download File')}
                              </a>
                            </div>
                            <div className="menu-item px-3">
                              <a
                                href="#"
                                className="menu-link px-3"
                                data-kt-filemanager-table="rename"
                                onClick={() => {
                                  document
                                    .getElementById(`rename-folder-${res._id}`)
                                    .classList.remove('d-none')
                                  document
                                    .getElementById(`folder-name-${res._id}`)
                                    .classList.add('d-none')
                                  document
                                    .getElementById(`folder-icon-${res._id}`)
                                    .classList.add('d-none')
                                }}
                              >
                                {t('Rename')}
                              </a>
                            </div>
                            <div className="menu-item px-3">
                              <a
                                href="#"
                                className="menu-link px-3"
                                data-kt-filemanager-table-filter="move_row"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_move_to_folder"
                              >
                                {t('Move to Folder')}
                              </a>
                            </div>
                            <div className="menu-item px-3">
                              <a
                                href="#"
                                className="menu-link text-danger px-3"
                                data-kt-filemanager-table-filter="delete_row"
                                onClick={() => deleteFile(res)}
                              >
                                {t('Delete')}
                              </a>
                            </div>
                          </DropdownContent>
                        </Dropdown>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <table className="d-block"></table>

      <div className="d-none" data-kt-filemanager-template="rename">
        <div className="fv-row">
          <div className="d-flex align-items-center">
            <span id="kt_file_manager_rename_folder_icon"></span>
            <input
              type="text"
              id="kt_file_manager_rename_input"
              name="rename_folder_name"
              placeholder="Enter the new folder name"
              className="form-control mw-250px me-3"
              value=""
            />
            <button
              className="btn btn-icon btn-light-primary me-3"
              id="kt_file_manager_rename_folder"
            >
              <i className="ki-outline ki-check fs-1"></i>
            </button>
            <button
              className="btn btn-icon btn-light-danger"
              id="kt_file_manager_rename_folder_cancel"
            >
              <span className="indicator-label">
                <i className="ki-outline ki-cross fs-1"></i>
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle"></span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="d-none" data-kt-filemanager-template="action">
        <div className="d-flex justify-content-end">
          <div className="ms-2" data-kt-filemanger-table="copy_link">
            <button
              className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              <i className="ki-solid ki-dots-horizontal fs-2x"></i>{' '}
            </button>
            <div
              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
              data-kt-menu="true"
            >
              <div className="card card-flush">
                <div className="card-body p-5">
                  <div
                    className="d-flex"
                    data-kt-filemanger-table="copy_link_generator"
                  >
                    <div className="me-5" data-kt-indicator="on">
                      <span className="indicator-progress">
                        <span className="spinner-border spinner-border-sm ms-2 align-middle"></span>
                      </span>
                    </div>

                    <div className="fs-6 text-gray-900">
                      Generating Share Link...
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column d-none text-start"
                    data-kt-filemanger-table="copy_link_result"
                  >
                    <div className="d-flex mb-3">
                      <i className="ki-outline ki-check fs-2 text-success me-3"></i>
                      <div className="fs-6 text-gray-900">
                        Share Link Generated
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value="https://path/to/file/or/folder/"
                    />
                    <div className="fw-normal fs-8 text-muted mt-2 px-3">
                      Read only.
                      <a
                        href="apps/file-manager/settings/.html"
                        className="ms-2"
                      >
                        Change permissions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ms-2">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-light btn-active-light-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              <i className="ki-outline ki-dots-square fs-5 m-0"></i>
            </button>

            <div
              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
              data-kt-menu="true"
            >
              <div className="menu-item px-3">
                <a href="#" className="menu-link px-3">
                  Download File
                </a>
              </div>

              <div className="menu-item px-3">
                <a
                  href="#"
                  className="menu-link px-3"
                  data-kt-filemanager-table="rename"
                >
                  Rename
                </a>
              </div>

              <div className="menu-item px-3">
                <a
                  href="#"
                  className="menu-link px-3"
                  data-kt-filemanager-table-filter="move_row"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_move_to_folder"
                >
                  Move to folder
                </a>
              </div>

              <div className="menu-item px-3">
                <a
                  href="#"
                  className="menu-link text-danger px-3"
                  data-kt-filemanager-table-filter="delete_row"
                >
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MediaLibrary
