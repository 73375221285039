import {
  Dropdown,
  DropdownContent,
  DropdownTrigger
} from '@/components/ui/dropdown'
import { selectedPlaylist } from '@/redux/user-slice'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useSWRConfig } from 'swr'
const PlaylistImage = ({ content }) => {
  const { path, file, type, meta } = (content && content[0].playlist[0]) || {}
  const imageUrl = `https://cdn.maiasignage.com/${path}/${file}`
  const videoUrl = `${process.env.REACT_APP_BUNNY_PULL_ZONE_URL}/${meta?.video_id}/thumbnail.jpg`

  return type === 'image' ? (
    <img
      className="playlist-table-item aspect-video w-24 !bg-[#f1f1f4]"
      src={imageUrl}
      alt="cover"
    />
  ) : type === 'video' ? (
    <img
      className="playlist-table-item aspect-video w-24 !bg-[#f1f1f4]"
      src={videoUrl}
      alt="cover"
    />
  ) : (
    <div className="playlist-table-item aspect-video w-24 !bg-[#f1f1f4]" />
  )
}

function secondsToHMS(duration) {
  let hours = Math.floor(parseInt(duration) / 3600)
  let minutes = Math.floor((parseInt(duration) % 3600) / 60)
  let seconds = parseInt(duration) % 60

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return hours + ':' + minutes + ':' + seconds
}

export const useColumns = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const playlists = useSelector(state => state.user.playlists) // Redux store'dan playlistleri alın
  const [renameBtn, setRenameBtn] = useState(null)
  const [newName, setNewName] = useState("")
  const { mutate } = useSWRConfig();
  const user =useSelector(state=>state.user)



  const handleNewName = async (e, data) => {
    e.preventDefault()

    try {
      await axios.put(`https://api.maiasignage.com/api/v1/source/${data._id}`, {
        name: newName,
      },{  headers: { Authorization: `Bearer ${user.user.user.token}` }})
      // Playlist ismini güncelledikten sonra Redux store'da güncelle
     
      setRenameBtn(null)
      mutate(`${process.env.REACT_APP_API_BASE}/v1/source`)
    } catch (error) {
      console.error("Error updating name:", error)
    }
  }

  const handleDuplicate = async (playlistId, playlistName, data) => {
    const newPlaylistName = `${playlistName} copy`
   

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/source/`, {
        name: newPlaylistName,
        layout: data.layout._id,
        source_type: 'playlist',
      })

      if (response.status === 200) {
        const updatedResponse = await axios.put(
          `${process.env.REACT_APP_API_BASE}/v1/source/${response.data._id}`,
          { content: data.content }
        )

        if (updatedResponse.status === 200) {
          toast.success(t('Playlist duplicated'))
          navigate(`/playlists/${updatedResponse.data._id}`)
          dispatch(selectedPlaylist(updatedResponse.data))
        }
      } else {
        toast.error(t('Error duplicating playlist'))
      }
    } catch (error) {
      toast.error(t('Error duplicating playlist'))
      console.error(error)
    }
  }
  const handleDelete = async (playlistId) => {
    try {
      // API'ye DELETE isteği gönder
      await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/source/${playlistId}`,{  headers: { Authorization: `Bearer ${user.user.user.token}` }}).then(res=>{


        if(res.status===200){
          mutate(`${process.env.REACT_APP_API_BASE}/v1/source`);
  
          // Kullanıcıya bilgi ver
          toast.success(t('Playlist deleted successfully'));
        }
      });
  
      // Başarı durumunda Redux store'u güncelle
    
      // Veri önbelleğini güncelleyin
     
    } catch (error) {
      console.error("Error deleting playlist:", error);
      toast.error(t('Error deleting playlist'));
    }
  };
  
  return [
    {
      accessorKey: 'name',
      header: ({ column }) => {
        return (
          <div
            className="align-items-center inline-flex cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            <i className="ki-outline ki-sort fs-2 text-gray-500 me-2 ms-1" />
            {t('Playlist Name')}
          </div>
        )
      },
      cell: ({ column, row }) => {
        const { _id, name, content } = row.original

        return (
         <>
            {row.original._id === renameBtn ? (
              <form className="d-flex align-items-center" onSubmit={(e) => handleNewName(e, row.original)}>
                <input
                  className="form-control mw-250px me-3"
                  name="name"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  autoFocus
                />
                <button className="btn btn-icon btn-light-primary me-3 flex-shrink-0" type="submit">
                  <span className="indicator-label">
                    <i className="ki-outline ki-check fs-1"></i>
                  </span>
                  <span className="indicator-progress">
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                </button>
                <button className="btn btn-icon btn-light-danger flex-shrink-0" type="button" onClick={() => setRenameBtn(null)}>
                  <span className="indicator-label">
                    <i className="ki-outline ki-cross fs-1"></i>
                  </span>
                  <span className="indicator-progress">
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                </button>
              </form>
            ) : (
              <>
              <Link to={_id} className="text-hover-primary text-gray-900 flex items-center" onClick={()=>dispatch(selectedPlaylist(row.original))} >
                <PlaylistImage  content={content} /> 
                <div style={{ marginLeft:10}} >{name}</div>
                </Link>
              </>
            )}
            </>
       
        )
      }
    },
    {
      accessorKey: 'duration',
      header: t('Duration'),
      cell: ({ column, row }) => {
        const { content } = row.original

        let totalDuration = 0

        content &&
          content[0].playlist.map(item => {
            if (item.meta?.duration) {
              totalDuration += item.meta?.duration
            }
          })

        return secondsToHMS(totalDuration)
      }
    },
    {
      accessorKey: 'updatedAt',
      header: t('Last Edited Date'),
      cell: ({ column, row }) => {
        const { i18n } = useTranslation()
        const { updatedAt } = row.original
        return moment(updatedAt).locale(i18n.language).format('LL')
      }
    },
    {
      accessorKey: 'actions',
      header: ({ column }) => <div className="text-end">{t('Settings')}</div>,
      cell: ({ row }) => {
        const { _id, name } = row.original;
    
        return (
          <div className="d-flex justify-content-end">
            <Dropdown>
              <DropdownTrigger trigger="click" placement="bottom-end">
                <button
                  className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i className="ki-solid ki-dots-horizontal fs-2x"></i>
                </button>
              </DropdownTrigger>
              <DropdownContent className="w-200px fs-7">
                <div className="menu-item px-3">
                  <a
                    href="#"
                    data-kt-subscriptions-table-filter="delete_row"
                    className="menu-link px-3"
                  >
                    {t('Playlist Details')}
                  </a>
                  <a
                    href="#"
                    onClick={() => handleDuplicate(_id, name, row.original)}
                    className="menu-link px-3"
                  >
                    {t('Clone')}
                  </a>
    
                  <a
                    href="#"
                    data-kt-subscriptions-table-filter="delete_row"
                    className="menu-link px-3"
                    onClick={() => {
                      setRenameBtn(row.original._id)
                      setNewName(name)
                    }}
                  >
                    {t('Rename')}
                  </a>
    
                  <a
                    href="#"
                    className="menu-link text-danger px-3"
                    data-kt-filemanager-table-filter="delete_row"
                    onClick={() => handleDelete(_id)}
                  >
                    {t('Delete')}
                  </a>
                </div>
              </DropdownContent>
            </Dropdown>
          </div>
        );
      }
    }
  ]
}
