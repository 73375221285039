import { useTranslation } from 'react-i18next'

import {
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'

const AddPlaylist = ({ setStep }) => {
  const { t } = useTranslation()

  return (
    <>
      <DialogHeader>
        <DialogTitle>{t('Add Playlist')}</DialogTitle>
      </DialogHeader>

      <div className="modal-body px-17 py-10">
        <div className="fv-row mb-9">
          <label className="fs-6 fw-semibold mb-2">{t('Name')}</label>
          <input
            type="text"
            className="form-control form-control-solid"
            placeholder={t('Enter Playlist Name')}
            name="name"
          />
        </div>
        <div className="fv-row mb-9">
          <label className="fs-6 fw-semibold mb-2">{t('Team')}</label>
          <input
            type="text"
            className="form-control form-control-solid"
            placeholder={t('Enter Team')}
            name="team"
          />
        </div>
        <div className="fv-row mb-9">
          <label className="fs-6 fw-semibold mb-2">{t('Tags')}</label>
          <input
            type="text"
            className="form-control form-control-solid"
            placeholder={t('Optional')}
            name="tags"
          />
        </div>
      </div>
      <DialogFooter>
        <DialogClose className="btn btn-light">{t('Cancel')}</DialogClose>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => setStep(2)}
        >
          {t('Add Playlist')}
        </button>
      </DialogFooter>
    </>
  )
}

export default AddPlaylist
