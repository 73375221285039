import * as TabsPrimitive from '@radix-ui/react-tabs'
import * as React from 'react'

import { cn } from '@/lib/utils'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.List ref={ref} {...props} asChild>
    <ul
      className={cn(
        'nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex h-full w-full justify-around border-transparent px-8',
        className
      )}
    >
      {props.children}
    </ul>
  </TabsPrimitive.List>
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef(({ className, ...props }, ref) => (
  <li className="nav-item mt-2">
    <TabsPrimitive.Trigger ref={ref} {...props} asChild>
      <span
        className={cn(
          'nav-link border-b-4 data-[state=active]:!border-[#1e29f3] data-[state=active]:!text-[#1e29f3]',
          className
        )}
      >
        {props.children}
      </span>
    </TabsPrimitive.Trigger>
  </li>
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.Content ref={ref} className={cn('', className)} {...props} />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
