import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import AddScreen from '@/pages/screens/data-table/add-screen'
import { formDataToJSON } from '@/utils/formDataToJSON'
import axios from 'axios'
import { PlayCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import useSWR, { useSWRConfig } from 'swr'
import { useDispatch, useSelector } from 'react-redux'
import Countdown from "react-countdown";
const Step = ({ number, title, description, buttonText, ActionDialog }) => (
  <div className="card relative flex h-[300px] flex-col justify-between p-8 shadow-md transition-all duration-300 ease-in-out hover:-translate-y-1 hover:shadow-lg">
    <div className="absolute right-4 top-4 text-7xl font-bold opacity-10">
      {number}
    </div>
    <div>
      <h3 className="text-heading mb-4 text-3xl font-bold">{title}</h3>
      <p className="text-gray-500 text-[16px]">{description}</p>
    </div>
    <Dialog>
      <DialogTrigger className="btn btn-primary max-w-max py-4 text-[14px] transition-all hover:-translate-y-0.5">
        {buttonText}
      </DialogTrigger>

      <DialogContent>
        <ActionDialog />
      </DialogContent>
    </Dialog>
  </div>
)

const Feature = ({ icon, title, description }) => (
  <div className="card p-8 shadow-md">
    <div className="mb-8 text-5xl text-primary">{icon}</div>
    <h3 className="text-heading mb-4 text-3xl font-bold">{title}</h3>
    <p className="text-gray-500 text-[16px]">{description}</p>
  </div>
)

const Dashboard = () => {
  const { t } = useTranslation()
  const { mutate } = useSWRConfig()
  const fetcher = (...args) => axios.get(...args)
  const user =useSelector(state=>state.user)
  const screens = user.screen;
  const screensStatus = user.screensStatus;

  const onlineScreens = screens?.filter(screen => screensStatus[String(screen?._id)]?.status === 'online');
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_API_BASE}/v1/screen`,
    fetcher
  )

  function addScreenHandler(e) {
    e.preventDefault()

    const formData = new FormData(e.target)
    const jsonData = formDataToJSON(formData)

    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE}/v1/screen`, jsonData)
        .then(response => {
          if (response.status === 200) {
            toast.success(t('Screen created'))

            mutate(`${process.env.REACT_APP_API_BASE}/v1/screen`)
          } else {
            toast.error(t('Error creating screen'))
          }
        })
        .catch(error => {
          console.error(error)
          toast.error(error.response.data.message)
        })
    } catch (error) {
      toast.error(t('Error creating screen'))
      console.error(error)
    }
  }

  const dataArray = data?.data || []
  console.log(user)
  
  if (isLoading) return

  return dataArray.length ? (
    <div className="grid gap-8">
      <div className="grid grid-cols-2 gap-8">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <div class="card card-flush">
            <div class="card-header pt-5">
              <div class="card-title d-flex flex-column">
                <span class="fs-2hx fw-bold text-gray-900 lh-1 ls-n2 me-2">
                 {Object.values(onlineScreens).length}
                </span>

                <span class="text-gray-500 fw-semibold fs-6 pt-1">
                  {t('Total Online Screens')}
                </span>
              </div>
            </div>

            <div class="card-body d-flex flex-column justify-content-end pe-0">
              <span class="fs-6 fw-bolder text-gray-800 d-block mb-2">
                {t('Devices Overview')}
              </span>

              <div class="symbol-group symbol-hover flex-nowrap">
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Alan Warden"
                  data-kt-initialized="1"
                >
                  <span class="symbol-label bg-warning text-inverse-warning fw-bold">
                    A
                  </span>
                </div>
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  aria-label="Michael Eberon"
                  data-bs-original-title="Michael Eberon"
                  data-kt-initialized="1"
                >
                  <img alt="Pic" src="/assets/media/avatars/300-11.jpg" />
                </div>
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Susan Redwood"
                  data-kt-initialized="1"
                >
                  <span class="symbol-label text-inverse-primary fw-bold bg-primary">
                    S
                  </span>
                </div>
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  aria-label="Melody Macy"
                  data-bs-original-title="Melody Macy"
                  data-kt-initialized="1"
                >
                  <img alt="Pic" src="/assets/media/avatars/300-2.jpg" />
                </div>
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Perry Matthew"
                  data-kt-initialized="1"
                >
                  <span class="symbol-label bg-danger text-inverse-danger fw-bold">
                    P
                  </span>
                </div>
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  aria-label="Barry Walter"
                  data-bs-original-title="Barry Walter"
                  data-kt-initialized="1"
                >
                  <img alt="Pic" src="/assets/media/avatars/300-12.jpg" />
                </div>
                <a
                  href="#"
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_view_users"
                >
                  <span class="symbol-label bg-light text-gray-400 fs-8 fw-bold">
                    +42
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="card card-flush">
            <div class="card-header pt-5">
              <div class="card-title d-flex flex-column">
                <span class="fs-2hx fw-bold text-gray-900 lh-1 ls-n2 me-2">
                {user.screen.length-Object.values(onlineScreens).length}
                </span>

                <span class="text-gray-500 fw-semibold fs-6 pt-1">
                  {t('Total Offline Screens')}
                </span>
              </div>
            </div>

            <div class="card-body d-flex flex-column justify-content-end pe-0">
              <span class="fs-6 fw-bolder text-gray-800 d-block mb-2">
                {t('Devices Overview')}
              </span>

              <div class="symbol-group symbol-hover flex-nowrap">
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Alan Warden"
                  data-kt-initialized="1"
                >
                  <span class="symbol-label bg-warning text-inverse-warning fw-bold">
                    A
                  </span>
                </div>
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  aria-label="Michael Eberon"
                  data-bs-original-title="Michael Eberon"
                  data-kt-initialized="1"
                >
                  <img alt="Pic" src="/assets/media/avatars/300-11.jpg" />
                </div>
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Susan Redwood"
                  data-kt-initialized="1"
                >
                  <span class="symbol-label text-inverse-primary fw-bold bg-primary">
                    S
                  </span>
                </div>
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  aria-label="Melody Macy"
                  data-bs-original-title="Melody Macy"
                  data-kt-initialized="1"
                >
                  <img alt="Pic" src="/assets/media/avatars/300-2.jpg" />
                </div>
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Perry Matthew"
                  data-kt-initialized="1"
                >
                  <span class="symbol-label bg-danger text-inverse-danger fw-bold">
                    P
                  </span>
                </div>
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  aria-label="Barry Walter"
                  data-bs-original-title="Barry Walter"
                  data-kt-initialized="1"
                >
                  <img alt="Pic" src="/assets/media/avatars/300-12.jpg" />
                </div>
                <a
                  href="#"
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_view_users"
                >
                  <span class="symbol-label bg-light text-gray-400 fs-8 fw-bold">
                    +42
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="card card-flush">
            <div class="card-header pt-5">
              <div class="card-title d-flex flex-column">
                <div class="d-flex align-items-center">
                  <span class="fs-2hx fw-bold text-gray-900 lh-1 ls-n2 me-2">
                    10 GB
                  </span>

                  <span class="badge badge-light-success fs-base">
                    <i class="ki-outline ki-up fs-5 text-success ms-n1"></i>
                    42%
                  </span>
                </div>

                <span class="text-gray-500 fw-semibold fs-6 pt-1">
                  {t('Total Storage')}
                </span>
              </div>
            </div>

            <div class="card-body d-flex align-items-center pb-4 pt-2">
              <div class="d-flex flex-center me-5 pt-2">
                <div
                  id="kt_card_widget_4_chart"
                  style={{
                    minWidth: '70px',
                    minHeight: '70px'
                  }}
                  data-kt-size="70"
                  data-kt-line="11"
                >
                  <span></span>
                  <canvas height="70" width="70"></canvas>
                </div>
              </div>

              <div class="d-flex flex-column content-justify-center w-100">
                <div class="d-flex fs-6 fw-semibold align-items-center">
                  <div class="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>

                  <div class="text-gray-500 flex-grow-1 me-4">
                    {t('Used Storage')}
                  </div>

                  <div class="fw-bolder text-gray-700 text-xxl-end">5.8 GB</div>
                </div>

                <div class="d-flex fs-6 fw-semibold align-items-center my-3">
                  <div class="bullet w-8px h-6px rounded-2 me-3 bg-primary"></div>

                  <div class="text-gray-500 flex-grow-1 me-4">
                    {t('Avaiable')}
                  </div>

                  <div class="fw-bolder text-gray-700 text-xxl-end">4.2 GB</div>
                </div>
              </div>
            </div>
          </div>

          <div class="card card-flush">
            <div class="card-header pt-5">
              <h3 class="card-title text-gray-800">{t('Screen Licences')}</h3>

              <div class="card-toolbar d-none">
                <div
                  data-kt-daterangepicker="true"
                  data-kt-daterangepicker-opens="left"
                  class="btn btn-sm btn-light d-flex align-items-center px-4"
                  data-kt-initialized="1"
                >
                  <div class="text-gray-600 fw-bold">
                    6 Haz 2024 - 5 Tem 2024
                  </div>

                  <i class="ki-outline ki-calendar-8 fs-1 me-0 ms-2"></i>
                </div>
              </div>
            </div>

            <div class="card-body pt-5">
              <div class="d-flex flex-stack">
                <div class="text-gray-700 fw-semibold fs-6 me-2">
                  {t('Total')}
                </div>

                <div class="d-flex align-items-senter">
                  <span class="text-gray-500 fw-bold fs-6">{user?.user?.user?.subscription?.serial_count}</span>
                </div>
              </div>

              <div class="separator separator-dashed my-3"></div>

              <div class="d-flex flex-stack">
                <div class="text-gray-700 fw-semibold fs-6 me-2">
                  {t('Used')}
                </div>

                <div class="d-flex align-items-senter">
                  <span class="text-gray-900 fw-bolder fs-6">{user.screen.length}</span>
                </div>
              </div>

              <div class="separator separator-dashed my-3"></div>

              <div class="d-flex flex-stack">
                <div class="text-gray-700 fw-semibold fs-6 me-2">
                  {t('Remaining')}
                </div>

                <div class="d-flex align-items-senter">
                  <span class="text-gray-900 fw-bolder fs-6">{user?.user?.user?.subscription?.serial_count-user.screen.length}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bold text-gray-900">
                {t('What’s up Today')}
              </span>

              <span class="text-muted fw-semibold fs-7 mt-1">
                {t('Total')} 25 {t('playlists')}
              </span>
            </h3>

            <div class="card-toolbar">
              <a href="#" class="btn btn-sm btn-light">
                {t('Report Cecnter')}
              </a>
            </div>
          </div>

          <div class="card-body px-0 pt-7">
            <ul
              class="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5"
              role="tablist"
            >
              <li class="nav-item ms-0 p-0" role="presentation">
                <a
                  class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px btn-active-danger px-3 py-4"
                  data-bs-toggle="tab"
                  href="#kt_timeline_widget_3_tab_content_1"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <span class="fs-7 fw-semibold">Fr</span>
                  <span class="fs-6 fw-bold">20</span>
                </a>
              </li>

              <li class="nav-item ms-0 p-0" role="presentation">
                <a
                  class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px btn-active-danger px-3 py-4"
                  data-bs-toggle="tab"
                  href="#kt_timeline_widget_3_tab_content_2"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <span class="fs-7 fw-semibold">Sa</span>
                  <span class="fs-6 fw-bold">21</span>
                </a>
              </li>

              <li class="nav-item ms-0 p-0" role="presentation">
                <a
                  class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px btn-active-danger px-3 py-4"
                  data-bs-toggle="tab"
                  href="#kt_timeline_widget_3_tab_content_3"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <span class="fs-7 fw-semibold">Su</span>
                  <span class="fs-6 fw-bold">22</span>
                </a>
              </li>

              <li class="nav-item ms-0 p-0" role="presentation">
                <a
                  class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px btn-active-danger active px-3 py-4"
                  data-bs-toggle="tab"
                  href="#kt_timeline_widget_3_tab_content_4"
                  aria-selected="true"
                  role="tab"
                >
                  <span class="fs-7 fw-semibold">Tu</span>
                  <span class="fs-6 fw-bold">23</span>
                </a>
              </li>

              <li class="nav-item ms-0 p-0" role="presentation">
                <a
                  class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px btn-active-danger px-3 py-4"
                  data-bs-toggle="tab"
                  href="#kt_timeline_widget_3_tab_content_5"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <span class="fs-7 fw-semibold">Tu</span>
                  <span class="fs-6 fw-bold">24</span>
                </a>
              </li>

              <li class="nav-item ms-0 p-0" role="presentation">
                <a
                  class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px btn-active-danger px-3 py-4"
                  data-bs-toggle="tab"
                  href="#kt_timeline_widget_3_tab_content_6"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <span class="fs-7 fw-semibold">We</span>
                  <span class="fs-6 fw-bold">25</span>
                </a>
              </li>

              <li class="nav-item ms-0 p-0" role="presentation">
                <a
                  class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px btn-active-danger px-3 py-4"
                  data-bs-toggle="tab"
                  href="#kt_timeline_widget_3_tab_content_7"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <span class="fs-7 fw-semibold">Th</span>
                  <span class="fs-6 fw-bold">26</span>
                </a>
              </li>

              <li class="nav-item ms-0 p-0" role="presentation">
                <a
                  class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px btn-active-danger px-3 py-4"
                  data-bs-toggle="tab"
                  href="#kt_timeline_widget_3_tab_content_8"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <span class="fs-7 fw-semibold">Fri</span>
                  <span class="fs-6 fw-bold">27</span>
                </a>
              </li>

              <li class="nav-item ms-0 p-0" role="presentation">
                <a
                  class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px btn-active-danger px-3 py-4"
                  data-bs-toggle="tab"
                  href="#kt_timeline_widget_3_tab_content_9"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <span class="fs-7 fw-semibold">Sa</span>
                  <span class="fs-6 fw-bold">28</span>
                </a>
              </li>

              <li class="nav-item ms-0 p-0" role="presentation">
                <a
                  class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px btn-active-danger px-3 py-4"
                  data-bs-toggle="tab"
                  href="#kt_timeline_widget_3_tab_content_10"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <span class="fs-7 fw-semibold">Su</span>
                  <span class="fs-6 fw-bold">29</span>
                </a>
              </li>

              <li class="nav-item ms-0 p-0" role="presentation">
                <a
                  class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px btn-active-danger px-3 py-4"
                  data-bs-toggle="tab"
                  href="#kt_timeline_widget_3_tab_content_11"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <span class="fs-7 fw-semibold">Mo</span>
                  <span class="fs-6 fw-bold">30</span>
                </a>
              </li>
            </ul>

            <div class="tab-content mb-2 px-9">
              <div
                class="tab-pane fade"
                id="kt_timeline_widget_3_tab_content_1"
                role="tabpanel"
              >
                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-success me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      10:20 - 11:00
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-warning me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      12:00 - 13:40
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Dashboard UI/UX Design Review{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Bob
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-info me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      16:30 - 17:00
                      <span class="text-gray-500 fw-semibold fs-7">PM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="kt_timeline_widget_3_tab_content_2"
                role="tabpanel"
              >
                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-warning me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      16:30 - 17:00
                      <span class="text-gray-500 fw-semibold fs-7">PM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-info me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      12:00 - 13:40
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-success me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      10:20 - 11:00
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="kt_timeline_widget_3_tab_content_3"
                role="tabpanel"
              >
                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      10:20 - 11:00
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-warning me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      12:00 - 13:40
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Bob
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-info me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      16:30 - 17:00
                      <span class="text-gray-500 fw-semibold fs-7">PM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>
              </div>

              <div
                class="tab-pane fade show active"
                id="kt_timeline_widget_3_tab_content_4"
                role="tabpanel"
              >
                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-info me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      10:20 - 11:00
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-warning me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      16:30 - 17:00
                      <span class="text-gray-500 fw-semibold fs-7">PM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Dashboard UI/UX Design Review{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Bob
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-success me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      12:00 - 13:40
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="kt_timeline_widget_3_tab_content_5"
                role="tabpanel"
              >
                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-danger me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      12:00 - 13:40
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Dashboard UI/UX Design Review{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Bob
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-warning me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      10:20 - 11:00
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-info me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      16:30 - 17:00
                      <span class="text-gray-500 fw-semibold fs-7">PM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="kt_timeline_widget_3_tab_content_6"
                role="tabpanel"
              >
                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-info me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      10:20 - 11:00
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      16:30 - 17:00
                      <span class="text-gray-500 fw-semibold fs-7">PM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-warning me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      12:00 - 13:40
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Dashboard UI/UX Design Review{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Bob
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="kt_timeline_widget_3_tab_content_7"
                role="tabpanel"
              >
                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-warning me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      12:00 - 13:40
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Bob
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-danger me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      10:20 - 11:00
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Dashboard UI/UX Design Review{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-success me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      16:30 - 17:00
                      <span class="text-gray-500 fw-semibold fs-7">PM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="kt_timeline_widget_3_tab_content_8"
                role="tabpanel"
              >
                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-success me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      16:30 - 17:00
                      <span class="text-gray-500 fw-semibold fs-7">PM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-info me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      10:20 - 11:00
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-danger me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      12:00 - 13:40
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Dashboard UI/UX Design Review{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Bob
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="kt_timeline_widget_3_tab_content_9"
                role="tabpanel"
              >
                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-info me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      12:00 - 13:40
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Bob
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      16:30 - 17:00
                      <span class="text-gray-500 fw-semibold fs-7">PM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-success me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      10:20 - 11:00
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Dashboard UI/UX Design Review{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="kt_timeline_widget_3_tab_content_10"
                role="tabpanel"
              >
                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-danger me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      12:00 - 13:40
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-warning me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      10:20 - 11:00
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Dashboard UI/UX Design Review{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Bob
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-info me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      16:30 - 17:00
                      <span class="text-gray-500 fw-semibold fs-7">PM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="kt_timeline_widget_3_tab_content_11"
                role="tabpanel"
              >
                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-info me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      16:30 - 17:00
                      <span class="text-gray-500 fw-semibold fs-7">PM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Dashboard UI/UX Design Review{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 bg-danger me-4"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      10:20 - 11:00
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      Marketing Campaign Discussion{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Peter Marcus
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <span
                    data-kt-element="bullet"
                    class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                  ></span>

                  <div class="flex-grow-1 me-5">
                    <div class="text-gray-800 fw-semibold fs-2">
                      12:00 - 13:40
                      <span class="text-gray-500 fw-semibold fs-7">AM </span>
                    </div>

                    <div class="text-gray-700 fw-semibold fs-6">
                      9 Degree Project Estimation Meeting{' '}
                    </div>

                    <div class="text-gray-500 fw-semibold fs-7">
                      Lead by
                      <a
                        href="#"
                        class="opacity-75-hover fw-semibold text-primary"
                      >
                        Lead by Bob
                      </a>
                    </div>
                  </div>

                  <a
                    href="#"
                    class="btn btn-sm btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_project"
                  >
                    {t('View')}
                  </a>
                </div>
              </div>
            </div>

            <div class="d-none float-end">
              <a
                href="#"
                class="btn btn-sm btn-light me-2"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_project"
              >
                Add Lesson
              </a>

              <a
                href="#"
                class="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
              >
                Call Sick for Today
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div class="card">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bold fs-3 mb-1">
                {t("Screen's Status Timeline")}
              </span>

              <span class="text-muted fw-semibold fs-7">
                {t('More than 400 new members')}
              </span>
            </h3>

            <div class="card-toolbar">
              <button
                type="button"
                class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
              >
                <i class="ki-outline ki-category fs-6"></i>{' '}
              </button>

              <div
                class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                data-kt-menu="true"
                id="kt_menu_6687bea75332b"
              >
                <div class="px-7 py-5">
                  <div class="fs-5 text-gray-900 fw-bold">
                    {t('Filter Options')}
                  </div>
                </div>

                <div class="separator border-gray-200"></div>

                <div class="px-7 py-5">
                  <div class="mb-10">
                    <label class="form-label fw-semibold">{t('Status')}:</label>

                    <div>
                      <select
                        class="form-select form-select-solid"
                        multiple
                        data-kt-select2="true"
                        data-close-on-select="false"
                        data-placeholder="Select option"
                        data-dropdown-parent="#kt_menu_6687bea75332b"
                        data-allow-clear="true"
                      >
                        <option></option>
                        <option value="1">Approved</option>
                        <option value="2">Pending</option>
                        <option value="2">In Process</option>
                        <option value="2">Rejected</option>
                      </select>
                    </div>
                  </div>

                  <div class="mb-10">
                    <label class="form-label fw-semibold">Member Type:</label>

                    <div class="d-flex">
                      <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                        />
                        <span class="form-check-label">Author</span>
                      </label>

                      <label class="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="2"
                          checked="checked"
                        />
                        <span class="form-check-label">Customer</span>
                      </label>
                    </div>
                  </div>

                  <div class="mb-10">
                    <label class="form-label fw-semibold">Notifications:</label>

                    <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        name="notifications"
                        checked
                      />
                      <label class="form-check-label">Enabled</label>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end">
                    <button
                      type="reset"
                      class="btn btn-sm btn-light btn-active-light-primary me-2"
                      data-kt-menu-dismiss="true"
                    >
                      Reset
                    </button>

                    <button
                      type="submit"
                      class="btn btn-sm btn-primary"
                      data-kt-menu-dismiss="true"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div id="kt_charts_widget_1_chart" className="h-96"></div>
          </div>
        </div>
        <div
          class="card h-full border-0"
          data-bs-theme="light"
          style={{
            background: 'linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)'
          }}
        >
          <div class="card-body">
         <div className='trial-exprie fw-bold text-white fs-2qx '> <Countdown  date={ (new Date(user?.user?.user?.subscription_expire).getTime())} /></div>
            <div class="row align-items-center h-100">
              <div class="col-7 ps-xl-13">
                <div class="mb-6 pt-6 text-white">
                  <span class="fs-4 fw-semibold d-block lh-1 me-2 pb-2 opacity-75">
                    {t('Get best offer')}
                  </span>
                
                  <span class="fs-2qx fw-bold">{t('Upgrade Your Plan')}</span>
                </div>

                <span class="fw-semibold fs-6 d-block mb-8 text-white opacity-75">
                  {t(
                    'Flat cartoony and illustrations with vivid unblended purple hair lady'
                  )}
                </span>

                <div class="d-flex flex-column flex-sm-row d-grid mt-24 gap-2">
                  <a
                    href="#"
                    class="btn btn-success me-lg-2 flex-shrink-0"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_upgrade_plan"
                  >
                    {t('Upgrade Plan')}
                  </a>
                  <a
                    href="#"
                    class="btn btn-primary flex-shrink-0"
                    style={{ background: 'rgba(255, 255, 255, 0.2)' }}
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_app"
                  >
                    {t('Read Guides')}
                  </a>
                </div>
              </div>

              <div class="col-5 pt-10">
                <div
                  class="bgi-no-repeat bgi-size-contain bgi-position-x-end h-225px"
                  style={{
                    backgroundImage:
                      "url('/assets/media/svg/illustrations/easy/5.svg')"
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="mx-auto max-w-screen-xl">
        <header className="mb-16 flex items-center justify-between">
          <h1 className="text-heading text-5xl font-bold">
            {t('Revolutionize Your Displays with Maia')}
          </h1>
          <div className="w-[400px] overflow-hidden rounded shadow-lg">
            <div className="flex aspect-video items-center justify-center bg-gradient-to-br from-[#1a2980] to-[#26d0ce]">
              <PlayCircle className="h-16 w-16 cursor-pointer text-white transition-transform hover:scale-110" />
            </div>
          </div>
        </header>

        <div className="mb-8 grid grid-cols-1 gap-8 md:grid-cols-3">
          <Step
            number="1"
            title={t('Install Maia Player')}
            description={t(
              'Download our powerful signage player and transform any screen into a dynamic digital display. Compatible with all major operating systems.'
            )}
            buttonText={t('Download Now')}
          />
          <Step
            number="2"
            title={t('Connect Your Screen')}
            description={t(
              'Seamlessly link your display to our cloud-based system. Control content remotely from anywhere, anytime. Perfect for single or multi-screen setups.'
            )}
            buttonText={t('Add Screen')}
            ActionDialog={() => (
              <form onSubmit={e => addScreenHandler(e)}>
                <AddScreen />
              </form>
            )}
          />
          <Step
            number="3"
            title={t('Create Your Content')}
            description={t(
              'Design captivating content with our user-friendly tools. From images and videos to live feeds and interactive elements, bring your vision to life.'
            )}
            buttonText={t('Start Creating')}
          />
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <Feature
            icon="🚀"
            title={t('Lightning-Fast Performance')}
            description={t(
              'Experience smooth playback and instant updates with our optimized system. Say goodbye to lag and hello to seamless content delivery.'
            )}
          />
          <Feature
            icon="🔒"
            title={t('Enterprise-Grade Security')}
            description={t(
              'Rest easy knowing your data and displays are protected by state-of-the-art encryption and security protocols. Your content, your control.'
            )}
          />
        </div>
      </div>
    </>
  )
}

export default Dashboard
