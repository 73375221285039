import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { DialogClose, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { changePlaylistEditStatus } from '@/redux/user-slice';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const PublishScreen = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [screenOptions, setScreenOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedScreens, setSelectedScreens] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const theme = localStorage.getItem("theme");

  useEffect(() => {
    if (!location.pathname.includes('playlist')) {
      dispatch(changePlaylistEditStatus(false));
    }
  }, [location, dispatch]);

  useEffect(() => {
    const screenCount = (user.screen || []).length;
    const allScreensOption = { 
      value: 'all_screens', 
      label: 'All Screens', 
      screenCount: screenCount 
    };
    const screenOptions = (user.screen || []).map((s) => ({
      value: s._id,
      label: s.name,
    }));

    setScreenOptions([allScreensOption, ...screenOptions]);
  }, [user.screen]);

  useEffect(() => {
    const allGroupsOption = { value: 'all_groups', label: 'All Groups' };
    const groupOptions = (user.group || []).map((g) => ({
      value: g._id,
      label: g.name,
    }));

    setGroupOptions([allGroupsOption, ...groupOptions]);
  }, [user.group]);

  const handleScreenChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === 'all_screens')) {
      setSelectedScreens(screenOptions.slice(1));
    } else {
      setSelectedScreens(selectedOptions || []);
    }
  };

  const handleGroupChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === 'all_groups')) {
      setSelectedGroups(groupOptions.slice(1));
    } else {
      setSelectedGroups(selectedOptions || []);
    }
  };

  const pushPlaylistToDevice = (e) => {
    e.preventDefault();

    if (selectedScreens.length === 0 && selectedGroups.length === 0) {
      toast.error(t('Please add screens or groups to publish playlist'));
      return;
    }

    // Doğrudan seçilen ekranların ID'leri
    const selectedScreenIds = selectedScreens.map((option) => option.value);
    
    // Seçilen grupların içindeki ekranların ID'leri
    const groupScreenIds = selectedGroups.flatMap((group) => {
      const foundGroup = user.group.find(g => g._id === group.value);
      return foundGroup ? foundGroup.screens.map(screen => screen._id) : [];
    });

    // Tüm ekran ID'lerini birleştirme ve tekrar edenleri kaldırma
    const allScreenIds = [...new Set([...selectedScreenIds, ...groupScreenIds])];

    axios
      .post(`${process.env.REACT_APP_API_BASE}/v1/screen/add/source`, {
        screens: allScreenIds,
        source: user?.selected_playlist._id,
      })
      .then((res) => {
        toast.success(t('Published playlist to selected devices and groups'));
        console.log(res.data);
      })
      .catch((err) => {
        toast.error(t('Error publishing playlist'));
        console.log(err);
      });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '45px',
      borderColor: theme === 'dark' ? '#1E2027' : '#DBDFE9',
      borderWidth: '0.5px',
      borderRadius: '6px',
      backgroundColor: theme === 'light' ? '#FCFCFC' : '#15171C',
      boxShadow: 'none',
      '&:hover': {
        borderColor: theme === 'dark' ? '#1E2027' : '#DBDFE9',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: theme === 'light' ? '#F9F9F9' : '#15171C',
      color: theme === 'light' ? '#071437' : '#9A9CAE',
      padding: 10,
      '&:hover': {
        backgroundColor: theme === 'light' ? '#F9F9F9' : '#1B1C21',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: theme === 'light' ? '#EEEEF2' : '#1E2027',
      height: '35px',
      borderRadius: '8px',
      padding: '6px 10px',
      color: theme === 'light' ? '#071437' : '#FFF',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: theme === 'light' ? '#071437' : '#9A9CAE',
      fontSize: '12px',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: theme === 'light' ? 'rgba(153, 161, 183, 0.60)' : 'rgba(153, 161, 183, 0.60)',
      '&:hover': {
        backgroundColor: theme === 'light' ? '#EEEEF2' : '#1E2027',
        color: 'rgba(153, 161, 183, 0.60)',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme === 'light' ? '#FCFCFC' : '#15171C',
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: theme === 'light' ? '#FCFCFC' : '#15171C',
      borderRadius: "8px"
    }),
  };

  const AllScreensOption = ({ innerProps, data }) => (
    <div style={{ color: theme === 'light' ? '#071437' : '#9A9CAE' }} className="all-screens-option" {...innerProps}>
      All Screens   <br />
      <span style={{ fontSize: '10px', color: theme === 'light' ? '#071437' : '#9A9CAE' }} className="screen-count">({data.screenCount} Screen) </span>
    </div>
  );

  const AllGroupsOption = ({ innerProps }) => (
    <div style={{ color: theme === 'light' ? '#071437' : '#9A9CAE' }} className="all-groups-option" {...innerProps}>
      All Groups
    </div>
  );

  const ClearIndicator = (props) => {
    const {
      children =  <i style={{cursor:"pointer"}} className="ki-outline ki-trash fs-2"></i>,
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles('clearIndicator', props)}
      >
        <div style={{ padding: '0px 5px' }}>{children}</div>
      </div>
    );
  };

  const customComponents = {
    Option: (props) => {
      if (props.data.value === 'all_screens') {
        return <AllScreensOption {...props} />;
      }
      if (props.data.value === 'all_groups') {
        return <AllGroupsOption {...props} />;
      }
      return <components.Option {...props} />;
    },
    ClearIndicator
  };

  return (
    <>
      <DialogHeader>
        <DialogTitle style={{ color: theme === 'light' ? '#000' : '#BCC0C4' }} className="pbl-header">{t('Publish Screen')}</DialogTitle>
      </DialogHeader>
      <form onSubmit={pushPlaylistToDevice}>
        <div style={{ padding: '30px' }} className="modal-body">
          <div style={{ marginBottom: '30px' }} className="fv-row">
            <label style={{ color: theme === 'light' ? '#98A1B7' : '#BCC0C4', fontSize: '15px' }} className="fw-semibold">
              {user?.selected_playlist?.name || ''}
            </label>
          </div>
          <div style={{ marginBottom: '30px' }} className="fv-row">
            <label style={{marginBottom:"5px", color: theme === 'light' ? '#000' : '#BCC0C4' }} className="fs-6 fw-semibold">{t('Screen Name')}</label>
            <Select
              isMulti
              name="screens"
              options={screenOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleScreenChange}
              value={selectedScreens}
              styles={customStyles}
              placeholder={t('Select Screens')}
              components={customComponents}
              isClearable={true}
            />
          </div>
          <div style={{ marginBottom: '6px' }} className="fv-row">
            <label style={{marginBottom:"5px", color: theme === 'light' ? '#000' : '#BCC0C4' }} className="fs-6 fw-semibold">{t('Group Name')}</label>
            <Select
              isMulti
              name="groups"
              options={groupOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleGroupChange}
              value={selectedGroups}
              styles={customStyles}
              placeholder={t('Select Groups')}
              components={customComponents}
              isClearable={true}
            />
          </div>
        </div>
        <DialogFooter className="ftr-publish">
          <DialogClose style={{height:"40px",width:"90px"}} className="btn btn-light">{t('Cancel')}</DialogClose>
          <DialogClose style={{ marginLeft: '26px',height:"40px" }} className="btn btn-primary" type="submit">
            {t('Push')}
          </DialogClose>
        </DialogFooter>
      </form>
    </>
  );
};

export default PublishScreen;