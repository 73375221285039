import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useState, useMemo, useCallback, useEffect } from 'react'

import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import {
  Dropdown,
  DropdownContent,
  DropdownTrigger
} from '@/components/ui/dropdown'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table'
import { formDataToJSON } from '@/utils/formDataToJSON'
import { useTranslation } from 'react-i18next'
import { useSWRConfig } from 'swr'
import AddScreen from './add-screen'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Sync from "./sync"
import AddGroup from './add-group'

const DataTable = ({ screenData, screenColumns, loading, groupData, groupColumns }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [sorting, setSorting] = useState([])
  const [columnFilters, setColumnFilters] = useState([])
  const [activeTab, setActiveTab] = useState('screens');
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { mutate } = useSWRConfig()
  const user = useSelector(state => state.user)
  const [columns, setColumns] = useState(screenColumns)
  const [data, setData] = useState(screenData)

  useEffect(() => {
    if (activeTab === "screens") {
      setColumns(screenColumns)
      setData(screenData)
    } else {
      setColumns(groupColumns)
      setData(groupData)
    }
  }, [activeTab, screenColumns, screenData, groupColumns, groupData]);

  // Memoize filteredData to prevent unnecessary recalculations
 /*  const filteredData = useMemo(() => {
    if (!searchTerm) return data
    return data.filter(row => {
      const nameMatch = row.name?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      const serialMatch = row.serial?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      return nameMatch || serialMatch
    })
  }, [data, searchTerm]) */

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    state: {
      sorting,
      columnFilters,
    }
  })

  // Handle changes in the search input field
  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value)
  }, [])

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    const statusValue = e.target.status.value
    table.resetColumnFilters()
    if (statusValue !== 'All') {
      table.getColumn('status')?.setFilterValue(statusValue)
    }
    if (e.target.direction.value !== 'Tümü') {
      table.getColumn('direction')?.setFilterValue(e.target.direction.value)
    }
  }, [table])

  const addScreenHandler = ((e) => {

    e.preventDefault()
    const formData = new FormData(e.target)
    const jsonData = formDataToJSON(formData)

    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE}/v1/screen`, jsonData, { headers: { Authorization: `Bearer ${user.user.user.token}` } })
        .then(response => {
          if (response.status === 200) {
            toast.success(t('Screen created'))
            mutate(`${process.env.REACT_APP_API_BASE}/v1/screen`)
          } else {
            toast.error(t('Error creating screen'))
          }
        })
        .catch(error => {
          console.error(error)
          toast.error(error.response.data.message)
        })
    } catch (error) {
      toast.error(t('Error creating screen'))
      console.error(error)
    }
  })
  const addGroupHandler = ((e) => {

    e.preventDefault()
    const formData = new FormData(e.target)
    const jsonData = formDataToJSON(formData)

    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE}/v1/group`, jsonData, { headers: { Authorization: `Bearer ${user.user.user.token}` } })
        .then(response => {
          if (response.status === 200) {
            toast.success(t('Screen created'))
            mutate(`${process.env.REACT_APP_API_BASE}/v1/group`)
          } else {
            toast.error(t('Error creating screen'))
          }
        })
        .catch(error => {
          console.error(error)
          toast.error(error.response.data.message)
        })
    } catch (error) {
      toast.error(t('Error creating screen'))
      console.error(error)
    }
  })

  return (
    <div className="card">
      <div className="card-header d-flex flex-column border-0">
        <div className="d-flex justify-content-between pt-4">
          <div className="card-title align-items-start flex w-full flex-col">
            <div className='flex w-full flex-row'>
              <div
                className={`flex cursor-pointer ${activeTab === 'screens' ? 'font-bold' : ''}`}
                onClick={() => setActiveTab('screens')}
              >
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-gray-900">
                    {t('Screens')}
                  </span>
                  <span className="fw-semibold fs-6 text-gray-500 mt-1">
                    {t('Total Screen')} {user.screen?.length}
                  </span>
                </h3>
              </div>
              <div
                className={`flex ms-2 cursor-pointer ${activeTab === 'groups' ? 'font-bold' : ''}`}
                onClick={() => setActiveTab('groups')}
              >
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-gray-900">
                    {t('Groups')}
                  </span>
                  <span className="fw-semibold fs-6 text-gray-500 mt-1">
                    {t('Total Group')} {user?.group?.length || 0}
                  </span>
                </h3>
              </div>
            </div>
            <div className="my-1 mt-4 flex w-full justify-between">
              <div className="d-flex align-items-center position-relative">
                <i className="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
                <input
                  className="form-control form-control-solid w-250px ps-12"
                  placeholder={t('Search by Name or Serial Number')}
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              <div className="card-toolbar">
                <div className="d-flex justify-content-end">
          
             {activeTab==="screens"?     <Dropdown>
                    <DropdownTrigger
                      className="btn-light-primary btn fs-6 me-3 px-8 py-4"
                      trigger="click"
                      placement="bottom-end"
                    >
                      <i className="ki-outline ki-filter fs-2"></i>{' '}
                      {t('Filter')}
                    </DropdownTrigger>
                    <DropdownContent className="w-300px">
                      <form onSubmit={handleSubmit}>
                        <div className="px-7 py-5">
                          <div className="fs-5 fw-bold text-gray-900">
                            {t('Filter Options')}
                          </div>
                        </div>
                        <div className="separator border-gray-200"></div>
                        <div
                          className="px-7 py-5"
                          data-kt-subscription-table-filter="form"
                        >
                          <div className="mb-10">
                            <label className="form-label fs-6 fw-semibold">
                              {t('Direction')}:
                            </label>
                            <select
                              name="direction"
                              className="form-select form-select-solid fw-bold"
                            >
                              <option>{t('All')}</option>
                              <option value="h">{t('Horizontal')}</option>
                              <option value="v">{t('Vertical')}</option>
                            </select>
                          </div>
                          <div className="mb-10">
                            <label className="form-label fs-6 fw-semibold">
                              {t('Status')}:
                            </label>
                            <select
                              name="status"
                              className="form-select form-select-solid fw-bold"
                            >
                              <option value="All">{t('All')}</option>
                              <option value="online">{t('Online')}</option>
                              <option value="offline">{t('Offline')}</option>
                            </select>
                          </div>
                          <div className="mb-10">
                            <label className="form-label fs-6 fw-semibold">
                              {t('Tags')}:
                            </label>
                            <select
                              name="tags"
                              className="form-select form-select-solid fw-bold"
                            >
                              <option>{t('All')}</option>
                              <option value="">item 1</option>
                              <option value="">item 2</option>
                            </select>
                          </div>
                          <div className="mb-10">
                            <label className="form-label fs-6 fw-semibold">
                              {t('Video Wall')}:
                            </label>
                            <select
                              name="video_wall"
                              className="form-select form-select-solid fw-bold"
                            >
                              <option>{t('All')}</option>
                              <option value="">item 1</option>
                              <option value="">item 2</option>
                            </select>
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              type="reset"
                              className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                              onClick={() => {
                                setSearchTerm('')
                                table.getColumn('name')?.setFilterValue('')
                                table.getColumn('serial')?.setFilterValue('')
                                table.getColumn('status')?.setFilterValue('')
                              }}
                            >
                              {t('Reset')}
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary fw-semibold px-6"
                            >
                              {t('Apply')}
                            </button>
                          </div>
                        </div>
                      </form>
                    </DropdownContent>
                  </Dropdown>:     <Dropdown>
                    <DropdownTrigger
                      className="btn-light-primary btn fs-6 me-3 px-8 py-4"
                      trigger="click"
                      placement="bottom-end"
                    >
                      <i className="ki-outline ki-filter fs-2"></i>{' '}
                      {t('Filter')}
                    </DropdownTrigger>
                 {/*    <DropdownContent className="w-300px">
                      <form onSubmit={handleSubmit}>
                        <div className="px-7 py-5">
                          <div className="fs-5 fw-bold text-gray-900">
                            {t('Filter Options')}
                          </div>
                        </div>
                        <div className="separator border-gray-200"></div>
                        <div
                          className="px-7 py-5"
                          data-kt-subscription-table-filter="form"
                        >
                          <div className="mb-10">
                            <label className="form-label fs-6 fw-semibold">
                              {t('Direction')}:
                            </label>
                            <select
                              name="direction"
                              className="form-select form-select-solid fw-bold"
                            >
                              <option>{t('All')}</option>
                              <option value="h">{t('Horizontal')}</option>
                              <option value="v">{t('Vertical')}</option>
                            </select>
                          </div>
                          <div className="mb-10">
                            <label className="form-label fs-6 fw-semibold">
                              {t('Status')}:
                            </label>
                            <select
                              name="status"
                              className="form-select form-select-solid fw-bold"
                            >
                              <option value="All">{t('All')}</option>
                              <option value="online">{t('Online')}</option>
                              <option value="offline">{t('Offline')}</option>
                            </select>
                          </div>
                          <div className="mb-10">
                            <label className="form-label fs-6 fw-semibold">
                              {t('Tags')}:
                            </label>
                            <select
                              name="tags"
                              className="form-select form-select-solid fw-bold"
                            >
                              <option>{t('All')}</option>
                              <option value="">item 1</option>
                              <option value="">item 2</option>
                            </select>
                          </div>
                          <div className="mb-10">
                            <label className="form-label fs-6 fw-semibold">
                              {t('Video Wall')}:
                            </label>
                            <select
                              name="video_wall"
                              className="form-select form-select-solid fw-bold"
                            >
                              <option>{t('All')}</option>
                              <option value="">item 1</option>
                              <option value="">item 2</option>
                            </select>
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              type="reset"
                              className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                              onClick={() => {
                                setSearchTerm('')
                                table.getColumn('name')?.setFilterValue('')
                                table.getColumn('serial')?.setFilterValue('')
                                table.getColumn('status')?.setFilterValue('')
                              }}
                            >
                              {t('Reset')}
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary fw-semibold px-6"
                            >
                              {t('Apply')}
                            </button>
                          </div>
                        </div>
                      </form>
                    </DropdownContent> */}
                  </Dropdown>}
                  <Dialog>
                    <DialogTrigger
                      className="btn btn-primary"
                      type="button"
                    >
                      {activeTab==="screens"?t('Add Screen'):t('Create Group')}
                    </DialogTrigger>
                    <DialogContent>
                   { activeTab==="screens"?<AddScreen addScreenHandler={addScreenHandler} />:<AddGroup addGroupHandler={addGroupHandler} />}
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: <i className="ki-outline ki-arrow-down fs-3"></i>,
                          desc: <i className="ki-outline ki-arrow-up fs-3"></i>,
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {loading ? 'Loading...' : 'No results.'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div id="" className="row pb-4">
        <div
          id=""
          className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar"
        ></div>
        <div
          id=""
          className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
        >
          <div className="dt-paging paging_simple_numbers">
            <ul className="pagination">
              {table.getCanPreviousPage() ? (
                <>
                  <li
                    className="dt-paging-button page-item cursor-pointer"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <span className="page-link previous">
                      <i className="previous"></i>
                    </span>
                  </li>
                  <li
                    className="dt-paging-button page-item cursor-pointer"
                    onClick={() => table.previousPage()}
                  >
                    <span className="page-link">
                      {table.options.state.pagination.pageIndex}
                    </span>
                  </li>
                </>
              ) : (
                ''
              )}
              <li className="dt-paging-button page-item active">
                <span className="page-link">
                  {table.options.state.pagination.pageIndex + 1}
                </span>
              </li>
              {table.getCanNextPage() ? (
                <>
                  <li
                    className="dt-paging-button page-item cursor-pointer"
                    onClick={() => table.nextPage()}
                  >
                    <span className="page-link">
                      {table.options.state.pagination.pageIndex + 2}
                    </span>
                  </li>
                  <li
                    className="dt-paging-button page-item cursor-pointer"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    <span className="page-link next">
                      <i className="next"></i>
                    </span>
                  </li>
                </>
              ) : (
                ''
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataTable
