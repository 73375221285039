import axios from 'axios'
import useSWR, { useSWRConfig } from 'swr'

import { useTranslation } from 'react-i18next'
import DataTable from './data-table'
import { useColumns } from './data-table/columns'

import AddPlaylist from './data-table/add-playlist'

import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { formDataToJSON } from '@/utils/formDataToJSON'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import SelectPlaylistLayout from './data-table/select-playlist-layout'
import { selectedPlaylist } from '@/redux/user-slice'
import { useNavigate } from 'react-router-dom'
const Platlist = () => {
  const fetcher = (...args) => axios.get(...args)

  const { t } = useTranslation()
  const dispatch=useDispatch();
  const [sorting, setSorting] = useState([])
  const [columnFilters, setColumnFilters] = useState([])
  const [step, setStep] = useState(1)
  const { mutate } = useSWRConfig()
  const user = useSelector(state => state.user)
  
  const navigate = useNavigate()
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_API_BASE}/v1/source`,
    fetcher
  )

  const dataArray = data?.data.source || []

  function handleSubmit(e) {
    e.preventDefault()

    const formData = new FormData(e.target)
    const jsonData = formDataToJSON(formData)

    axios
      .post(`${process.env.REACT_APP_API_BASE}/v1/source/`, {
        name: jsonData.name,
        layout: user.layout_id,
        source_type: 'playlist'
      })
      .then(response => {
        if (response.status === 200) {
          toast.success(t('Playlist created'));
          navigate(`/playlists/${response.data._id}`)
          dispatch(selectedPlaylist(response.data))
          mutate(`${process.env.REACT_APP_API_BASE}/v1/source`)
        } else {
          toast.error('Error creating playlist')
        }
      })
      .catch(error => {
        console.error(error)
        // Handle error
      })
  }

  const columns = useColumns()

  return (
    <div>
      {dataArray.length || isLoading ? (
        <DataTable
          data={[...dataArray]}
          columns={columns}
          loading={isLoading}
        />
      ) : (
        <div className="card">
          <div className="card-body p-0">
            <div className="card-px my-10 py-20 text-center">
              <h2 className="fs-2x fw-bold mb-10">{t('New Playlist')}</h2>

              <p className="fs-4 fw-semibold text-gray-500 mb-10">
                {t('Click on the below buttons to launch a new playlist.')}
              </p>

              <Dialog>
                <DialogTrigger className="btn btn-primary">
                  {t('Add New Playlist')}
                </DialogTrigger>
                <DialogContent>
                  <form onSubmit={e => handleSubmit(e)}>
                    <Tabs
                      defaultValue="addPlaylist"
                      value={step == 1 ? 'addPlaylist' : 'selectLayout'}
                    >
                      <TabsList className="hidden">
                        <TabsTrigger value="addPlaylist">
                          Add Playlist
                        </TabsTrigger>
                        <TabsTrigger value="selectLayout">
                          Select Layout
                        </TabsTrigger>
                      </TabsList>
                      <TabsContent
                        value="addPlaylist"
                        forceMount
                        className="data-[state=inactive]:hidden"
                      >
                        <AddPlaylist setStep={setStep} />
                      </TabsContent>
                      <TabsContent value="selectLayout">
                        <SelectPlaylistLayout setStep={setStep} />
                      </TabsContent>
                    </Tabs>
                  </form>
                </DialogContent>
              </Dialog>
            </div>

            <div className="flex justify-center px-4 text-center">
              <img
                className="mw-100 mh-300px"
                src="/assets/media/illustrations/sketchy-1/5.png"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Platlist
