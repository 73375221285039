import axios from 'axios'
import { useState,useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSWRConfig } from 'swr'
import { Dropdown, DropdownContent, DropdownTrigger } from '@/components/ui/dropdown'
import { toast } from 'react-toastify'
import ScreenDetail from "../data-table/screen-detail"
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { useSocket } from '../../../socket-context'
export const useColumns = () => {
  const { t } = useTranslation()
  const allState = useSelector(state => state)
  const user = useSelector(state => state.user)
  const [renameBtn, setRenameBtn] = useState(null)
  const { mutate } = useSWRConfig()
  const [newName, setNewName] = useState("")
  const socket=useSocket();

  const checkOnlineStatus = id => {
    const status = allState.user.screensStatus[id]?.status
    return (
      <span className={`badge fs-7 px-4 py-3 ${status === 'online' ? 'badge-light-success' : 'badge-light-danger'}`}>
        {status === 'online' ? 'Online' : 'Offline'}
      </span>
    )
  }

  const handleNewName = useCallback((e, data) => {
    e.preventDefault()

    axios
      .put(`https://api.maiasignage.com/api/v1/screen/${data._id}`, {
        name: newName,
      }, { headers: { Authorization: `Bearer ${user.user.user.token}` } })
      .then(() => {
        mutate(`${process.env.REACT_APP_API_BASE}/v1/screen`)
        setRenameBtn(null)
      })
      .catch(error => {
        console.error("Error updating name:", error)
      })
  }, [newName, user.user.user.token, mutate])

  const handleRenameClick = useCallback((id, currentName) => {
    setRenameBtn(id);
    setNewName(currentName);
  }, [])

  const handleDelete = useCallback(async (playlistId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/screen/${playlistId}`, { headers: { Authorization: `Bearer ${user.user.user.token}` } }).then(res => {
        if (res.status === 200) {
          mutate(`${process.env.REACT_APP_API_BASE}/v1/screen`);
          toast.success(t('Screen deleted successfully'));
        }
      });
    } catch (error) {
      console.error("Error deleting screen:", error);
      toast.error(t('Error deleting screen'));
    }
  }, [user.user.user.token, mutate, t]);
  const rotateScreen = useCallback((row) => {
    
    let screen=row.original
   let rotateValue=90
    if (socket) {
     
      socket.emit('call', 'v1.device.rotate',{
      
        screen:screen,
        rotate:rotateValue
      }, (err, res) => {
        if (err) {
          console.error(err);
         
        } else {
          console.log(res);
       
        }
      });
    } 
  }, []);

  const renderDropdown = useCallback(({ row }) => (
    <div className="d-flex justify-content-end">
      <Dropdown>
        <DropdownTrigger trigger="click" placement="bottom-end">
          <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
            <i className="ki-solid ki-dots-horizontal fs-2x"></i>
          </button>
        </DropdownTrigger>
        <DropdownContent className="w-200px fs-7">
          <div className="menu-item px-3">
            <a href="#" className="menu-link px-3">{t('Power Management')}</a>
            <a href="#" className="menu-link px-3">{t('Screenshot')}</a>
            <a href="#" className="menu-link px-3">{t('Tags')}</a>
            <Dialog>
              <DialogTrigger href="#" className="menu-link px-3">{t('Screen Details')}</DialogTrigger>   
              <DialogContent>
                <ScreenDetail  id={row.original}/>
              </DialogContent>
            </Dialog>
            <a href="#" className="menu-link px-3">{t('Update Software')}</a>
            <a href="#" className="menu-link px-3" onClick={()=>rotateScreen(row)}>{t('Rotate Screen')}</a>
            <a href="#" className="menu-link px-3">{t('Restart')}</a>
            <a href="#" className="menu-link px-3" onClick={() => handleRenameClick(row.original._id, row.original.name)}>{t('Rename')}</a>
            <a href="#" className="menu-link text-danger px-3" onClick={() => handleDelete(row.original._id)}>{t('Delete')}</a>
          </div>
        </DropdownContent>
      </Dropdown>
    </div>
  ), [t, handleRenameClick, handleDelete]);

  return [
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <div
          className="align-items-center inline-flex cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <i className="ki-outline ki-sort fs-2 text-gray-500 me-2 ms-1" />
          {t('Screen Name')}
        </div>
      ),
      cell: ({ row }) => {
        return (
          <>
            {row.original._id === renameBtn ? (
              <form className="d-flex align-items-center" onSubmit={e => handleNewName(e, row.original)}>
                <input className="form-control mw-250px me-3" name="name"
                  onChange={(e) => setNewName(e.target.value)}
                  value={newName}
                  autoFocus
                />
                <button className="btn btn-icon btn-light-primary me-3 flex-shrink-0" type="submit">
                  <span className="indicator-label">
                    <i className="ki-outline ki-check fs-1"></i>
                  </span>
                  <span className="indicator-progress">
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                </button>
                <button className="btn btn-icon btn-light-danger flex-shrink-0" type="button" onClick={() => setRenameBtn(null)}>
                  <span className="indicator-label">
                    <i className="ki-outline ki-cross fs-1"></i>
                  </span>
                  <span className="indicator-progress">
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                </button>
              </form>
            ) : (
              <span className="w-full">{row.original.name}</span>
            )}
          </>
        )
      },
      filterFn: (row, id, filterValue) => {
        const cellValue = row.getValue(id)?.toLowerCase() || ''
        return cellValue.includes(filterValue.toLowerCase())
      },
    },
    {
      accessorKey: 'serial',
      header: t('Screen ID'),
      filterFn: (row, id, filterValue) => {
        const cellValue = row.getValue(id)?.toLowerCase() || ''
        return cellValue.includes(filterValue.toLowerCase())
      },
    },
    {
      accessorKey: 'place',
      header: t('Screen Location'),
    },
    {
      accessorKey: 'direction',
      header: t('Type'),
      filterFn: (row, id, filterValue) => filterValue === 'Tümü' || row.getValue(id) === filterValue,
    },
    {
      accessorKey: 'status',
      header: ({ column }) => (
        <div
          className="align-items-center inline-flex cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <i className="ki-outline ki-sort fs-2 text-gray-500 me-2 ms-1" />
          {t('Status')}
        </div>
      ),
      cell: ({ row }) => checkOnlineStatus(row.original._id),
      filterFn: (row, id, filterValue) => {
        const status = allState.user.screensStatus[row.original._id]?.status || 'offline'
        return filterValue === 'All' || filterValue === undefined || status.toLowerCase() === filterValue.toLowerCase()
      },
    },
    {
      accessorKey: 'actions',
      header: () => <div className="text-end">{t('Settings')}</div>,
      cell: renderDropdown,
    },
  ]
}
