import { Calendar as FullCalendar } from 'fullcalendar'
import { useEffect, useRef, useState } from 'react'

import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import allLocales from '@fullcalendar/core/locales-all'

import AddEvent from './add-event'
import EventInfo from './event-info'

import { useTranslation } from 'react-i18next'
import events from './events'

const Calendar = () => {
  const calendarRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [isOpen2, setIsOpen2] = useState(false)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const calendar = new FullCalendar(calendarRef.current, {
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      navLinks: true,
      selectable: true,
      selectMirror: true,
      editable: true,
      dayMaxEvents: true,
      events: events,
      eventOverlap: false,
      locales: allLocales,
      locale: i18n.language,

      eventClick: e => {
        setIsOpen2(true)
        setSelectedEvent(e)
      },
      select: e => {
        setIsOpen(true)
      }
    })

    calendar.render()
  }, [i18n.language])

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h2 className="card-title fw-bold">{t('Schedules')}</h2>

          <div className="card-toolbar">
            <Dialog open={isOpen}>
              <DialogTrigger
                className="btn btn-flex btn-primary"
                onClick={() => setIsOpen(true)}
              >
                <i className="ki-outline ki-plus fs-2"></i>
                {t('Add Schedule')}
              </DialogTrigger>
              <DialogContent>
                <AddEvent onClose={() => setIsOpen(false)} />
              </DialogContent>
            </Dialog>
          </div>
        </div>

        <div className="card-body">
          <Dialog open={isOpen2}>
            <DialogContent>
              <EventInfo
                event={selectedEvent}
                onClose={() => setIsOpen2(false)}
              />
            </DialogContent>
          </Dialog>
          <div id="kt_calendar_app" ref={calendarRef}></div>
        </div>
      </div>
    </>
  )
}

export default Calendar
