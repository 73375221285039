import flatpickr from 'flatpickr'

import { useEffect, useRef } from 'react'

const DatePicker = ({ className, ...props }) => {
  const ref = useRef(null)
  let flatpickrInstance = null

  useEffect(() => {
    const inputElement = ref.current

    flatpickrInstance = flatpickr(inputElement, {
      enableTime: false,
      dateFormat: 'Y-m-d',
      clickOpens: false,
      onClose: () => {
        inputElement.blur()
      }
    })

    return () => {
      if (flatpickrInstance) {
        flatpickrInstance.destroy()
      }
    }
  }, [])

  const handleInputClick = () => {
    flatpickrInstance.open()
  }

  return (
    <input
      className={`form-control form-control-solid ps-12 ${className}`}
      ref={ref}
      onClick={handleInputClick}
      readOnly
      {...props}
    />
  )
}

export default DatePicker
