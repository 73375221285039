import { useLocation, useNavigate } from 'react-router-dom'

import { useTranslation } from '@/localization/i18n'
import { pageTitles } from '@/utils/page-titles'
import { useSelector } from 'react-redux'
const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const user = useSelector(state => state.user)
  const { t, i18n } = useTranslation()
  const currentPath = location.pathname
  const pageTitle = pageTitles[currentPath] || ''
  const showBackButton = currentPath !== '/'

  const handleBackClick = () => {
    navigate(-1)
  }

  return (
    <div className="app-header align-items-stretch fixed right-0 top-0 !z-20 flex w-full lg:left-[70px] lg:w-auto">
      <div className="app-container container-fluid d-flex align-items-stretch justify-content-between">
        <div className="app-header-wrapper d-flex flex-grow-1 justify-content-around justify-content-lg-between mb-lg-0 mb-6 flex-wrap gap-3">
          <div className="d-flex align-items-stretch">
            <div
              className="align-items-center inline-flex cursor-pointer"
              onClick={handleBackClick}
            >
              {showBackButton && (
                <i
                  class="ki-outline ki-left ms-4 text-[#636674]"
                  style={{ fontSize: '2.5rem' }}
                ></i>
              )}
              <h1 className="fw-bold fs-3 text-gray-900 m-0">
                {pageTitle || user?.selected_playlist.name}
              </h1>
            </div>
          </div>
          <div className="d-flex align-items-center gap-lg-4 gap-2">
            {/*   <a
             
              className="btn btn-flex btn-outline btn-active-primary fs-6 h-40px px-4 maia-63c002c010bf"
            >
              
                 
                    {t("What's News")}
                
              
            </a> */}
            <a
              href=""
              className="btn btn-flex btn-outline btn-active-primary fs-6 h-40px px-4"
            >
              <i className="ki-outline ki-teacher fs-2 pe-2"></i>
              {t('Learn')}
            </a>
            <a
              href=""
              className="btn btn-flex btn-outline btn-active-primary fs-6 h-40px px-4"
            >
              <i className="ki-outline ki-message-edit fs-2 pe-2"></i>
              {t('Feedback')}
            </a>
            <div className="btn-active-light-primary btn btn-flex btn-outline btn-active-primary fs-6 h-40px px-4">
              <i class="ki-outline ki-notification fs-2 !pr-0"></i>
            </div>
          </div>
        </div>
        <div className="d-flex d-lg-none flex-grow-1 flex-stack gap-4">
          <button className="btn btn-icon btn-sm btn-active-color-primary ms-n2">
            <i className="ki-outline ki-abstract-14 fs-1"></i>
          </button>
          <a href="index.html">
            <img className="h-30px" src="/maia.svg" />
          </a>
          <button className="btn btn-icon btn-sm btn-active-color-primary me-n2">
            <i className="ki-outline ki-menu fs-1"></i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
